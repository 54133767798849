import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
export interface SignUpSuccessResponse {
  data: {
    id: string,
    type:string,
    attributes: {
        first_name: string,
        email: string
    }
  },
  meta: {
      message: string
  },
  errors:[
    {
      token:string
    }
  ]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    history:any,
    navigate:any
  };
  id: string;
}

export interface S {
  // Customizable Area Start
  queryParam:string

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallId: string = "";
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      queryParam:''
      // Customizable Area End
    };

    // Customizable Area Start
   
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.apiCallId){
          this.handleUserActivation(responseJson)
        }
      }
    
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const queryString = this.props.navigation.history.location.search;
    const params = new URLSearchParams(queryString);
    const queryParam = params.get('token');
    this.callApi(queryParam)
  }
  callApi(token:string | null): boolean {
    if (token) {
        const header = {
          "Content-Type": configJSON.loginApiContentType,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiCallId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.userActivationApiEndPoint +'?token='+token
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    return true
  }
  handleUserActivation(responseJson: SignUpSuccessResponse){
    if (responseJson?.meta) {
      toast.success(responseJson?.meta?.message);
      this.props.navigation.navigate("EmailAccountLoginBlock")
    } else {
      toast.error(responseJson?.errors?.[0]?.token || "Error in user activation.");
    }
  }
  // Customizable Area End
}
