import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,

} from "@mui/material";
import { styled } from "@mui/material/styles";
import { closeIcon } from "../../../blocks/mobile-account-registration/src/assets";
// Customizable Area End

import TermsConditionsEditController, {
  Props,
  configJSON,
} from "./TermsConditionsEditController";

export default class TermsConditionsEdit extends TermsConditionsEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openModal, onHandleCloseModal } = this.props;
    return  (
     <>
      <DialogContainerPrivacy
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className={"titleContainer"}>
            <DialogTitle data-test-id="modalTitlePrivacyPolicy" className="modalTitlePrivacyPolicy">
            Privacy policy
            </DialogTitle>
            <Box className={"closeIconPrivacy"}>
              <img
                id="close-icon"
                src={closeIcon}
                onClick={onHandleCloseModal}
                alt="close-icon"
              />
            </Box>
          </Box>
  
          <DialogContent>
          <SummaryTextPrivacy>
            <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>Summary</h2>
           
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            This privacy policy applies only to the ProcureCircular platform (the “Platform”) and our website located at <a style={{color:"#0000FF"}} href="www.procurecircular.com">www.procurecircular.com</a> (the "Website"). It applies to users of our Platform and Website. It explains how we may collect and use your personal information and your rights in relation to your information.  ProcureCircular Limited is the data controller of your information. If you do not agree with our collection and use of your personal information as set out in this privacy policy, you must not use the Platform or Website.
            </p>

            <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>COLLECTION OF PERSONAL INFORMATION</h2>
            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            We respect all laws that apply to our business, including applicable data protection laws. 
            </p>

            <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            We collect and use your information only where:
            </p>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>we need it to provide you access and functions on the Platform (i.e. name and email address),</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote the Platform and to protect our legal rights and interests,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>you give us consent to do so for a specific purpose, or</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>we need to process your data to comply with a legal obligation.</li>
      </ul>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but this will not affect any processing that has already taken place.
      </p>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        Where we are using your information because we have a legitimate interest to do so, you have the right to object to that use. Though, in some cases, this may mean no longer using the Platform. If you wish to submit a subject access request or a request for erasure, please contact <a href="mailto:admin@ambio-n.com">admin@ambio-n.com</a>.
      </p>
      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>USE OF COOKIES</h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        In addition to personal information you provide, we may also collect information about you automatically when you visit the Platform or access content in our electronic communications with you.
      </p>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      The collection of information may involve the use of cookies. A cookie is a small text file that is placed on your device by your browser. Cookies allow us to identify your computer and find out details about your visit and browsing habits so that we can offer you a more personalised experience and manage, monitor and improve services.
      </p>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
            We collect and use your information only where:
            </p>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>Compliance cookies. These cookies tell us whether you’ve given consent to the use of cookies and if you need to be presented with a consent request message.</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>Analytics cookies. These cookies allow us to count page visits and traffic sources so we can measure and improve the performance of our Platform and Website.</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>Retargeting cookies. These cookies are used to display targeted ads to you on other sites if you have previously visited our Platform or Website, based on your behaviour when visiting.</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      Session cookies. These cookies store temporary information to enable Platform and Website functionality. They’re automatically deleted when you close your browser.
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        Functional cookies. These are cookies used for core Platform and Website functionality.
        </li>
      </ul>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      Every web browser provides the ability for the user to configure their cookie preferences. Please refer to the help section of your browser to find out how to configure your own preferences. If you choose not to accept cookies, you may not be able to use some of the core features of the Platform or Website.
      </p>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      The information collected by cookies is not tied to your contact details (such as your name or email address), it only includes general information about your computer settings, your connection to the internet e.g. operating system and platform, IP address, your browsing patterns and timings of browsing on the Platform or Website and geographical location.
Visit www.allaboutcookies.org to find out more about cookies.
      </p>
      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      USE OF YOUR INFORMATION
      </h2>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      By using the Platform or Website, you agree that we may collect, hold, process and use your information (including personal information) for the purpose of providing you with the Platform or Website and developing our business which includes:
      </p>
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>personalising your visits to the Platform or Website and developing the design and style of the Platform or Website to improve the services provided to you,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>contacting you if you have not finalised your order to follow up on your quote and/or to see if there was a problem with your use of the Platform or Website,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>informing you about the latest changes to the Platform or Website, products, services or promotional offers that you might find interesting,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>customer service and support,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>communicating (and personalising such communication) with you via telephone, email, SMS, in-app chat messaging or any other means available to us,</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>enabling you to share our content with others e.g. using 'Share this' functionality;</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>conducting market research, and/or</li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px", listStyleType: "circle"}}>carrying out technical and statistical analysis to measure the performance of our services and the Platform or Website.</li>
      </ul>
      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      SHARING YOUR PERSONAL INFORMATION
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      We may share information about you with suppliers that we engage to help us provide certain services and/or functionality e.g. online payment processing. We may also share it with others if required by law.
      </p>

      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      KEEPING YOUR PERSONAL INFORMATION SECURE
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      We take the security of your personal information very seriously and have appropriate physical, technical and administrative procedures in place to help protect your personal information from unauthorised access, use or disclosure.
      </p>

      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      RETENTION OF PERSONAL INFORMATION
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      We only keep your information for as long as we need it for the above purposes. We will only keep it longer if required by law.
      </p>

      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      ACCESSING YOUR PERSONAL INFORMATION
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      If you wish to review or receive copies of the personal information we hold about you, change your marketing preferences or have any other queries about this privacy policy, please contact  <a href="mailto:admin@ambio-n.com">admin@ambio-n.com</a>.
      </p>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      We may charge a small administration fee (not exceeding the maximum permitted by law in England or Ireland as applicable) in relation to fulfilling a request for access to personal information.
          </p>

          <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
          UNSUBSCRIBING FROM EMAILS
      </h2>

      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      When creating a Platform account or submitting your email address you will receive transactional (emails about your activity and business management on the Platform). You will also be opted into marketing (content, editorial and promotional) emails. Transactional emails are necessary to operate your account on the Platform and cannot be unsubscribed from. You can unsubscribe from marketing emails at any time. If you wish to unsubscribe from marketing emails please click on the unsubscribe link in the email or contact <a href="mailto:admin@ambio-n.com">admin@ambio-n.com</a>.
          </p>

          <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
          YOUR RIGHTS
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      In addition to your rights described above, below is a summary of your rights in relation to your personal information:
          </p>

          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to ask us about its collection and use,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to ask us for a copy,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to ask us to correct it or complete it,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to ask us to delete it,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to ask us to stop or restrict processing it,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      to ask us to send it to another organisation,
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
        to withdraw consent at any time (if applicable), and
        </li>
        <li style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      to complain to the <span style={{fontWeight:450, fontSize:"14px", color:"#0000FF", textDecoration:"underline"}}>Information Commissioner’s Office</span> (but please contact us at <a href="mailto:admin@ambio-n.com">admin@ambio-n.com</a> for help first).
        </li>
      </ul>

      <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
      CHANGES TO THIS PRIVACY POLICY
      </h2>
      <p style={{fontSize:"14px", fontWeight: 390,color:"#292524",paddingLeft:"15px"}}>
      If we change this privacy policy, we will post the revised privacy policy here with an updated effective date. If we make significant changes to the privacy policy, we may also notify you by other means such as sending an email or posting a notice on our home page.
          </p>

          <h2  style={{fontWeight:450, fontSize:"20px", color:"#292524"}}>
          LAST REVISED: 20th August 2024
      </h2>
          </SummaryTextPrivacy>
        </DialogContent>
         
        </DialogContainerPrivacy>
     </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const DialogContainerPrivacy = styled(Dialog)({
 "& .titleContainer": {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "5px 11px 6px 15px",
  borderBottom: "1px solid lightgray",
  "& .modalTitlePrivacyPolicy": {
  color: "#000000",
  fontSize: "24px",
  fontWeight: 420,
  lineHeight: "32px",
},
},

"& .closeIconPrivacy": {
  marginLeft: 10,
  height: "14px",
  width: "14px",
  padding: "5px",
  "& img": {
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
},

  "& .MuiDialog-paperWidthSm": {
  boxShadow: "none",
  border: "4px solid",
  borderImageSlice: 4,
  borderImageSource:
    "linear-gradient(118.3deg, #C2A657 1.51%, #E9D292 15.87%, #967C3E 30.23%, #F1DD99 44.6%, #7C6019 58.96%, #E7D8A0 73.32%, #A88E53 87.68%, #EFD99E 102.04%)",
  padding: "0px",
  margin: "0px",
  width: "666px",
  maxWidth: "666px",
  height: "673px",

  "@media (max-width: 480px)": {
    maxHeight: "100%",
  },
  
  "& .MuiDialogContent-root": {
    justifyContent: "center",
    paddingTop: "25px",
    overflowY: "auto",
    "@media (max-width: 480px)": {
      padding: "40px 12px 0 12px",
    },

    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#BA902F",
      borderRadius: "12px",
      minHeight: "81px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#BA902F",
    },
    scrollbarWidth: "8px",
    marginRight: "10px",
  },
"& .MuiDialogActions-root": {
  padding: "0",
  justifyContent: "end",
},
},

});



const SummaryTextPrivacy = styled('div')({
  paddingLeft:"17px",
  paddingRight:"17px",
  fontFamily: "'Arial', sans-serif",
  fontSize: '16px',
  lineHeight: 1.6,
  color: '#000',


  p: {
    marginBottom: '10px',
  },

  ul: {
    listStyleType: 'lower-alpha',
    marginLeft: '20px',

    li: {
      marginBottom: '5px',
    },
  },
});
// Customizable Area End
