//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import {apiCall} from "../../../blocks/utilities/src/CommonFunctions";



interface ColorGradient {
  name: string;
  gradient: string;
}

interface Image {
  id: number;
  url: string;
  name?: string;
}

interface AttributeValue {
  id: number | null;
  value: string | null;
}

interface AttributeValueComposition {
  id: number | null;
  value: string | null;
  percent: string | null;
}

interface Picture {
  id: number;
  url: string;
}

interface ProductAttributes {
  catalogue_id: string | null;
  manufacturer: string | null;
  catalogue_type: string;
  display_name: string;
  short_description: string;
  full_description: string;
  width: string;
  offer_sample: string | null;
  comments: string | null;
  country_of_origin: string | null;
  bleaching: string | null;
  order_minimum_quantity: number;
  allowed_quantities: string | null;
  location_country: string | null;
  location_city: string | null;
  not_returnable: boolean;
  disable_wishlist_button: boolean;
  discounted_price: string;
  price: string;
  weight: string;
  sku: string;
  shade_depth: number;
  stock: string;
  tumble_dry: boolean;
  sample_in_showroom: boolean;
  second_certificate_name: string;
  third_certificate_name: string;
  pc_id: number;
  available_quantity: number;
  company: string | null;
  first_composition_percent: number;
  second_composition_percent: number;
  third_composition_percent: number;
  material_type: AttributeValue;
  dye: AttributeValue;
  fabric_type: AttributeValue;
  fabric_formation: AttributeValue;
  currency: AttributeValue;
  color: AttributeValue;
  stretch: AttributeValue;
  iron_temperature: AttributeValue;
  material_type:  AttributeValue ;
  fabric_pattern: AttributeValue;
  cleaning_instruction: AttributeValue;
  compositions: AttributeValueComposition[];
  discount: string;
  pictures: Picture[];
  wash_dry_clean: AttributeValue;
  technical_and_care_sheet: string | null;
  video: string | null;
  first_certificate_image: Image | null;
  second_certificate_image: Image | null;
  third_certificate_image: Image | null;
}

export interface ProductData {
  id: string;
  type: string;
  attributes: ProductAttributes;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number
  meters: number,
  open: boolean,
  pricePer100Meters: number,
  pricePerMeter: number,
  totalPrice: number
  tokenMain: string,
  selectedColor: any,
  isSelected: boolean
  colorGradients: ColorGradient[];
  isZoomed: boolean;
  isOutOfStock:boolean;
  productId: string;
  productDescriptionData: ProductData;
  selectedImage: string;
  isOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      open: false,
      meters: "",
      pricePer100Meters: 450,
      pricePerMeter: 4.5,
      totalPrice: 10 * 4.5,
      tokenMain: "",
      isSelected: false,
      selectedColor:  { name: 'teal', gradient: 'linear-gradient(225.01deg, #FFEED8 50.53%, #FEE1BC 50.53%, #FFECD7 101.04%)' }      ,
      colorGradients: [
        { name: 'teal', gradient: 'linear-gradient(225.01deg, #FFEED8 50.53%, #FEE1BC 50.53%, #FFECD7 101.04%)' },
        { name: 'white', gradient: 'linear-gradient(225.01deg, #FDFDFD 50.53%, #F2F2F2 50.53%, #FDFDFD 101.04%)' },
        { name: 'black', gradient: 'linear-gradient(225.01deg, #010101 50.53%, #242424 50.53%, rgba(1, 1, 1, 0.866667) 101.04%)'},
        { name: 'gray', gradient: 'linear-gradient(225.01deg, #D9D9D9 50.53%, #BEBEBE 50.53%, rgba(217, 217, 217, 0.866667) 101.04%)' },
        { name: 'golden', gradient: 'linear-gradient(225.01deg, #ECB907 50.53%, #D3A501 50.53%, rgba(236, 185, 7, 0.866667) 101.04%)' },
        { name: 'yellow', gradient: 'linear-gradient(225.01deg, #F0E68C 50.53%, #DCD172 50.53%, rgba(240, 230, 140, 0.866667) 101.04%)' },
        { name: 'floral white', gradient: 'linear-gradient(225.01deg, #FFFFF0 50.53%, #F2F2D7 50.53%, rgba(255, 255, 240, 0.866667) 101.04%)' },
      ],
      isZoomed: false,
      isOutOfStock: false,
      productId: "",
      productDescriptionData:{
          id: "16",
          type: "product",
          attributes: {
            catalogue_id: null,
            manufacturer: null,
            catalogue_type: "cotton",
            display_name: "dis-name",
            short_description: "this is the short description",
            full_description: "rejfer fehrfbenf erfbernf wnr",
            width: "125.0",
            offer_sample: null,
            comments: null,
            country_of_origin: null,
            bleaching: null,
            order_minimum_quantity: 20,
            allowed_quantities: null,
            location_country: null,
            location_city: null,
            not_returnable: false,
            disable_wishlist_button: false,
            discounted_price: "60.0",
            price: "200.0",
            weight: "110.0",
            sku: "alphapro123",
            shade_depth: 99,
            stock: "500.0",
            tumble_dry: true,
            sample_in_showroom: true,
            second_certificate_name: "xyn",
            third_certificate_name: "lmn",
            pc_id: 123145678,
            available_quantity: 110,
            company: null,
            first_composition_percent: 0,
            second_composition_percent: 0,
            third_composition_percent: 0,
            material_type: { id: 9, value: "Imported" },
            dye: { id: 14, value: "WHite" },
            fabric_type: { id: 7, value: "Linen" },
            fabric_formation: { id: 7, value: null },
            currency: { id: 17, value: "INR" },
            stretch: { id: 11, value: "Full" },
            iron_temperature: { id: 15, value: "Very High" },
            fabric_pattern: { id: 6, value: "Stripped" },
            cleaning_instruction: { id: null, value: null },
            discount: "333.33",
            pictures: [
              {
                id: 3,
                url: "",
              },
            ],
            compositions: [
              {
                  id: 2,
                  value: "hwefhjwbef",
                  percent: 20
              },
          ],
            technical_and_care_sheet: null,
            video: null,
            first_certificate_image: {
              id: 4,
              url: "",
              name: "abc",
            },
            second_certificate_image: {
              id: 5,
              url: "",
              name: "xyn",
            },
            third_certificate_image: {
              id: 6,
              url: "",
              name: "lmn",
            },
            material_type: {
              id: 6,
              value: "Wool",
            },
            color: {
              id: 6,
              value: "Golden",
            },
            wash_dry_clean: {
              id: 6,
              value: "ewfrf3fer"
          },
          },
        },
        selectedImage: "",
        isOpen: false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
    if (this.isValidProductResponse(responseJson)) {
      this.apiProductSuccessCallBacks(apiRequestCallId, responseJson);
    }
    if (this.isInValidResponse(responseJson)) {
      this.apiFailureCallBacks(apiRequestCallId);
    }
  }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const product_id = await getStorageData("product_id");
    this.setState({
      productId: product_id
    })
    const authToken = await getStorageData("accessToken");
    this.setState({ 
      tokenMain: authToken
    });
   this.getProductDescription()
   
  }

  isValidProductResponse = (responseJson: ProductData) => {
    return responseJson && responseJson.data;
  };

  apiProductSuccessCallBacks = (apiRequestCallId: string, responseJson: ProductData) => {
    if (this.productApiItemCallId === apiRequestCallId) {
     this.setState({
       productDescriptionData: responseJson.data,
       isloading: false
     },() => {
      if (this.state.productDescriptionData?.attributes?.pictures?.length) {
        this.setState({ selectedImage: this.state.productDescriptionData?.attributes?.pictures[0].url });
      }
      if (this.state.productDescriptionData?.attributes?.order_minimum_quantity) {
        this.setState({meters : String(this.state.productDescriptionData?.attributes?.order_minimum_quantity) });
      }
     })
    }
  }

  isInValidResponse = (responseJson: any) => {
    return responseJson
  };
  
  apiFailureCallBacks = (apiRequestCallId: string) => {
    
  }

  getProductDescription = async () => {
    this.setState({isloading: true})
    this.productApiItemCallId = await apiCall({ method: configJSON.validationApiMethodType,  endPoint: `${configJSON.productDescriptionAPiEndPoint}/${this.state.productId}`, token: true });
  }

  handleImageSelect = (imageUrl) => {
    this.setState({ selectedImage: imageUrl });
  };

  handleColorSelect = (color: {name: string, gradient:any}) => {
    this.setState({ selectedColor: color ,
      isSelected: true
    })
  };

  handleIncrease = () => {
    const { available_quantity } = this.state.productDescriptionData.attributes;

    this.setState((prevState) => {
      const newMeters = parseInt(prevState.meters) + 1;

      if (newMeters <= available_quantity) {
        const newPrice = newMeters * prevState.pricePerMeter;
        return {
          meters: String(newMeters),
          totalPrice: newPrice,
        };
      }
      return null;
    });
  };
  
  handleDecrease = () => {
    const { order_minimum_quantity } = this.state.productDescriptionData.attributes;

    this.setState((prevState) => {
      const newMeters = prevState.meters - 1;

      if (newMeters >= order_minimum_quantity) {
        const newPrice = newMeters * prevState.pricePerMeter;
        return {
          meters: newMeters,
          totalPrice: newPrice,
        };
      }
      return null;
    });
  };

 handleZoomClick = () => {
    this.setState((prevState) => ({
      isZoomed: !prevState.isZoomed,
    }));
  };

  handleProductCloseModal = () => {
    this.setState({
      isOpen: false
    })
  }

  handleProductNavigateLogin = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleProductNavigateSignup = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleProductLoginSignup = () => {
    if(this.state.tokenMain){
      this.setState({
        isOpen: false
      })
    }else{
      this.setState({
        isOpen: true
      })
  }
}

handleSimilarProductLoginSignup = () => {
  if(this.state.tokenMain){
    this.setState({
      isOpen: false
    })
  }else{
    this.setState({
      isOpen: true
    })
}
}

handleOpenModalOne = () => {
  this.setState({
    isOpen: true
  })
}

handleOpenModalTwo = () => {
  this.setState({
    isOpen: true
  })
}

handleOpenModalThree = () => {
  this.setState({
    isOpen: true
  })
}
  // Customizable Area End
}
