import React, {useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/InputBase';
import {Typography, InputAdornment, IconButton, Autocomplete, TextField} from '@mui/material';
import { colors } from '../../blocks/utilities/src/Colors';
import {VisibilityOff, Visibility, ArrowDropDown} from '@mui/icons-material';

//img path
const  iconAddphoto = require('./icons-nav/icon-add-photo.svg');

interface ChildProps {
    dataUserInfo: any|null;
    setUserInfo:( firstName: string, 
                    lastName: string,
                    email: string,
                    jobTitle: string,
                    companyName: string,
                    country: string,
                    city: string,
                    phoneNumber: string
                ) => void;
}



function PersonalDetails(props : ChildProps) {
    const userInfo = props?.dataUserInfo?.attributes;
    const [firstName, setFirstName] = useState(userInfo?.first_name);
    const [lastName, setLastName] = useState(userInfo?.last_name);
    const [email, setEmail] = useState(userInfo?.email);
    const [jobTitle, setJobTitle] = useState(userInfo?.job_title);
    const [companyName, setCompanyName] = useState(userInfo?.company_name);
    const [country, setCountry] = useState(userInfo?.country);
    const [city, setCity] = useState(userInfo?.city);
    const [phoneNumber, setPhoneNumber] = useState(userInfo?.phone_number);
    const [showCurrentPassword, setShowCurrentPassword] = useState(true);
    const [showNewPassword, setShowNewPassword] = useState(true);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(true);


    const shortName = () => {
        let shortName = userInfo?.first_name+" "+userInfo?.last_name;
        return convertToShortName(shortName);
    }

    useEffect(() => {
        setFirstName(userInfo?.first_name);
        setLastName(userInfo?.last_name);
        setEmail(userInfo?.email);
        setJobTitle(userInfo?.job_title);
        setCompanyName(userInfo?.company_name);
        setCountry(userInfo?.country);
        setCity(userInfo?.city);
        setPhoneNumber(userInfo?.phone_number);
    }, [userInfo])

    

    const handleUpdateUserInfo = () => {
        props.setUserInfo(
            firstName,
            lastName,
            email,
            jobTitle,
            companyName,
            country,
            city,
            phoneNumber
        );
    };

  return (
    <PContainer>
        <PCHeaderInfo>
            <PCHAvatar>
                <span style={{color:'#BA9553', fontSize: '52px', fontWeight:400, lineHeight:122/52}}>{shortName()}</span>
            </PCHAvatar>
            <PCHInfor>
                <PCHIFullname>{userInfo?.first_name+" "+userInfo?.last_name}</PCHIFullname>
                <PCHIEmail>{"abc@email.com"}</PCHIEmail>
                <PCHIAddphoto>
                    <span>
                        <img src={iconAddphoto}></img>
                    </span>
                    <span style={{color:colors().darkGold, fontSize:'12px',fontWeight:400,marginLeft:'10px', }}>ADD PHOTO</span>
                </PCHIAddphoto>
            </PCHInfor>
        </PCHeaderInfo>
        <PCBodyInfor>
            <PCBPersonalInfo>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputFirstName">
                            First name
                        </StyledInputLable>
                        <CustomInput value={firstName} onChange={(event) => setFirstName(event.target.value)} id="inputFirstName" />
                        
                    </Grid>
                    <Grid item xs={6}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputSurname">
                            Surname
                        </StyledInputLable>
                        <CustomInput value={lastName} onChange={(event) => setLastName(event.target.value)} id="inputSurname" />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputWorkEmail">
                            Work email
                        </StyledInputLable>
                        <CustomInput fullWidth  value={email} onChange={(event) => setEmail(event.target.value)}  id="inputWorkEmail" />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputJobTitle">
                            Job title
                        </StyledInputLable>
                        <CustomInput value={jobTitle} onChange={(event) => setJobTitle(event.target.value)}  id="inputJobTitle" />
                    </Grid>
                    <Grid item xs={6}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputCompanyName">
                            Company name
                        </StyledInputLable>
                        <CustomInput value={companyName} onChange={(event) => setCompanyName(event.target.value)} id="inputCompanyName" />
                    </Grid>
                    
                    <Grid item xs={3.5}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputCountry">
                            Country
                        </StyledInputLable>
                        <CustomInput value={country} onChange={(event) => setCountry(event.target.value)} id="inputCountry" />
                    </Grid>
                    <Grid item xs={3.5}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputCity">
                            City
                        </StyledInputLable>
                        <CustomInput fullWidth value={city} onChange={(event) => setCity(event.target.value)} id="inputCity" />
                    </Grid>
                    <Grid item xs={5}>
                        <StyledInputLable sx={{fontSize: '14px'}} shrink htmlFor="inputPhoneNo">
                            Phone number
                        </StyledInputLable>
                        <Box className="phoneWrapper">
                        <Autocomplete
                            disablePortal
                            id="country-code"
                            options={[]}
                            className='countryCode'
                            sx={{ width: 300 }}
                            renderInput={(params) => <CustomInput
                                 {...params} 
                                 disabled={true}
                                 endAdornment={
                                    <InputAdornment position="end" className='dropdownIcon'>
                                    <IconButton
                                        className='dropdownIcon'
                                        aria-label="country-code"
                                    >
                                        <ArrowDropDown />
                                    </IconButton>
                                    </InputAdornment>
                                }
                                />}
                        />
                        <CustomInput value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} id="inputPhoneNo" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            </PCBPersonalInfo>
            <PCBAccountInfo>
                <Typography className='passwordTitle'>Password</Typography>
                <Typography className='changePasswordTitle'>Change your password</Typography>
                <Box sx={{ flexGrow: 1, paddingTop: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledInputLable sx={{fontSize: '14px', type:'password'}} shrink htmlFor="inputCurrentPassword">
                                Your current password
                            </StyledInputLable>
                            <CustomInput 
                                type={showCurrentPassword ? "password" : "text"}
                                defaultValue="123456" 
                                id="inputCurrentPassword"
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="inputCurrentPassword"
                                        onClick={() => setShowCurrentPassword(prev => !prev)}
                                        className='eyeIcon'
                                    >
                                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                             />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledInputLable sx={{fontSize: '14px', type:'password'}} shrink htmlFor="inputNewPassword">
                                New password
                            </StyledInputLable>
                            <CustomInput 
                                defaultValue="123456" 
                                type={showNewPassword ? "password" : "text"}
                                id="inputNewPassword" 
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="inputNewPassword"
                                        onClick={() => setShowNewPassword(prev => !prev)}
                                        className='eyeIcon'
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledInputLable sx={{fontSize: '14px', type:'password'}} shrink htmlFor="inputNewPassword">
                                Confirm new password
                            </StyledInputLable>
                            <CustomInput 
                                type={showConfirmNewPassword ? "password" : "text"}
                                defaultValue="123456" 
                                id="confirmNewPassword" 
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="confirmNewPassword"
                                        onClick={() => setShowConfirmNewPassword(prev => !prev)}
                                        className='eyeIcon'
                                    >
                                        {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div className='changePasswordButton' >
                    Change password
                </div>
            </PCBAccountInfo>
        </PCBodyInfor>
        <PCFooter>
            <span className='cancelChanged'>
                Cancel
            </span>
            <span className='saveChanged' onClick={() => handleUpdateUserInfo()}>
                SAVE CHANGES
            </span>
            
            
        </PCFooter>
    </PContainer>
  )
};

export default PersonalDetails;

const StyledInputLable = styled(InputLabel)(
    {
        color: colors().blackText
    }
);

const PContainer = styled('div')(
    {
        width: "935px",
        height: "1001px",
        backgroundColor: colors().white,
        paddingTop: "85px",
    }
);

const PCHeaderInfo = styled('div')(
    {
        width: "100%",
        height: "auto",
        backgroundColor: colors().white,
        display: 'flex',
    }
);
const PCHAvatar = styled('div')(
    {
        borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
        borderImageSlice: 1,
        width: '122px',
        height: '122px',
        margin: '0px 0px 0px 400px',
        borderRadius: '60%',
        backgroundColor: '#F5F1E2',
        textAlign: 'center',
        
    }
);

const PCHInfor = styled('div')(
    {
        margin: '15px 0px 0px 50px',
        textAlign: 'center',
    }
);

const PCHIFullname = styled('div')(
    {
        textAlign: 'left',
        color: '#616161',
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
    }
);
const PCHIEmail = styled('div')(
    {
        textAlign: 'left',
        color: '#78716C',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19.2px',
    }
);

const PCHIAddphoto = styled('div')(
    {
        display: 'flex',
        marginTop:"14.11px",
    }
);

//Body content main info
const PCBodyInfor = styled('div')(
    {
        width: "613px",
        height: "auto",
        backgroundColor: colors().white,
        margin: '60px 0px 0px 250px',
        
    }
);

const PCBPersonalInfo = styled('div')(
    {
        width: "603px",
        height: "324px",
        fontSize:'14px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '28px 32px 32px 32px',
        border: '1px solid #C6C6C6',
        "& .phoneWrapper" : {
            display: "flex",
            gap: "5px",
            "& .countryCode": {
                width: "35%",
            }
        }
    }
);

const PCBAccountInfo = styled('div')( () =>
    {
        return {
            width: "603px",
            fontSize:'14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginTop: 13,
            padding: '28px 32px 32px 32px',
            border: '1px solid #C6C6C6',
            "& .passwordTitle": {
                fontSize: "20px",
                lineWeight: "400px",
                lineHeight: "28px",
                color: "#1F2024",
            },
            "& .changePasswordTitle" : {
                fontSize: "16px",
                lineWeight: "400px",
                lineHeight: "24px",
                color: "#424242",
            },
            "& .changePasswordButton" : {
                width: '190px',
                height: '32px',
                padding: '6px 12px 6px 12px',
                fontSize: "16px",
                lineWeight: "400px",
                color: colors().whiteText,
                backgroundColor: colors().black,
                borderRadius: "36px",
                alignItems: "baseline",
                alignContent: "center",
                textAlign: "center",
                marginTop: "32px",
                background: "linear-gradient(#1C1917 0 0) padding-box, linear-gradient(99.09deg, #1C1917 2.64%, #1C1917 100%) border-box",
                border: "1px solid transparent",
                display: "inline-block",
            },
        }
        
    }
);

const PCFooter = styled('div')(() =>
    { 
        return { 
            width: "auto",
            height: "104px",
            backgroundColor: colors().white,
            padding: "24px 24px 0px 300px",
            alignContent: "right",
            display: "flex",
            marginLeft: "250px",

            "& .cancelChanged": {
                width: "auto",
                height: "56px",
                color: colors().darkGold,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                alignItems: "baseline",
                alignContent: "center",
                padding: "16px 0px 16px 0px",
            },
            "& .saveChanged": {
                width: "183px",
                height: "56px",
                color: colors().darkGold,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                alignContent: "center",
                textAlign: "center",
                marginLeft: "28px",
                background: "linear-gradient(#fff 0 0) padding-box, linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%) border-box",
                padding: "16px 32px",
                border: "1px solid transparent",
                borderRadius: "36px",
                display: "inline-block",
                 
            }
        }
        
    }
);

const CustomInput = styled(Input)(({theme}) => {
    return {
        position: 'relative',
        border: '1px solid #E2E8F0',
        width: "100%",
        textAlign: 'left',
        marginBottom: 10, 
        backgroundColor: '#F8FAFC',
        "& .MuiInputBase-input": {
            height: 36,
            fontSize: 14,
            backgroundColor: '#F8FAFC',
            alignContent: 'center',
            paddingLeft: 10,
            paddingRight: 10,
        },
         "& .eyeIcon": {}
    }
});

function convertToShortName(fullName: string): string {
    // Split the full name into parts based on spaces
    const parts = fullName.trim().split(/\s+/);
  
    // Ensure there are at least two parts
    if (parts.length < 2) {
      return 'Invalid Name'; // Return a default or error value
    }
  
    // Extract the first letter of the first name and last name
    const firstInitial = parts[0].charAt(0).toUpperCase();
    const lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();
  
    // Return the concatenated initials
    return firstInitial + lastInitial;
}