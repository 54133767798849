import React from "react";

// Customizable Area Start
import EmailAccountActivationController, { Props } from "./EmailAccountActivationController";
// Customizable Area End

export default class EmailAccountActivation extends EmailAccountActivationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{color:"white"}}>User activation</div>
      );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End