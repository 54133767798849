import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import {Grid, Autocomplete, Box, InputLabel, Input, InputAdornment} from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel  from "@mui/material/FormControlLabel";
import { color } from 'react-native-reanimated';
import {ArrowDropDown} from '@mui/icons-material';


function CompanyDetails() {
    //img path
    const  iconAddphoto = require('./icons-nav/icon-add-photo.svg');
    const  saveChangesButton = require('./icons-nav/icon-save-changes.svg');

    const headerData = {
        fullName: 'JENNY WILSON',
        email: 'jwilson@procurenarotaris.com',
        shortTitle: 'JW',
    }

  return (
    <PContainer>
        <PCBodyInfor>
            <PCBPersonalInfo>
                <Box sx={{display: "flex", alignItems: "center"}}>

                <div className='companyDetailTitle'>Shipping details</div>
                <FormGroup>
                    <FormControlLabel style={{margin: " 0px 0px 0px 5px"}} control={<Checkbox sx={{ color: "#C2C2C2", '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Same as company address." />
                </FormGroup>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCompanyName">
                                Name of the company
                            </InputLabel>
                            <CustomInput placeholder="Write a name of the company that the material will be delivered to" id="inputCompanyName" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputSurname">
                                Company registered address
                            </InputLabel>
                            <CustomInput placeholder="House or Building Number and Street Name" id="inputSurname" />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <CustomInput fullWidth  placeholder="Address information" id="inputWorkEmail" />
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputJobTitle">
                                City
                            </InputLabel>
                            <CustomInput placeholder="Name of the city" id="inputJobTitle" />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCompanyName">
                            Postal code
                            </InputLabel>
                            <CustomInput placeholder="XXX XXX" id="inputCompanyName" />
                        </Grid>
                        
                        <Grid item xs={8}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCountry">
                                Country
                            </InputLabel>
                            <CustomInput placeholder="Name of the country" id="inputCountry" />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCity">
                                Phone number
                            </InputLabel>
                            {/* <CustomInput fullWidth placeholder="+1 000 000 000" id="inputCity" /> */}
                            <Box className="phoneWrapper">
                        <Autocomplete
                            disablePortal
                            id="country-code"
                            options={[]}
                            className='countryCode'
                            sx={{ width: 300 }}
                            renderInput={(params) => <CustomInput
                                 {...params} 
                                 disabled={true}
                                 endAdornment={
                                    <InputAdornment position="end" className='dropdownIcon'>
                                    <IconButton
                                        className='dropdownIcon'
                                        aria-label="country-code"
                                    >
                                        <ArrowDropDown />
                                    </IconButton>
                                    </InputAdornment>
                                }
                                />}
                        />
                        <CustomInput  id="inputPhoneNo" />
                        </Box>
                        </Grid>
                    </Grid>
                </Box>
            </PCBPersonalInfo>
            <PCBAccountInfo>
                <div style={{display: "flex", alignContent:"center", textAlign:"center"}}>
                    <Typography className='billingInfoTitle'>Billing information</Typography>
                    <FormGroup>
                        <FormControlLabel style={{margin: " 0px 0px 0px 5px"}} control={<Checkbox sx={{ color: "#C2C2C2", '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Same as company address." />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel style={{margin: " 0px 0px 0px 5px"}} control={<Checkbox sx={{ color: "#C2C2C2", '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Same as shipping address." />
                    </FormGroup>                    
                </div>
                
                <Box sx={{ flexGrow: 1, paddingTop: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCurrentPassword">
                                Company’s Tax or VAT number
                            </InputLabel>
                            <CustomInput placeholder="Write a Tax or VAT ID number" id="inputCurrentPassword" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputNewPassword">
                            Name of the company
                            </InputLabel>
                            <CustomInput placeholder="Write a name of the company" id="inputNewPassword" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputNewPassword">
                            Company billing address
                            </InputLabel>
                            <CustomInput placeholder="House or Building Number and Street Name" id="inputNewPassword" />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInput placeholder="Address information" id="inputNewPassword" />
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputJobTitle">
                                City
                            </InputLabel>
                            <CustomInput placeholder="Name of the city" id="inputJobTitle" />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputCompanyName">
                            Postal code
                            </InputLabel>
                            <CustomInput placeholder="XXX XXX" id="inputCompanyName" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className='labelStyle' shrink htmlFor="inputNewPassword">
                            Country
                            </InputLabel>
                            <CustomInput placeholder="Name of the country" id="inputNewPassword" />
                        </Grid>
                    </Grid>
                </Box>
            </PCBAccountInfo>
        </PCBodyInfor>
        <PCFooter>
            <span className='cancelChanged'>
                Cancel
            </span>
            <span className='saveChanged'>
                SAVE CHANGES
            </span>
            
            
        </PCFooter>
    </PContainer>
  )
};

export default CompanyDetails;

const PContainer = styled('div')(
    {
        width: "935px",
        height: "1001px",
        backgroundColor: "#FFFFFF",
        paddingTop: "75px",
    }
);

const PCHeaderInfo = styled('div')(
    {
        width: "100%",
        height: "auto",
        backgroundColor: "#FFFFFF",
        display: 'flex',
    }
);
const PCHAvatar = styled('div')(
    {
        border: "red solid 1px",
        width: '122px',
        height: '122px',
        margin: '0px 0px 0px 400px',
        borderRadius: '60%',
        backgroundColor: '#F5F1E2',
        textAlign: 'center',
        
    }
);

const PCHInfor = styled('div')(
    {
        margin: '50px 0px 0px 50px',
        textAlign: 'center',
    }
);

//Body content main info
const PCBodyInfor = styled('div')(
    {
        width: "613px",
        height: "auto",
        backgroundColor: "#FFFFFF",
        margin: '0px 0px 0px 250px',
        
    }
);

const PCBPersonalInfo = styled('div')(
    {
        width: "603px",
        fontSize:'14px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '28px 32px 32px 32px',
        border: '1px solid #C6C6C6',
        "& .phoneWrapper" : {
            display: "flex",
            gap: "5px",
            "& .countryCode": {
                width: "35%",
            }
        },
        "& .companyDetailTitle": {
            fontSize: '20px',
            color: '#1F2024',
            fontWeight: 400,
            lineHeight: '28px',
        },
        "& .labelStyle" : {
            color: '#1E293B',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400
        },
        "& .inputStyle" : {
            color: '#64748B',
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 400
        }
    }
);

const PCBAccountInfo = styled('div')( () =>
    {
        return {
            width: "603px",
            height: "520px",
            fontSize:'14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginTop: 13,
            padding: '28px 32px 32px 32px',
            border: '1px solid #C6C6C6',
            "& .billingInfoTitle": {
                fontSize: "20px",
                lineWeight: "400px",
                lineHeight: "28px",
                color: "#1F2024",
                alignContent: "center",
                marginLeft: "8px",
            },
            "& .labelStyle" : {
                color: '#1E293B',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400
            },
            "& .inputStyle" : {
                color: '#64748B',
                fontSize: '14px',
                lineHeight: '22px',
                fontWeight: 400
            },
            "& .changePasswordButton" : {
                width: '190px',
                height: '32px',
                padding: '6px 12px 6px 12px',
                fontSize: "16px",
                lineWeight: "400px",
                color: "#FAFAF9",
                backgroundColor: '#1C1917',
                borderRadius: "36px",
                alignItems: "baseline",
                alignContent: "center",
                textAlign: "center",
                marginTop: "32px",
                background: "linear-gradient(#1C1917 0 0) padding-box, linear-gradient(99.09deg, #1C1917 2.64%, #1C1917 100%) border-box",
                border: "1px solid transparent",
                display: "inline-block",
            },
        }
        
    }
);

const PCFooter = styled('div')(() =>
    { 
        return { 
            width: "auto",
            height: "104px",
            backgroundColor: "#FFFFFF",
            padding: "24px 24px 0px 300px",
            alignContent: "right",
            display: "flex",
            marginLeft: "250px",

            "& .cancelChanged": {
                width: "auto",
                height: "56px",
                color: "#94A3B8",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                alignItems: "baseline",
                alignContent: "center",
                padding: "16px 0px 16px 0px",
            },
            "& .saveChanged": {
                width: "183px",
                height: "56px",
                color: "#64748B",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                alignContent: "center",
                textAlign: "center",
                marginLeft: "28px",
                background: "linear-gradient(#F1F5F9 0 0) padding-box, linear-gradient(99.09deg, #F1F5F9 2.64%, #F1F5F9 100%) border-box",
                padding: "16px 32px",
                border: "1px solid transparent",
                borderRadius: "36px",
                display: "inline-block",
                 
            }
        }
        
    }
);

const CustomInput = styled(Input)(({theme}) => {
    return {
        position: 'relative',
        border: '1px solid #E2E8F0',
        width: "100%",
        backgroundColor: '#F8FAFC',
        textAlign: 'left',
        marginBottom: 10, 
        "& .MuiInputBase-input": {
            height: 36,
            fontSize: 14,
            backgroundColor: '#F8FAFC',
            alignContent: 'center',
            color: '#64748B',
            // lineHeight: '22px',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 400
        },
        "& .dropdownIcon" : {
            margin: 0,
            padding: 0,
        }
    }
})