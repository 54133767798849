import React from "react";

import {
  Box,
  Typography,
  Grid, // Customizable Area Start
  // Customizable Area End
  CardContent,
} from "@mui/material";
import { Products } from "./InventoryTypes";
// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box style={webStyle.navBar}>
            <Typography variant="h4">User</Typography>
          </Box>
          <div
            style={{
              width: "90%",
              alignSelf: "center",
              backgroundColor: "red",
            }}
          />
          <Grid container spacing={2}>
            {this.state.productData.map((element: Products, index: number) => {
              return (
                <Grid
                  item
                  xs={4}
                  md={4}
                  alignItems="center"
                  justifyContent="center"
                  key={element?.attributes.id + index}
                >
                  <CardContent>
                    <Typography style={{ fontSize: 14 }} gutterBottom>
                      Product Id : {element?.attributes.id}
                    </Typography>
                    <Typography variant="h5" component="div" />

                    <Typography style={{ marginBottom: 1.5 }}>
                      Product Name : {element?.attributes.name}
                    </Typography>
                    <Typography variant="body2">
                      Product Description :{" "}
                      {element?.attributes.product_feature}
                      <br />
                    </Typography>

                    <Typography variant="body2">
                      Product Category : {element?.attributes.category_name}
                      <br />
                    </Typography>
                    <Typography variant="body2">
                      Product Category : {element?.attributes.category_name}
                      <br />
                    </Typography>
                    <Typography variant="body2">
                      Product price : {element?.attributes.price}
                      <br />
                    </Typography>
                    <Typography variant="body2">
                      Manufacture Date :{" "}
                      {moment(element?.attributes.manufacture_date).format(
                        "DD/MM/YYYY"
                      )}
                      <br />
                    </Typography>
                    <Typography variant="body2">
                      Expiry Date  :{" "}
                      {moment(element?.attributes.expiry_date).format(
                        "DD/MM/YYYY"
                      )}
                      <br />
                    </Typography>
                  </CardContent>
                </Grid>
              );
            })}
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  navBar: {
    justifyContent: "space-between",
    display: "flex",
    height: 50,
    marginTop: "2%",
  },
};
// Customizable Area End
