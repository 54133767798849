import React from "react";
import { CircularProgress } from "@mui/material";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return props.loading ? (
    <div style={webStyles.root}>
      <div style={webStyles.circularContainer}>
        <CircularProgress size={60} sx={{ color: "#2D6A4D" }} />
      </div>
    </div>
  ) : (
    <div />
  );
}

// Explicit type annotation for styles using Partial<CSSProperties>
const webStyles: { root: React.CSSProperties; circularContainer: React.CSSProperties } = {
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  circularContainer: {
    textAlign: "center" as const, // Ensure correct type is inferred
  },
};
