export const countryList = [
  {
    label: "United Kingdom",
    value: "United Kingdom",
    country_code: "+44"
  },
  {
    label: "Germany",
    value: "Germany",
    country_code: "+49"
  },
  {
    label: "Spain",
    value: "Spain",
    country_code: "+34"
  },
  {
    label: "France",
    value: "France",
    country_code: "+33"
  },
  {
    label: "Italy",
    value: "Italy",
    country_code: "+39"
  },
  {
    label: "Portugal",
    value: "Portugal",
    country_code: "+351"
  },
]

export const roleList = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Reporting",
    value: "reporting_user",
  },
]

export const statusList = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Removed",
    value: "Removed",
  },
]