import React from 'react'
//header content

// sidebar conten 1
const  iconDashboard = require('./icons-nav/icon-dashboard.svg')
const  iconInspriation = require('./icons-nav/icon-inspriation.svg')
const  iconESGreporting = require('./icons-nav/icon-esgreporting.svg')
const  iconCommercialAnalysis = require('./icons-nav/icon_commercial_analysis.svg')

// sidebar conten 2
const  iconCircularMarketplace = require('./icons-nav/icon-circular-marketplace.svg')
const  iconRequestToMarketplace = require('./icons-nav/icon-request-to-marketplace.svg')
const  iconFavorites = require('./icons-nav/icon-favorites.svg')
const  iconManageStocklist = require('./icons-nav/icon_manage_stocklist.svg')
const  iconZeroWaste = require('./icons-nav/icon_zero_waste_mapping.svg')

// sidebar conten 3
const  iconManageOrders = require('./icons-nav/icon-manage-orders.svg')
const  iconManageFinances = require('./icons-nav/icon-manage-finances.svg')
const  iconCart = require('./icons-nav/icon-carts.svg')
const  iconSetting = require('./icons-nav/icon-setting.svg')

export const NavigationSidebarDataFirst = [
    {
        name: "dashboard",
        title: "Dashboard",
        icon: iconDashboard,
        link: "Dashboard",
        secondPath: ""
    },
    {
        name: "inspiration",
        title: "Inspiration",
        icon: iconInspriation,
        link: "",
        secondPath: ""
    },
    {
        name: "esg_reporting",
        title: "ESG Reporting",
        icon: iconESGreporting,
        link: "",
        secondPath: ""
    }
]

export const NavigationSidebarDataSecond = [
    {
        name: "circular_marketplace",
        title: "Circular marketplace",
        icon: iconCircularMarketplace,
        link: "Catalogue",
        secondPath: ""
    },
    {
        name: "requests_to_marketplace",
        title: "Requests to marketplace",
        icon: iconRequestToMarketplace,
        link: "",
        secondPath: ""
    },
    {
        name: "favourites",
        title: "Favourites",
        icon: iconFavorites,
        link: "",
        secondPath: ""
    }
]

export const NavigationSidebarDataThird = [
    {
        name: "manage_orders",
        title: "Manage orders",
        icon: iconManageOrders,
        link: "",
        secondPath: ""
    },
    {
        name: "manage_finances",
        title: "Manage finances",
        icon: iconManageFinances,
        link: "",
        secondPath: ""
    },
    {
        name: "cart",
        title: "Cart",
        icon: iconCart,
        link: "",
        secondPath: ""
    },
    {
        name: "settings",
        title: "Settings",
        icon: iconSetting,
        link: "UserSettings",
        secondPath: "PersonalDetails"
    },
    
]

export const BuyerSidebarDataFirst = [
    {
        name: "dashboard",
        title: "Dashboard",
        icon: iconDashboard,
        link: "Dashboard",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "inspiration",
        title: "Inspiration",
        icon: iconInspriation,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "esg_reporting",
        title: "ESG Reporting",
        icon: iconESGreporting,
        link: "",
        secondPath: "",
        isLocked: true,
        commingSoon: false,
    },
]

export const BuyerSidebarDataSecond = [
    {
        name: "circular_marketplace",
        title: "Circular marketplace",
        icon: iconCircularMarketplace,
        link: "Catalogue",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "requests_to_marketplace",
        title: "Requests to marketplace",
        icon: iconRequestToMarketplace,
        link: "",
        secondPath: "",
        isLocked: true,
        commingSoon: false,
    },
    {
        name: "favourites",
        title: "Favourites",
        icon: iconFavorites,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
]

export const BuyerSidebarDataThird = [
    {
        name: "basket",
        title: "Basket",
        icon: iconCart,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "order_management",
        title: "Order Management",
        icon: iconManageOrders,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "manage_finances",
        title: "Financial Accounts",
        icon: iconManageFinances,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "settings",
        title: "Settings",
        icon: iconSetting,
        link: "UserSettings",
        secondPath: "PersonalDetails",
        isLocked: false,
        commingSoon: false,
    },
    
]

export const SupplierSidebarDataFirst = [
    {
        name: "dashboard",
        title: "Dashboard",
        icon: iconDashboard,
        link: "Dashboard",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "esg_reporting",
        title: "ESG Reporting",
        icon: iconESGreporting,
        link: "",
        secondPath: "",
        isLocked: true,
        commingSoon: false,
    },
    {
        name: "commercial_analysis",
        title: "Commercial analysis",
        icon: iconCommercialAnalysis,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: true,
    },
]

export const SupplierSidebarDataSecond = [
    {
        name: "manage_stocklist",
        title: "Manage stocklist",
        icon: iconManageStocklist,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "circular_marketplace",
        title: "Circular marketplace",
        icon: iconCircularMarketplace,
        link: "Catalogue",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "marketplace_requests",
        title: "Marketplace requests",
        icon: iconManageStocklist,
        link: "",
        secondPath: "",
        isLocked: true,
        commingSoon: false,
    },
    {
        name: "zero_waste_mapping",
        title: "Zero-Waste mapping",
        icon: iconZeroWaste,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: true,
    },
]

export const SupplierSidebarDataThird = [
    {
        name: "order_management",
        title: "Order Management",
        icon: iconManageOrders,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "financial_accounts",
        title: "Financial Accounts",
        icon: iconManageFinances,
        link: "",
        secondPath: "",
        isLocked: false,
        commingSoon: false,
    },
    {
        name: "settings",
        title: "Settings",
        icon: iconSetting,
        link: "UserSettings",
        secondPath: "PersonalDetails",
        isLocked: false,
        commingSoon: false,
    },
    
]
