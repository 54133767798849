import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { AccountData, Meta } from "../../../blocks/utilities/src/typeInterface";
import { apiCall, handleExpiredToken } from "../../../blocks/utilities/src/CommonFunctions";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  allowManageUser: boolean;
  maxUserCount: number;
  openModal: boolean;
  usersList: AccountData[],
  meta: Meta,
  user_id: string | number;
  anchorEl: HTMLElement | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class UsersTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUsersListApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      allowManageUser: false,
      openModal: false,
      usersList: [],
      meta: {
        total_pages: 1,
        total_records: 1,
        current_page: 1,
        next_page: null,
        previous_page: null
      },
      user_id: "",
      anchorEl: null,
      maxUserCount: configJSON.maxUserCount
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJsonData, this.props);
      this.setState({loading: false});
      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getUsersListApi) {
          this.getUsersListResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    getStorageData("accountDetails", true).then(responseJsonData => {
      this.setState({ allowManageUser: responseJsonData.user_role === configJSON.admin });
    });
    this.getUsersList();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    const { current_page } = this.state.meta
    if ( current_page !== prevState.meta.current_page ) {
      this.getUsersList();
    }
  }

  getUsersList = async () => {
    this.setState({loading: true});
    const params = { page: this.state.meta.current_page };
    this.getUsersListApi = await apiCall({ method: configJSON.getMethod, endPoint: configJSON.addUserEndpoint, token: true, params });
  }

  getUsersListResponse = (responseData: {data: AccountData[], meta: Meta}) => {
    if (responseData.data.length) {
      this.setState((prev) => ({
        usersList: responseData.data,
        meta: responseData.meta ?? prev.meta,
      }))
    }
  }
  addUser = () => {
    this.setState({ openModal: true });
  };
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleClick = (event: { currentTarget:HTMLElement | null; }, user_id: string | number) => {
    this.setState({ anchorEl: event.currentTarget, user_id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, user_id: "" });
  };

  handlePageChange = (_event: React.ChangeEvent<unknown> | null,
    newPage: number,) => {
    this.setState({
      meta: {
        ...this.state.meta,
        current_page: newPage
      }
    });
  }
  // Customizable Area End
}
