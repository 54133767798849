import React from 'react'

export const SettingsMenuBarData = [
    {
        title: "Personal Details",
        link: "/#"
    },
    {
        title: "Company Details",
        link: "/#"
    },
    {
        title: "Shipping Address",
        link: "/#"
    },
    {
        title: "Payment Details",
        link: "/#"
    },
    {
        title: "User Management",
        link: "/#"
    },
    {
        title: "Subscription Plans",
        link: "/#"
    }
]