import React from "react";

// Customizable Area Start
import { styled } from '@mui/material/styles';
import { Box, TextField, Button, Grid, Typography, InputAdornment } from '@mui/material';
import { image, headerImage, mobileImage, checkImage, logoImage} from "./assets";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { StyleSheet } from "react-native";

const StyledLabel = styled(Typography)({
  fontFamily:"brandon-grotesque, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  textAlign:"left",
  lineHeight:"20px",
  color:"#1E293B",
  letterSpacing:"0.05em",
});
const StyledHeading = styled("div")({
    font:"Garamond Premier Pro",
    fontSize: "56px",
    fontWeight: 400,
    textAlign:"left",
    lineHeight:"50px",
    color:"white",
    marginTop:"60px",
    fontFamily: 'garamond-premier-pro !important',
    letterSpacing:"0.05em",
    "@media(max-width:500px)": {
      lineHeight:"58px",
      marginTop:"52px",
    },
    "@media(min-width:500px) and (max-width:850px)": {
      lineHeight:"60px",
      marginTop:"10px",
    },
  });
  const StyledContainer = styled("div")({
    width: "96%",
    height:"80vh",
    marginLeft: '2%',
    marginRight: '2%',
    backgroundImage:`url(${image})`,
    outline: '1px solid #CCB26F',
    outlineOffset: '-21px',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundSize: "cover",
    "@media(max-width:500px)": {
      backgroundImage:`url(${mobileImage})`,
      outline: '1px solid #CCB26F',
      outlineOffset: '-21px',
      width: "100%",
      marginLeft: '0',
      marginRight: '0',
      backgroundSize: "cover",
      height:"auto"
    },
    "@media(min-width:500px) and (max-width:940px)":{
      height:"auto",
    }
  });
  const InnerContainer= styled(Box)({
    padding:"10px", 
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "auto", 
    width:"70%",
    "@media(max-width:500px)": {
      width:"90%",
      flexDirection: "column"
    },
    "@media(min-width:500px) and (max-width:850px)": {
      width:"90%",
    }
  })
  const LeftBox1= styled(Box)({
    marginTop: '55px',
    "@media(max-width:500px)": {
      marginTop: '55px',
    },
    "@media(min-width:500px) and (max-width:850px)": {
      marginTop: '5px',
    }
  })
  const LeftContainer= styled(Box)({
    padding:"33px",
    flexDirection: 'column',
    alignContent: 'center',
    display: 'flex',
    width: "40%",
    marginTop:"30px",
    height: "auto",
    opacity: "0px",
    "@media(max-width:500px)": {
      width:"90%",
      padding:'0px'
    },
    "@media(min-width:500px) and (max-width:850px)": {
      marginTop:"5px",
    }
  })
  const RightContainer= styled(Box)({
    width: '35%',
    height: '423px',
    backgroundColor: 'white',
    display: 'flex',
    opacity: "0px",
    border: '4px solid #BA9553',
    "@media(max-width:500px)": {
      width:"90%",
      flexDirection: "column",
      marginBottom:"20px",
      alignItems:"center"
    },
    "@media(min-width:500px) and (max-width:850px)":{
      marginTop:"20px",
      marginBottom:"20px",
      alignItems:"center"
    }
  })
  const SignInContainer= styled(Box)({
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:500px)": {
      width:"90%",
      paddingTop:'32px',
      // paddingLeft:'10px'
    },
    "@media(min-width:500px) and (max-width:850px)": {
      // paddingLeft:"12px",
    }
  })

  const StyledHeader = styled(Box)({
    marginTop: '30px',
      marginLeft: '50px',
      display:"flex",
      "@media(max-width:500px)": {
        flexDirection: "row-reverse",
        justifyContent: "space-between"
      },
      "@media(min-width:500px) and (max-width:850px)": {
        flexDirection: "row-reverse",
        justifyContent: "space-between"
      }
  });
  const StyledHeading2 = styled("div")({
    fontSize: "16px",
    fontWeight: 420,
    textAlign:"left",
    lineHeight:"22.88px",
    color:"white",
    marginTop:"15px",
    letterSpacing:"0.05em",
    fontFamily: 'brandon-grotesque, sans-serif',
    "@media(max-width:500px)": {
      marginTop:"0px",
      marginBottom:"30px",
    }
  });
  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      color: "#64748B",
      fontSize: "14px",
      height: "36px",
      backgroundColor: "#F8FAFC",
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: "#DC2626",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: '1px solid transparent',
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      marginLeft: "initial",
      color:'#DC2626',
    },
    '& .MuiFormHelperText-root.Mui-error':{
      color:'#DC2626',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#292524",
      },
    },
    '& .MuiInputBase-input': {
      height: '2px',
      color: '#292524',
    },
  '& .MuiInputBase-input::placeholder': {
    color: '#64748B', 
  },
  });

const StyledButton = styled(Button)({
  color:"#B7862B", 
  borderRadius:"36px", 
  width:"100%",
  height:"44px",
  marginTop:"20px", 
  fontSize: '16px',
  fontWeight:390,
  border:"1px solid #B7862B",
  cursor:"pointer",
  "&:hover": {
    backgroundColor: "white",
  },
})
const StyledMessage = styled(Box)({
  backgroundColor: "#F4FFF3",
  padding: "20px",
  fontSize: "16px",
  fontWeight: "390",
  lineHeight: "24px",
  color: "#528A6F",
  marginTop: "10%",
  marginBottom: "5%",
  display:"flex",
});
const styles1 = {

  root: {
      marginLeft: '69px',
      width: '1488px',
      height: '727px',
      position: 'relative',

  },
  backgroundImage: {
      position: 'relative',
      padding: '13px 56px 45px 56px',
  },
  imageContainer: {
      position: 'relative',
      width: '100%',
      height: '726px',
  },
  image: {
    outline: ' 1px solid #CCB26F',
    outlineOffset: '-21px'
  },

  ribbonImage: {
    height: '100%'
  },
  
  customButton: {
      marginTop: '55px !important',
      fontFamily: 'brandon-grotesque, sans-serif',
      fontWeight: '420',
      width: '213px',
      height: '44px',
      borderRadius: '36px !important',
      border: '1px solid #B07A25 !important',
      padding: '10px 24px',
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      backgroundColor: '#F5F1E2 !important',
      color: '#B07A25 !important',
      cursor: 'pointer',
      textAlign: 'center',
  },
  headtext: {
      fontWeight: '400',
      fontSize: '32px !important',
      color: 'white',
  },
  subHead: {
      fontSize: '16px',
      color: 'white',
  }
  
};
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div data-test-id="testDiv" style={{height:"100%"}}>
      <Box>
        <StyledHeader>
          <img src={headerImage}/>
          <img style={{ height:"15px",marginTop:'20px' }} src={logoImage}/>
        </StyledHeader>
      </Box>
      
       <StyledContainer>
        <InnerContainer>
          <LeftContainer>
              <Typography style={{ fontSize: '32px', color: '#F8D18D', fontWeight: '420' }}>SIGN IN</Typography>
              <Typography style={{ fontSize: '12px', color: 'white', fontWeight: '420', textTransform: "uppercase" }}>
                  I don’t have an account, I want to <span style={{ color: '#F8D18D', textTransform: "uppercase",cursor: "pointer" }} onClick={()=>{this.clickSignUp()}} data-test-id="signInBtn">Sign up</span>
              </Typography>
              <StyledHeading>Welcome Back</StyledHeading>
              <LeftBox1>
                  <StyledHeading2>
                      Log in to access your dedicated portal for sustainable procurement. Start or continue your circular journey today!
                  </StyledHeading2>
                  <StyledHeading2>
                      Need assistance? <a style={{ color: '#F8D18D', cursor: "pointer"}} rel="noopener noreferrer" href="https://procurecircular.com/contact" target="_blank" data-test-id="contactUsBtn">Contact us</a> for help with your account.
                  </StyledHeading2>
              </LeftBox1> 
          </LeftContainer>
          <RightContainer>
                <SignInContainer>
                  {this.state.fType === 'signIN' ? 
                  <form onSubmit={this.handleSubmit} noValidate autoComplete="off" style={{width:"85%"}}>
                  <Typography style={{
                      fontFamily: "brandon-grotesque, sans-serif",
                      fontSize: "16px",
                      fontWeight: "420",
                      lineHeight: "22.88px",
                      textAlign: "left",
                      color: "#475569",
                      marginBottom:"26px"
                    }}>Please enter your details to log in</Typography>
                      <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                              <StyledLabel>Your Email</StyledLabel>
                              <StyledTextField
                                  data-test-id="emailInp"
                                  placeholder="name@companyname.com"
                                  name="email"
                                  id="standard-error-helper-text"
                                  value={this.state.email}
                                  onChange={(event)=>this.handleChange('email' ,event.target.value)}
                                  helperText={this.state.emailError}
                                  error={!!this.state.emailError}
                                  inputProps={{
                                    form: {
                                      autocomplete: 'off',
                                    },
                                  }}
                                  sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover fieldset': {
                                        borderColor: '#F8FAFC',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#F8FAFC',
                                      },
                                    },
                                  }}
                              />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                              <StyledLabel>Password</StyledLabel>
                              <StyledTextField
                                  type={this.state.enablePasswordField ? 'password' : 'text'}
                                  data-test-id="passInp"
                                  placeholder="*******"
                                  inputProps={{
                                    form: {
                                      autocomplete: 'off',
                                    },
                                  }}
                                  name="email"
                                  id="standard-error-helper-text"
                                  value={this.state.password}
                                  onChange={(event)=>this.handleChange('password' ,event.target.value)}
                                  helperText={this.state.passwordError}
                                  error={!!this.state.passwordError}
                                  sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover fieldset': {
                                        borderColor: '#F8FAFC',
                                      },
                                      '&.Mui-focused fieldset': {
                                        borderColor: '#F8FAFC',
                                      },
                                    },
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                        <InputAdornment data-testid='showPasswordContainer' data-show={this.state.enablePasswordField} position="end">
                                        {this.state.enablePasswordField ? 
                                            <VisibilityOutlinedIcon 
                                            data-test-id="togglePasswordVisibility"
                                            style={{cursor: "pointer",color:"#94A3B8",height:'16px',width:"16px"}}
                                            data-testid="hidePassword" onClick={this.handleClickShowPassword} 
                                            /> :
                                            <VisibilityOffOutlinedIcon 
                                            data-test-id="togglePasswordVisibility"
                                            style={{cursor: "pointer",color:"#94A3B8", height:'16px',width:"16px"}}
                                            data-testid="showPassword" onClick={this.handleClickShowPassword} />
                                        }
                                        </InputAdornment>
                                    ),
                                    }}
                              />
                          </Grid>
                      </Grid>
                      <Typography
                          variant="body2"
                          align="left"
                          style={{
                              font: "Brandon  Grotesque",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#424242",
                              letterSpacing: "0.05em",
                              marginTop:"10px"
                          }}
                          >
                          You don’t have an account?{" "} 
                          <span
                              onClick={()=>{this.clickSignUp()}} 
                              data-test-id="signInBtn1"
                              style={{
                                textDecoration: "none",
                                color: "#BA9553",
                                letterSpacing: "0.05em",
                                cursor: "pointer"
                              }}
                          >
                              Create one
                          </span>
                      </Typography>
                      <StyledButton type="submit" data-test-id='registerButton' onClick={(e)=>this.handleSubmit(e)}>LOG IN</StyledButton>
                      <Typography
                          variant="body2"
                          align="center"
                          style={{
                              fontFamily: "brandon-grotesque, sans-serif",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#424242",
                              letterSpacing: "0.05em",
                              marginTop:"9px"
                          }}
                          >
                          Forgot Password?{" "}
                          <span
                              style={{
                              textDecoration: "none",
                              color: "#BA9553",
                              letterSpacing: "0.05em",
                              cursor: "pointer"
                              }}
                              onClick={()=>{this.clickReset('reset')}}
                              data-test-id="resetButton"
                          >
                              Reset here
                          </span>
                      </Typography>
                  </form>
                  : ""}
                  {
                    this.state.fType === 'reset' ?
                      <form onSubmit={this.handleResetPassword} noValidate autoComplete="off" style={{width:"85%"}}>
                    <Typography style={{
                        fontSize: "16px",
                        fontWeight: "420",
                        lineHeight: "22.88px",
                        textAlign: "left",
                        color: "#616161"
                      }}>PASSWORD RESET</Typography>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <StyledLabel style={{marginTop: "25px"}}>Your Email</StyledLabel>
                                <StyledTextField
                                    data-test-id="emailInp1"
                                    placeholder="name@companyname.com"
                                    name="email"
                                    id="standard-error-helper-text"
                                    value={this.state.email}
                                    onChange={(event)=>this.handleChange('email' ,event.target.value)}
                                    helperText={this.state.emailError}
                                    error={!!this.state.emailError}
                                    sx={{
                                      width: '100%',
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                      },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        
                        <StyledButton type="submit" data-test-id='resetPassButton' onClick={(e)=>this.handleResetPassword(e)}>Reset Password</StyledButton>
                        <Typography
                            variant="body2"
                            align="left"
                            style={{
                                font: "Brandon  Grotesque",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#424242",
                                letterSpacing: "0.05em",
                                marginTop:"30px"
                            }}
                            >
                            I remember my password.{" "} 
                            <span
                                onClick={()=>{this.clickReset('signIN')}} 
                                data-test-id="loginButton1"
                                style={{
                                  textDecoration: "none",
                                  color: "#BA9553",
                                  letterSpacing: "0.05em",
                                  cursor: "pointer"
                                }}
                            >
                                Log in
                            </span>
                        </Typography>
                        <Typography
                            variant="body2"
                            align="left"
                            style={{
                                font: "Brandon  Grotesque",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#424242",
                                letterSpacing: "0.05em",
                                marginTop:"10px"
                            }}
                            >
                            You don’t have an account?{" "} 
                            <span
                                onClick={()=>{this.clickSignUp()}} 
                                data-test-id="signUpBtn"
                                style={{
                                  textDecoration: "none",
                                  color: "#BA9553",
                                  letterSpacing: "0.05em",
                                  cursor: "pointer"
                                }}
                            >
                                Create one
                            </span>
                        </Typography>
                      </form>
                     :
                    ""
                  }
                  {
                    this.state.fType === 'showSuccess' ?
                    <div style={{width:"85%", marginBottom:"20%"}}>
                      <Typography style={{
                          fontSize: "16px",
                          fontWeight: "420",
                          lineHeight: "22.88px",
                          textAlign: "left",
                          color: "#616161",
                          marginTop:"5%"
                        }}>PASSWORD RESET</Typography>
                        <StyledMessage>
                          <div style={{marginRight:"16px",display:"flex", justifyContent:"center",alignItems:"center"}}><img src={checkImage} height="33px" width="33px"/></div>
                          <div>A link activating the password change was sent to the email address provided.</div>
                      </StyledMessage>
                     
                      <StyledButton type="submit" data-test-id='loginButton3' onClick={()=>{this.clickReset('signIN')}}>LOG IN</StyledButton>
                      </div>: ""
                  }
              </SignInContainer>
          </RightContainer>
        </InnerContainer>
      </StyledContainer> 
      </div>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
