import React from "react";

// Customizable Area Start
import { Grid, Typography, Container } from '@mui/material';
import { styled } from "@mui/styles";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return(
      <NavigationWrapper {...this.props}>
        <TermsContainer style={{ maxWidth: '1001px' }}>
      <SecondMainContainer container direction="column" spacing={2}>
  <Grid item>
    <Title>
      TERMS AND CONDITIONS
    </Title>
  </Grid>
  <Grid item>
    <Typography style={{color:"#162A26",fontWeight:420, fontSize:"15.1px",margin: "0px 0px 32px"}} variant="body1" paragraph>
      Welcome to Procure Circular. By using our web app, you agree to comply with and be bound by the following terms and conditions.
      Please review these terms carefully. If you do not agree to these terms, you should not use this site.
    </Typography>
  </Grid>
  <Grid style={{marginTop:"15px"}} item>
    <SectionTitle >
      1. Acceptance of Terms
    </SectionTitle>
    <Typography>
     <SectionContent>
       By using our website and services, you agree to these Terms and Conditions and our Privacy Policy. If you do not agree, you should not use our site or services.
       </SectionContent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      2. Privacy Policy
    </SectionTitle>
    <Typography variant="body1" >
      <SectionContent>
      Our Privacy Policy, which is part of these Terms and Conditions, describes how we handle the information you provide to us. You can review our Privacy Policy <span style={{ textDecoration: "underline" }}>here</span>
       </SectionContent>     
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle variant="h6" >
      3. User Registration
    </SectionTitle>
    <Typography variant="body1" component="ul">
      <SectionContent>
      During the registration process, users are required to accept these Terms and Conditions and the Privacy Policy.
      </SectionContent>
    </Typography>
    <Typography variant="body1" component="ul">
      <SectionContent>
      Links to these documents are provided, and users cannot complete registration without agreeing to them.
      </SectionContent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle >
      4. User Content
    </SectionTitle>
    <Typography variant="body1" component="ul">
      <SectionContent>
        You are responsible for the content you upload, post, email, transmit, or otherwise make available via our services. This includes any product information, images, and interactions on the platform.
      </SectionContent>
    </Typography>
    <Typography variant="body1" component="ul">
      <SectionContent>
      You must ensure that your content does not infringe on any third-party rights or violate any laws.
      </SectionContent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      5. Prohibited Conduct
    </SectionTitle>
    <SectionContentComponent variant="body1">
      You agree not to use our services for any unlawful or prohibited activities. You shall not:
      <ul>
        <SectionContent>Upload or transmit any content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights.</SectionContent>
        <SectionContent>Use our services to distribute spam or malicious software.</SectionContent>
        <SectionContent>Interfere with or disrupt the services or servers.</SectionContent>
      </ul>
    </SectionContentComponent>
  </Grid>
  <Grid item>
    <SectionTitle>
      6. Third-Party Accounts
    </SectionTitle>
    <Typography variant="body1">
      <SectionContentComponent variant="body1">
      You may link your account to third-party services such as social media platforms. By linking your account, you authorize us to access certain information from these accounts in accordance with our Privacy Policy.
      </SectionContentComponent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      7. Termination
    </SectionTitle>
    <Typography variant="body1">
      <SectionContentComponent variant="body1">
     We may terminate or suspend your account at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users.
      </SectionContentComponent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      8. Limitation of Liability
    </SectionTitle>
    <Typography variant="body1">
      <ul>
     <SectionContent>
     Procure Circular and its affiliates will not be liable for any indirect, incidental, or consequential damages arising out of your use of our services.
      </SectionContent>
      <SectionContent>
     Our total liability to you will not exceed the amounts paid by you to us over the preceding 12 months.
      </SectionContent>
      </ul>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      9. Indemnification
    </SectionTitle>
    <Typography variant="body1">
      <SectionContent>
      You agree to indemnify and hold Procure Circular and its affiliates harmless from any claims, losses, or damages, including legal fees, arising from your use of our services or your violation of these Terms and Conditions.
      </SectionContent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      10. Governing Law
    </SectionTitle>
    <Typography variant="body1" >
      <SectionContent>
      These Terms and Conditions are governed by the laws of the United Kingdom. Any disputes will be resolved in the courts of the United Kingdom.
      </SectionContent>
    </Typography>
  </Grid>
  <Grid item>
    <SectionTitle>
      11. Changes to Terms
    </SectionTitle>
    <Typography variant="body1" >
      <SectionContent>
      We may update these Terms and Conditions from time to time. We will notify users of significant changes via email or through our services. Continued use of the services after such changes constitutes your acceptance of the new terms.
      </SectionContent>
    </Typography>
  </Grid>
  <Grid item style={{marginBottom:"58px"}}>
    <SectionTitle>
      12. Contact Information
    </SectionTitle>
    <Typography variant="body1">
      <SectionContent>
      For any questions about these Terms and Conditions, please contact us at <span data-test-id="contact-navigate" onClick={this.handleContactUs}style={{ textDecoration: "underline" }}>Contact Us</span>.
      </SectionContent>
    </Typography>
  </Grid>
   </SecondMainContainer>
    </TermsContainer>
      </NavigationWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const SecondMainContainer = styled(Grid)({
  '@media (max-width: 900px)': {
    maxWidth:"1001px !important"
},
})

const Title = styled(Typography)({
marginBottom: "20px",
  fontWeight: "420",
  color:"#2D6A4D",
  fontSize:"24px",
});

const SectionTitle = styled(Typography)({
  fontWeight: "Bold",
   fontSize:"16px", 
   fontFamily:"garamond-premier-pro !important",
   color:"#162A26"
});

const SectionContent = styled('li')({
  lineHeight: "1.6",
   fontSize:"16px",
    fontWeight:"Medium",
    color:"#162A26",
    marginTop: "15px",
    fontFamily:"garamond-premier-pro !important",
});

const SectionContentComponent = styled(Typography)({
  color:"#162A26",
  fontSize:"16px",
  fontWeight:"Medium",
  marginTop:"15px",
  fontFamily:"garamond-premier-pro !important"
})

const TermsContainer = styled(Container)({
  padding: "20px",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
});


// Customizable Area End
