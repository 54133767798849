import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material';
import { toast } from "react-toastify";
import { convertFormData, handleApiError } from "../../../blocks/utilities/src/CommonFunctions";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  contactReason:string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  authToken:string;
  nameError:string;
  surnameError:string;
  emailError: string;
  phoneNumberError:string
  cityErrorMessage:string
  commentsError:string;
  contactReasonError:string;
  files:any;
  phoneError:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      surname:"",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      authToken:"",
      nameError:"",
      surnameError:"",
      emailError: "",
      phoneNumberError:"",
      cityErrorMessage:"",      
      commentsError:"",   
      contactReason:"" ,
      contactReasonError:"",
      files:null,
      phoneError:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.addContactApiCallId) {
        if (responseJson && responseJson.data){
            toast.success("Your request has been submitted successfully.");
            this.closeContactPopup()
          // success
        }
        if (responseJson.message) {
          handleApiError(responseJson.message);
          //error
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const authToken = await getStorageData("accessToken");
    this.setState({authToken:authToken})
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  handleSendMessage =()=>{
    if(this.validateData()){
      this.sendQuery()
    }
  }
  handleFiles=(event:any)=>{
    let file = event.target.files[0];
        if (file) {
          this.setState({files:file})
        }
  }
  sendQuery= async()=> {
    let attrs = null;
    attrs = {
      name: this.state.name,
      surname: this.state.surname,
      email: this.state.email,
      description: this.state.comments,
      reason: this.state.contactReason,
      phone_number: this.state.phoneNumber,
    }
    if(this.state.files){
      attrs = {
        ...attrs,
        "attachment": this.state.files
      }
    }

    const header = {
      // "Content-Type": "",
      // token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      convertFormData(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  validateData(): boolean {  
    let emailError = false;
    let emailErrorMsg = "";
    if(!this.validateEmail(this.state.email)) {
      emailError = true;
      emailErrorMsg = "Please enter a valid email."
    }
    if(this.state.email.length === 0){
      emailError = true;
      emailErrorMsg = "This field can't be empty"
    }
    const isEmptyFirstName = this.state.name.length < 1;
    const emptyFirstNameError = isEmptyFirstName ? "This field can't be empty" : "";

    const isEmptySurnameError = this.state.surname.length < 1;
    const surnameError = isEmptySurnameError ? "This field can't be empty" : "";

    const isEmptyContactReason = this.state.contactReason.length < 1;
    const contactReasonError = isEmptyContactReason ? "This field can't be empty" : "";
  
    const isEmptyComments = this.state.comments.length < 1;
    const emptyCommentsError = isEmptyComments ? "This field can't be empty" : "";
  
  
    this.setState({
      nameError: emptyFirstNameError,
      surnameError: surnameError,
      emailError: emailErrorMsg,
      commentsError:emptyCommentsError,
      contactReasonError:contactReasonError
    });
    if (emptyFirstNameError || emailError || surnameError || contactReasonError || emptyCommentsError || this.state.phoneError) {
      return false;
    }
    
    return true;
  }
  handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOption = event.target.value as string;
    this.setState({ contactReason: selectedOption},()=>{
      const { contactReason } = this.state;
      if (contactReason.length === 0) {
        this.setState({ contactReasonError: "This field can't be empty" });
      } else {
        this.setState({ contactReasonError: '' });
      }
    })
  };
  handleChange = (name:string, value:string): void => {
    if(name === 'phoneNumber'){
      value = value.replace(/\D/g, '');
    }
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>,()=>{
      if (name === 'name') {
        this.validatefirstNameField();
      }
      else if(name === 'surname'){
        this.validateSurnameField();
      }
      else if(name === 'email'){
        this.validateworkEmailField();
      }
      else if(name === 'comments'){
        this.validateCommentsField();
      }
      else if(name === 'phoneNumber'){
        this.validatephoneField();
      }
    });
  };
  validatephoneField = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber.length < 6 ) {
      this.setState({ phoneError: "Phone number should not be less than 6 digit long" });
    }else if(phoneNumber.length > 15) {
      this.setState({ phoneError: "Phone number should not be more than 15 digit long" });
    }else {
      this.setState({ phoneError: '' });
    }
  }
  validateEmail = (email:string) => {
    const atSymbolIndex = email.indexOf('@');
    const dotSymbolIndex = email.lastIndexOf('.');
    return (
      atSymbolIndex > 0 &&
      dotSymbolIndex > atSymbolIndex + 1 &&
      dotSymbolIndex < email.length - 1
    );
  }
  validateworkEmailField = () => {
    const { email } = this.state;
    if (!this.validateEmail(email)) {
      this.setState({ emailError: "Please enter a valid email." });
    } else {
      this.setState({ emailError:'' });
    }
  }
  validatefirstNameField = () => {
    const { name } = this.state;
    if (name.length === 0) {
      this.setState({ nameError: "This field can't be empty" });
    } else {
      this.setState({ nameError: '' });
    }
  }
  validateCommentsField = () => {
    const { comments } = this.state;
    if (comments.length === 0) {
      this.setState({ commentsError: "This field can't be empty" });
    } else {
      this.setState({ commentsError: '' });
    }
  }
  validateSurnameField = () => {
    const { surname } = this.state;
    if (surname.length === 0) {
      this.setState({ surnameError: "This field can't be empty" });
    } else {
      this.setState({ surnameError: '' });
    }
  }
  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };
  openContactPopup=()=>{
    this.setState({isVisible:true})
  }
  closeContactPopup=()=>{
    this.setState({
      isVisible:false,
      nameError: "",
      surnameError: "",
      emailError: "",
      commentsError:"",
      contactReasonError:"",
      files:null,
      name:"",
      surname:"",
      email:"",
      phoneNumber:"",
      contactReason:"",
      comments:"",
      phoneError:""
    })
  }
  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  // Customizable Area End
}
