import React, { useState } from 'react';
import {SettingsMenuBarData } from './SettingsMenuBarData';
import PersonalDetails from './PersonalDetails';
import CompanyDetails from './CompanyDetails';
import {UserInfoLogged} from '../../blocks/settings2/src/Settings2Controller';

interface ChildProps {
    userInfoData: UserInfoLogged|null;
    onUpdateUserInfo: (
        firstName: string,
        lastName: string,
        email: string,
        jobTitle: string,
        companyName: string,
        country: string,
        city: string,
        phoneNumber: string
    ) => void;

}
function Settings(props: ChildProps) {
    const {userInfoData, onUpdateUserInfo} = props;
    const [indexSelected, setIndexSelected] = useState(0);

    const renderContent = (): React.ReactNode => {
        switch (indexSelected) {
          case 0:
            return <PersonalDetails dataUserInfo={userInfoData} setUserInfo={onUpdateUserInfo} />;
          case 2:
            return <CompanyDetails />;
          default:
            return <PersonalDetails dataUserInfo={userInfoData} setUserInfo={onUpdateUserInfo}/>;
        }
      };
  return (
    <>
        <div style={menuBarStyle.settingsTitle}>SETTINGS</div>
        <div style={menuBarStyle.common}>
        {SettingsMenuBarData.map((item, index) => (
            <div key={index} style={{...menuBarStyle.menuItemStyle,
                backgroundColor: (indexSelected === index)  ? '#F5F1E2' : '#ffffff',
                color: (indexSelected === index)  ? '#B7862B' : '#334155',
            }} onClick={() => setIndexSelected(index)}>{item.title}</div>
        ))}
        </div>

        {renderContent()}
    </>
    
  )
};

export default Settings;

const menuBarStyle = {
    common: {
        width: '100%',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center',
    } as React.CSSProperties,

    settingsTitle : {
        fontSize: '24px', 
        color: '#4C6967',
        width: '100%', 
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '34.32px',
        marginBottom: '14px',
    } as React.CSSProperties,

    menuContentParent :{
        float: 'left',
        width: '100%',
        height: 'auto',
    },

    menuItemStyle : {
        cursor: 'pointer',
        fontSize: '14px', 
        transition: 'background-color 0.3s ease',
        fontWeight: 400,
        lineHeight: '22px',
        color: '#334155',
        textAlign: 'left',
        padding: '8px 12px 8px 12px',
        borderRadius: '8px',
        marginLeft: '10px',

    } as React.CSSProperties,
};

