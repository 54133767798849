Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.UserInfoLoggedApiEndPoint = "account/accounts/logged_user";
exports.UserInfoLoggedApiMethod = "GET";

exports.UpdateUserInfoApiEndPoint = "account/update";
exports.UpdateUserInfoApiMethod = "PUT";

exports.UserInfoApiEndPoint = "";
exports.UserInfoApiMethod = "GET";

// Customizable Area End