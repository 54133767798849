import React from "react";

// Customizable Area Start
import { Container, Box, Input, InputLabel, Typography } from "@mui/material";
import Select from "react-select";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import MultipleCurrencySupportController, {
  Props,
  configJSON,
} from "./MultipleCurrencySupportController";

export default class MultipleCurrencySupport extends MultipleCurrencySupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Typography variant="subtitle1" component="div">
                {configJSON.labelBodyText}
              </Typography>
              <Box sx={webStyle.inputStyle}>
                <InputLabel id="service-shop-name" data-test-id="txtInputValue">
                  {Number(this.state.txtInputValue)}
                </InputLabel>
                <InputLabel data-test-id="txtFromCurrency">
                  {this.state.fromCurrency}
                </InputLabel>
                <InputLabel>{configJSON.swapSign}</InputLabel>
                <InputLabel data-test-id="txtResult">
                  {this.state.currencyRatio * Number(this.state.txtInputValue)}
                </InputLabel>
                <InputLabel data-test-id="txtToCurrency">
                  {this.state.toCurrency}
                </InputLabel>
                <Input
                  data-test-id={"txtInput"}
                  type={"text"}
                  placeholder={configJSON.txtInputPlaceholder}
                  fullWidth={true}
                  disableUnderline={true}
                  value={this.state.txtInputValue}
                  onChange={(event) => this.setInputValue(event.target.value)}
                />
              </Box>
              <InputLabel>{configJSON.from}</InputLabel>
              <Box sx={webStyle.pickerStyle}>
                <Select
                  data-test-id="from-select"
                  options={this.state.currencyData}
                  onChange={this.handleWebFromDropdownChange}
                />
              </Box>

              <InputLabel>{configJSON.to}</InputLabel>
              <Box sx={webStyle.pickerStyle}>
                <Select
                  data-test-id="to-select"
                  options={this.state.currencyData}
                  onChange={this.handleWebToDrowdownChange}
                />
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  pickerStyle: {
    width: 120,
  },
};
// Customizable Area End
