import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogContent,
  Container,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Popover,
  Alert
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AdvancedSearchModalController, {
  Props,
  configJSON,
} from "./AdvancedSearchModalController.web";
import Loader from "../../../components/src/Loader.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { BgImageGreen, CheckboxIcon, ClockIcon, CloseIcon, ExpandIcon, FilledCheckboxIcon, logo, MapIcon, NoSearchImg, RoundClose, SearchIcon, SearchIcon2, SearchIcon3, SearchIcon4, ThreedotIcon } from "./assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Filters, FiltersList, FilterValue, Tip } from "../../../blocks/utilities/src/typeInterface";
import { generateFilterTag, hasValues } from "../../../blocks/utilities/src/CommonFunctions";
// Customizable Area End


export default class AdvancedSearchModal extends AdvancedSearchModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading, searchInput, searchValue, tipsList, materialSearch, searchFilterObj, showEmptySearchUi, filterList, showData, searchFilterResult, openSearchAlert, anchorEl, showAllFilters, suggestionList, recentSearchList, popularSearchList } = this.state;
    const { openModal, onHandleCloseModal } = this.props;

    const sliceCount = showAllFilters ? filterList.length : 4;

    const filterTagList = () => {
      return (
        <Box className={"filterTagContainer"}>
          <Typography className="filterByTxt">Filter by </Typography>

          <Box className={"filterTagInner"}>
            {
              searchFilterObj?.filters && Object.entries(searchFilterObj?.filters).map(([key, value]) => {
                if (Array.isArray(value)) {
                  return value.map((id) => {
                    const label = this.getLabelValue(key, id);
                    if (label) {
                      return (
                        <FilterTag
                          key={`${key}-${id}`}
                          data-test-id="filterTag"
                          label={
                            <Typography className="tagTxt">
                              {generateFilterTag(key)} :{" "}
                              <span className="tagTxt" style={{ color: "#292524" }}>{label}</span>
                            </Typography>
                          }
                          color="primary"
                          onDelete={this.handleDeleteFilterTag.bind(this, key, id)}
                          deleteIcon={<img src={RoundClose} />}
                        />
                      );
                    }
                  });
                } else {
                  const label = this.getLabelValue(key, value);
                  if (label) {
                    return (
                      <FilterTag
                        key={`${key}-${value}`}
                        data-test-id="filterTag"
                        label={
                          <Typography>
                            {generateFilterTag(key)} :{" "}
                            <span style={{ color: "#292524" }}>{label}</span>
                          </Typography>
                        }
                        color="primary"
                        onDelete={this.handleDeleteFilterTag.bind(this, key, value)}
                        deleteIcon={<img src={RoundClose} />}
                      />
                    );
                  }
                }
              })
            }
          </Box>
        </Box>
      )
    };

    const noSearchFound = () => {
      return (
        <NoSearchFound>
          <Box className="innerBox">
            <img src={NoSearchImg} />
            <Typography className="noSearchFoundText">{configJSON.noSearchFoundTxt}</Typography>
          </Box>
        </NoSearchFound>
      )
    };

    const defaultFilters = () => {
      return (
        <Box className={`${showAllFilters && "moreFilterInner"} filterInner`}>

          {showAllFilters && <Box className="clearFiltersOuter">
            <Typography className="filterResult">{configJSON.filterResultsTxt}</Typography>
            <Typography
              className="filterResult clearFilters"
              onClick={this.onClearAllFilter}
              data-test-id="clearFilters"
            >
              {configJSON.clearFilters}
            </Typography>

          </Box>}
          {filterList.slice(0, sliceCount).map((filterItem: FiltersList) => (
            <Box className={`${showAllFilters && "moreFilterAccordianOuter"} accordianOuter`} key={filterItem.key}>
              <CustomAccordion>
                <CustomAccordionSummary
                  expandIcon={<img src={ExpandIcon} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  data-test-id={filterItem.key}
                >
                  {filterItem.title}
                </CustomAccordionSummary>

                {filterItem.type === "radio" ? (
                  <CustomAccordionDetails className="radioFilterOuter">
                    <RadioGroup
                      data-test-id={"radioBtn"}
                      aria-labelledby={filterItem.key}
                      name={filterItem.key}
                      className="listInner"
                      onChange={this.handleRadioFilterChange}
                      value={searchFilterObj.filters?.[filterItem.key as keyof Filters] ?? ""}
                    >
                      {filterItem.values.map((dataItem: FilterValue) => (
                        <div className="count_container" key={dataItem.id}>
                          {radioItem(dataItem)}
                          <Typography className="count_text">{dataItem.catalogues_count}</Typography>
                        </div>
                      ))}

                    </RadioGroup>
                  </CustomAccordionDetails>
                ) : (
                  <CustomAccordionDetails className="radioFilterOuter">
                    {filterItem.key === "material_types" && <TextField
                      className="materialTypeSearch"
                      data-test-id="searchMaterial"
                      placeholder={"Search"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={SearchIcon4} />
                          </InputAdornment>
                        ),
                        sx: {
                          '&:before': {
                            border: '1px solid #8AAA91 !important',
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            border: '1px solid #8AAA91 !important',
                          },
                          '&:after': {
                            border: '1px solid #8AAA91 !important',
                          },
                        },
                      }}
                      variant="outlined"
                      value={materialSearch}
                      onChange={this.onMaterialSearch}
                    />}

                    <Box
                      className="listInner checklistOuter"
                    >
                      {this.filterMaterialList(filterItem).map((dataItem: FilterValue) => (
                        <div className="count_container checkBoxContainer" key={dataItem.id}>
                          {checklistItem(filterItem.key, dataItem)}
                          <Typography className="count_text">{dataItem.catalogues_count}</Typography>
                        </div>
                      ))}
                    </Box>
                    <Typography style={{ display: "none" }} className="moreFilterLink">More</Typography>
                  </CustomAccordionDetails>
                )
                }
              </CustomAccordion>
            </Box>
          ))}

          {(!showAllFilters && filterList.length > 4) && <Typography
            data-test-id="more_filters_link"
            onClick={this.handleShowAllFilters}
            className="moreFilterLink"
          >
            More filters
          </Typography>}

        </Box>
      )
    };

    const radioItem = (item: FilterValue) => {
      return (
        <FormControlLabel
          key={item.id}
          value={item.id}
          label={item.value}
          control={
            <Radio
              disableRipple
              color="default"
              checkedIcon={<CheckedRadioIcon />}
              icon={<RadioIcon />}
            />
          }
        />
      )
    };

    const checklistItem = (key: string, checkItem: FilterValue) => {
      return (
        <FormControlLabel
          key={checkItem.id}
          value={checkItem.id}
          label={checkItem.value}
          data-test-id={"checkBox"}
          onChange={() => this.handleCheckboxFilterChange(key, checkItem.id)}
          // checked={
          //   Array.isArray(searchFilterObj.filters?.[key]) &&
          //   (searchFilterObj.filters?.[key] as (string | number)[]).includes(checkItem.id)
          // }
          checked={!!searchFilterObj.filters?.[key as keyof Filters]?.includes(checkItem.id)}
          control={
            <Checkbox
              disableRipple
              color="default"
              checkedIcon={<img src={FilledCheckboxIcon} />}
              icon={<img src={CheckboxIcon} />}
            />
          }
        />
      )
    };

    return (
      <>
        <Loader loading={loading} />
        <DialogContainer
          fullScreen
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <SearchHeader>
            <Box className={"logoContainer"}>
              <img src={logo} className="logo_image" />
              <Box className={"closeIconMobile"}>
                <img
                  id="close-icon"
                  onClick={onHandleCloseModal}
                  alt="close-icon"
                  src={CloseIcon}
                />
              </Box>
            </Box>
            <Box className="centerContainer">
              <div className="threedotInner">
                <span><img src={ThreedotIcon} /></span>
              </div>
              <div className="searchContainer">
                <span className="searchIcon"><img src={SearchIcon} /></span>
                <TextField
                  ref={this.anchorRef}
                  aria-owns={openSearchAlert ? 'search-alert' : undefined}
                  className="searchInput"
                  data-test-id="searchInput"
                  id="input-with-icon-textfield"
                  placeholder={searchValue ? "" : "Search by Material type, location, end-use"}
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyPress={this.handleKeyPress}
                  onFocus={this.handleTextFieldFocus}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ display: searchValue ? "flex" : "none" }} position="start">
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <CustomChip
                            data-test-id="searchBadge"
                            label={
                              <abbr title={searchValue}>{searchValue}</abbr>
                            }
                            // label={searchValue}
                            onDelete={this.handleDeleteChip}
                            color="primary"
                            deleteIcon={<img src={RoundClose} />}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={MapIcon} style={{ cursor: "pointer" }} />
                      </InputAdornment>
                    ),
                    sx: {
                      '&:before': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                      '&:hover:not(.Mui-disabled):before': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                      '&:after': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                    },
                  }}
                  variant="standard"
                />
                <Popover
                  id="search-alert"
                  anchorEl={anchorEl || this.anchorRef.current}
                  open={openSearchAlert}
                  onClose={this.handleCloseAlert}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      marginTop: "22px",
                      width: anchorEl ? (anchorEl.clientWidth) : 'auto',
                      minWidth: '368px',
                      '@media (max-width: 1023px)': {
                        width: "auto",
                        maxWidth: "90%",
                        minWidth: "auto",
                        left: "50% !important",
                        transform: "translateX(-50%) !important",
                      },
                    }
                  }}
                >
                  <CustomAlert data-test-id="search_alert" className="searchAlert" severity="error">Please enter your material keywords before acting your search</CustomAlert>
                </Popover>
              </div>
              <Button
                className="searchBtn"
                data-test-id="search_button"
                onClick={this.onHandleSearch}
              >
                {this.getSearchLabel()}
              </Button>
              <div className="threedotInner">
                <span><img src={ThreedotIcon} /></span>
              </div>
            </Box>
            <div className="rightIconContainer">
              <Box className={"closeIcon"}>
                <img
                  id="close-icon"
                  onClick={onHandleCloseModal}
                  alt="close-icon"
                  src={CloseIcon}
                />
              </Box>
            </div>

          </SearchHeader>

          <>
            <DialogContent dividers>
              <MainContainer>
                <Box className="recentSearchContainer">
                  <div data-test-id="tipsContainer" className="tipsContainer">
                    <Carousel
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      useKeyboardArrows={false}
                      autoPlay={false}
                      autoFocus={false}
                      emulateTouch={true}
                      swipeable={true}
                    >
                      {tipsList?.map((tipItem: Tip) => (
                        <Box className="tipItem" key={tipItem.title}>
                          <Typography className="title1">{tipItem.title}</Typography>
                          <Typography className="title2">{tipItem.sub_title}</Typography>
                          <Typography className="detailsPara">{tipItem.description}</Typography>
                        </Box>
                      ))}
                    </Carousel>
                  </div>
                  <div className="bindRecentSearch">
                    <div className="recentSearchesInner">
                      <Box className="titleAndClearOuter">
                        <Typography className="title">{configJSON.recentSearchsTitle}</Typography>
                        <Typography data-test-id="clear_search_btn" onClick={this.onClearRecentSearch} className="clearAll">{configJSON.clearAll}</Typography>
                      </Box>
                      <Box className="wrap_search">
                        {recentSearchList.length > 0 ? recentSearchList.map((searchItem: string) => (
                          <Box className="searchItemContainer" key={searchItem} onClick={this.setSearchValue.bind(null, searchItem)}>
                            <img src={SearchIcon2} />
                            <Typography data-test-id="recent_search_item" className="searchItem">{searchItem}</Typography>
                          </Box>
                        )) :
                          <Typography data-test-id="no_recent_search" className="searchItem">{configJSON.noRecentSearchTxt}</Typography>
                        }
                      </Box>
                    </div>

                    <div className="recentSearchesInner">
                      <Typography className="title">{configJSON.popularSearchsTitle}</Typography>
                      <Box className="wrap_search">
                        {popularSearchList.map((searchItem: string) => (
                          <Box className="searchItemContainer" key={searchItem} onClick={this.setSearchValue.bind(null, searchItem)}>
                            <img src={SearchIcon2} />
                            <Typography data-test-id="popular_search_item" className="searchItem">{searchItem}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </div>
                  </div>

                </Box>
                <Box className="filterContainer">
                  {hasValues(searchFilterObj.filters) && filterTagList()}
                  {(showData && suggestionList.count > 0 && searchInput) && <Box className="slContainer">
                    {suggestionList.current_suggestions.map((searchItem: string) => (
                      <Box className="slItem" onClick={this.setSearchValue.bind(null, searchItem)}>
                        <img src={SearchIcon3} />
                        <Typography className="searchText">{searchItem}</Typography>
                      </Box>
                    ))}

                    {suggestionList.previous_searches.map((searchItem: string) => (
                      <Box className="slItem" onClick={this.setSearchValue.bind(null, searchItem)}>
                        <img src={ClockIcon} />
                        <Typography className="searchText">{searchItem}</Typography>
                      </Box>
                    ))}

                  </Box>}
                  {showEmptySearchUi ? noSearchFound() : defaultFilters()}

                </Box>
              </MainContainer>
            </DialogContent>
          </>
        </DialogContainer>
      </>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const custom_scrollbar = {
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "56px"
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#BA902F",
    borderRadius: "12px",
    minHeight: "81px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#BA902F",
  },

  scrollbarWidth: "8px",
}
const DialogContainer = styled(Dialog)({
  "& .logoContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 1023px)': {
      width: "100%",
      marginBottom: "58px",
    },
    '@media (max-width: 600px)': {
      marginBottom: "66px",
    },
    "& .closeIconMobile": {
      height: "30px",
      width: "30px",
      '@media (min-width: 1024px)': {
        display: "none"
      },
      "& img": {
        cursor: "pointer",
        height: "30px",
        width: "30px",
      },
    },
  },
  "& .MuiDialog-paperWidthSm": {
    boxShadow: "none",
    padding: "0px",
    margin: "0px",
    "& .MuiDialogContent-root,& .MuiDialogActions-root, & .MuiDialogTitle-root":
    {
      padding: "0",
      justifyContent: "center",
    },
    "& .MuiDialogContent-root": {
      borderColor: "#2D6A4D",
      backgroundImage: `url(${BgImageGreen})`,
      backgroundSize: "cover",
      backgroundColor: "#162B26",
      "@media (max-width: 480px)": {
        padding: "40px 12px 0 12px",
      },
    },
    "& .MuiDialogActions-root": {
      padding: 0,
      justifyContent: "end",
    },
  },
});
const MainContainer = styled(Box)({
  height: "100%",
  display: "flex",
  "@media (max-width: 1023px)": {
    flexDirection: "column-reverse",
    padding: "32px",
    height: "100%",
    boxSizing: "border-box",
    overflow: "auto",
    paddingTop: "20px",
  },
  "@media (max-width: 600px)": {
    padding: "20",
  },
  "& .recentSearchContainer": {
    ...custom_scrollbar,
    width: "278px",
    borderRight: "1px solid #2D6A4D",
    padding: "31px 24px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "39px",
    overflow: "auto",

    "@media (max-width: 1023px)": {
      width: "100%",
      height: "auto",
      border: "1px solid #2D6A4D",
      overflow: "unset",
      marginTop: "40px",
    },

    "& .tipsContainer": {
      padding: "26px",
      boxSizing: "border-box",
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(71.92deg, rgba(216, 196, 135, 0) 44.6%, #D8C487 100%)",
      background: "linear-gradient(179.92deg, rgba(15, 23, 42, 0) 0.07%, rgba(0, 0, 0, 0.8) 146.35%)",
      "& .title1": {
        color: "#6B9581",
        fontSize: "10px",
        lineHeight: "20px",
        fontWeight: 390,
        marginBottom: "4px",
      },
      "& .title2": {
        color: "#E7DBB6",
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 390,
        marginBottom: "12px",
      },
      "& .detailsPara": {
        color: "#C8DBC9",
        fontSize: "12px",
        lineHeight: "15.6px",
        fontWeight: 400,
      },

      "& .carousel": {
        display: "flex",
        flexDirection: "column-reverse",
      },
      "& .control-dots": {
        marginTop: "12px",
        position: "unset",
        textAlign: "left",
        display: "flex",
        gap: "6px",
      },
      "& .dot": {
        margin: "0px",
        height: "4px",
        width: "4px",
        background: '#000000',
        "&.selected": {
          background: "#C29E40",
        },
      },
      "& .slide": {
        textAlign: "unset",
      },

    },
    "& .bindRecentSearch": {
      gap: "39px",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 1023px)": {
        display: "flex",
        justifyContent: "space-between",
      },
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    },
    "& .recentSearchesInner": {
      boxSizing: "border-box",
      "@media (max-width: 1023px)": {
        width: "50%",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
      "& .wrap_search": {
        maxHeight: "150px",
        overflow: "auto",
        ...custom_scrollbar,
      },
      "& .searchItem": {
        display: "flex",
        gap: "8",
        color: "#8AAA91",
        fontSize: "14px",
        lineHeight: "19.6px",
        fontWeight: 400,
        wordBreak: "break-word",
      },
      "& .titleAndClearOuter": {
        display: "flex",
        gap: "25px",
      },
      "& .clearAll": {
        color: "#CBAE5B",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 390,
        cursor: "pointer",
      },
      "& .title": {
        color: "#FFFFFF",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 420,
        marginBottom: "39px",
        textTransform: "uppercase",
      },
      "& .searchItemContainer": {
        display: "flex",
        gap: "12px",
        cursor: "pointer",
        marginBottom: "12px",
        "& img": {
          height: "20px",
          width: "20px",
        },
      },

      "& .detailsPara": {
        color: "#C8DBC9",
        fontSize: "12px",
        lineHeight: "15.6px",
        fontWeight: 400,
      },

    },
  },
  "& .filterContainer": {
    ...custom_scrollbar,
    flex: 1,
    width: "100%",
    padding: "47px 15px",
    boxSizing: "border-box",
    "@media (max-width: 1023px)": {
      padding: "0",
    },
    "& .slContainer": {
      ...custom_scrollbar,
      background: "linear-gradient(180deg, rgba(82, 138, 111, 0) 0%, rgba(82, 138, 111, 0.5) 100%)",
      borderRadius: "16px",
      marginBottom: "26px",
      marginTop: "40px",
      "@media (max-width: 600px)": {
        marginTop: "24px",
      },
    },
    "& .filterTagContainer": {
      display: "flex",
      gap: "8px",
      flex: 1,
      marginBottom: "28px",
      "@media (max-width: 1023px)": {
        gap: "40px",
        marginBottom: "24px",
      },
      "& .filterTagInner": {
        display: "flex",
        gap: "4px",
        flexWrap: "wrap",
      },
      "& .filterByTxt": {
        color: "#FFFFFF",
        fontSize: "14px",
        lineHeight: "19.6px",
        fontWeight: 420,
        minWidth: "48px",
      },
    },

    "& .slItem": {
      display: "flex",
      height: "38px",
      gap: "8px",
      alignItems: "center",
      padding: "0 10px",
      borderBottom: "1px solid #162A26",
      borderRadius: "8px",
      cursor: "pointer",
      "& img": {
        height: "14px",
        width: "14px",
      },
      "& .searchText": {
        color: "#E0EEDF",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 420,
      },
    },

    "& .filterInner": {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      boxSizing: "border-box",
      paddingLeft: "74.5px",
      "&.moreFilterInner": {
        paddingLeft: "18.5",
        paddingRight: "18.5",
        flexDirection: "column",
        gap: "8px !important",
        width: "562px",
        background: "linear-gradient(180deg, rgba(82, 138, 111, 0) 0%, rgba(82, 138, 111, 0.5) 100%)",
        '@media (max-width: 1024px)': {
          width: "100%",
          padding: 0
        },
      },
      '@media (max-width: 1024px)': {
        gap: "40px",
        paddingLeft: "0px",
      },
      '@media (max-width: 600px)': {
        gap: "8px",
      },
      "& .moreFilterLink": {
        paddingLeft: "16px",
        color: "#CBAE5B",
        fontSize: "14px",
        lineHeight: "19.6px",
        fontWeight: 420,
        cursor: "pointer",
        alignContent: "center",
        height: "38px",
      },
    },

    "& .accordianOuter": {
      width: "250px",
      "&.moreFilterAccordianOuter": {
        width: "100%",
        '@media (max-width: 1024px)': {
          width: "100%",
        },
      },
      '@media (max-width: 1024px)': {
        width: "calc(50% - 28px)",
      },
      '@media (max-width: 600px)': {
        width: "100%",
      },
    },
    "& .clearFiltersOuter": {
      display: "flex",
      height: "38px",
      gap: "8px",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& .filterResult": {
        color: "#CBAE5B",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 390,
      },
      "& .clearFilters": {
        cursor: "pointer",
      },
    },
  },
});
const SearchHeader = styled(Container)({
  padding: "16px 24px 16px 69px !important",
  boxSizing: "border-box",
  maxWidth: "100% !important",
  width: "100% !important",
  minHeight: "139px",
  backgroundColor: "#162B26",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  '@media (max-width: 1023px)': {
    flexDirection: "column",
    padding: "12px 12px 30px 32px !important",
    minHeight: "auto",
  },
  '@media (max-width: 600px)': {
    padding: "7px 6px 15px 20px !important",
  },
  "& .logo_image": {
    height: "16px",
    '@media (max-width: 1200px)': {
      height: "13px",
    },
    '@media (max-width: 900px)': {
      height: "9px",
      width: "137px",
    },
    '@media (max-width: 600px)': {
      height: "6.72px",
      width: "103px",
    },
  },

  "& .centerContainer": {
    height: "100%",
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 1023px)': {
      width: "100%",
      flexDirection: "column",
      gap: "24px",
      paddingRight: "20px",
      boxSizing: "border-box",
    },
    '@media (max-width: 600px)': {
      paddingRight: "14px",
    },
    "& .searchBtn": {
      width: "120px",
      minWidth: "120px",
      height: "32px",
      color: colors().white,
      backgroundColor: "#BA902F",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 420,
      borderRadius: "26px",
      border: "1px solid #C29E40",
      boxSizing: "border-box",
      marginRight: "51px",
      "&.Mui-disabled": {
        backgroundColor: "#F1F5F9",
        color: "#64748B",
      },
      '@media (max-width: 1400px)': {
        marginRight: "40px",
      },
      '@media (max-width: 1300px)': {
        marginRight: "30px",
      },
      '@media (max-width: 1250px)': {
        marginRight: "15px",
      },
      '@media (max-width: 1023px)': {
        width: "100%",
        margin: 0
      },
    },
  },
  "& .threedotInner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    '@media (max-width: 1023px)': {
      display: "none",
    },
  },

  "& .searchContainer": {
    height: "100%",
    width: "100%",
    maxWidth: "535px",
    padding: "0px 11px 0px 25px",
    display: "flex",
    alignItems: "center",
    gap: "11px",
    paddingLeft: "40px",
    '@media (max-width: 1400px)': {
      padding: "0px 11px 0px 20px",
    },
    '@media (max-width: 1340px)': {
      maxWidth: "500px",
    },
    '@media (max-width: 1300px)': {
      padding: "0px 11px 0px 15px",
    },
    '@media (max-width: 1250px)': {
      padding: "0px 11px 0px 0px",
      maxWidth: "470px",
    },
    '@media (max-width: 1023px)': {
      width: "100%",
      maxWidth: "100%",
      padding: "0px",
    },
  },
  "& .searchIcon": {
    height: "32.63px",
    width: "32.63px",
    marginRight: "9px",
  },
  "& .searchInput": {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "0",
      borderColor: "#E2E8F0",
      paddingRight: "16px",
      '@media (max-width: 1023px)': {
        paddingRight: "0px",
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        fontWeight: "420",
        lineHeight: "19.2px",
        color: "#FFFFFF",
        padding: "8px 0px",
        paddingTop: "0px",
        textTransform: "uppercase",
      }
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 0.5,
      fontSize: "16px",
      fontWeight: "420",
      lineHeight: "19.2px",
      wordBreak: "break-all",
      overflow: "hidden" as const,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      textTransform: "uppercase",
      '@media (max-width: 600px)': {
        fontSize: "12px",
        lineHeight: "14.4px",
      },
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fef3c7',
      '& fieldset': {
        borderColor: 'blue',
        border: "none",
      },
    },
  },
  "& .rightIconContainer": {
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
    alignItems: "start",
    padding: "16px 24px 0 0",

    '@media (max-width: 1023px)': {
      display: "none",
    },
  },
  "& .closeIcon": {
    cursor: "pointer",
    height: "30px",
    width: "30px",
    "& img": {
      cursor: "pointer",
      height: "30px",
      width: "30px",
    },
  },
}
);

const CustomChip = styled(Chip)({
  backgroundColor: '#CBAE5B',
  color: '#292524',
  fontWeight: 420,
  fontSize: '16px',
  lineHeight: "19.2px",
  borderRadius: '6px',
  height: '27px',
  width: '164px',
  padding: '4px 0px 4px 8px',
  marginBottom: '9px',
  justifyContent: 'space-between',
  ' span': {
    padding: "0px",
    paddingRight: '9px',
  },
  ' abbr': {
    textDecoration: "none",
  },

  '.MuiChip-deleteIcon': {
    color: '#292524',
    fontSize: '12.75px',
    margin: "0px",
    marginRight: '4px',
    marginLeft: '10px',
  },
  '.MuiChip-label': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
});

const FilterTag = styled(Chip)({
  backgroundColor: '#E0EEDF',
  color: '#045544',
  borderRadius: '25px',
  height: '25px',
  padding: '4px 12px !important',
  justifyContent: 'space-between',
  '.MuiChip-deleteIcon': {
    color: '#292524',
    fontSize: '12.75px',
    margin: '0px',
    height: "16px",
    width: "16px",
  },
  '.MuiChip-label': {
    paddingLeft: "0px",
    paddingRight: "8px",
    '& p': {
    fontWeight: "420 !important",
    fontSize: '12px !important',
    lineHeight: "16.8px !important",
    letterSpacing: "-0.34"
  },
  },
  '& .tagTxt': {
    fontWeight: "420 !important",
    fontSize: '12px !important',
    lineHeight: "16.8px !important",
  },
});

const CustomAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: "1px solid #BA902F",
  color: '#FFFFFF',
  fontWeight: 420,
  fontSize: '16px',
  lineHeight: "22.4px",
  borderRadius: '0px !important',
  minWidth: '250px',
  '&.Mui-expanded': {
    borderBottom: "0",
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
});

const CustomAccordionSummary = styled(AccordionSummary)({
  justifyContent: 'start',
  height: '38px',
  minHeight: '38px',
  "&.Mui-expanded": {
    minHeight: "auto",
  },
  "& .MuiAccordionSummary-content	": {
    flexGrow: 0,
    margin: 0,
    marginRight: "4px !important",
    textTransform: "uppercase",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    "& img": {
      height: "20px",
      width: "20px",
    }
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    "& img": {
      filter: "brightness(0) saturate(100%) invert(77%) sepia(92%) saturate(306%) hue-rotate(341deg) brightness(84%) contrast(87%)"
    }
  },
  '& .MuiAccordionSummary-content': {
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  borderTop: "1px solid #BA902F",
  padding: "28px 12px",
  "& .MuiFormControlLabel-root": {
    marginRight: "0px",
    marginLeft: "-5px",
  },
  "&.radioFilterOuter": {
  },
  "& .listInner": {
    width: "100%",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
  },
  "& .checklistOuter": {
    overflow: "auto",
    width: "100%",
    maxHeight: "215px",
    flexWrap: "nowrap",
    paddingBottom: "1px",
    paddingRight: "10px",
    ...custom_scrollbar,
  },
  "& .count_container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "20px",
    gap: "15px",
    "&.checkBoxContainer": {
      minHeight: "36px",
      height: "auto",
      borderBottom: "1px solid #045544",
      boxSizing: "border-box",
      "&:nth-last-child(1)": {
        borderBottom: "none !important",
        border: 0,
      },
    },
    "& .MuiTypography-root": {
      color: '#F5F5F4',
      fontWeight: 390,
      fontSize: '14px',
      lineHeight: "19.6px",
    },
    "& .count_text": {
      color: '#D6D3D1',
      fontWeight: 390,
      fontSize: '12px',
      lineHeight: "16.8px",
    },
  },
  "& .materialTypeSearch": {
    width: "100%",
    marginBottom: "11px",
    "& .MuiInputBase-root": {
      borderRadius: "0",
      border: "1px solid #8AAA91",
      paddingRight: "16px",
      '@media (max-width: 1023px)': {
        paddingRight: "0px",
      },
      "& .MuiInputBase-input": {
        fontSize: "14px",
        fontWeight: "390",
        lineHeight: "22px",
        color: "#FFFFFF",
        padding: "0px",
        paddingTop: "0px",
        height: "25px",
        boxSizing: "border-box"
      }
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 0.5,
      fontSize: "14px",
      fontWeight: "390",
      lineHeight: "22px",
      wordBreak: "break-all",
      overflow: "hidden" as const,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      '@media (max-width: 600px)': {
        fontSize: "12px",
        lineHeight: "14.4px",
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: "none",
      },
    },
  },
});

const RadioIcon = styled('span')({
  borderRadius: '50%',
  padding: '0px !important',
  width: 16,
  height: 16,
  border: "1px solid #F5F5F4",
  backgroundColor: 'transparent', '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
});

const CheckedRadioIcon = styled(RadioIcon)({
  backgroundColor: '#B7862B',
  borderColor: '#B7862B',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#162A26,#162A26 28%,transparent 32%)',
    content: '""',
  },
});

const CustomAlert = styled(Alert)({
  minHeight: "48px",
  backgroundColor: "#FEE2E2",
  color: "#0F172A",
  fontSize: "12px",
  lineHeight: "28px",
  fontWeight: 390,
  boxSizing: "border-box",
  alignItems: "center",
  "& .MuiAlert-icon": {
    marginRight: "16px",
  },
  "& .MuiAlert-message": {
    padding: "0px",
  },
  '@media (max-width: 500px)': {
    width: "100%",
  },
});

const NoSearchFound = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .innerBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "447px",
    flexDirection: "column",
    height: "100%",
    "& img": {
      margin: "68.53px 27px",
    },
    "& .noSearchFoundText": {
      color: "#FFFFFF",
      fontSize: "24px",
      lineHeight: "34.32px",
      fontWeight: 420,
      textAlign: "center",
    }
  }
});

// Customizable Area End
