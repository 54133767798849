import React from "react";

// Customizable Area Start
import {
  Box,Typography,Dialog,DialogTitle,
  DialogContent,DialogActions,Grid,TextField,Select,MenuItem,
  FormHelperText,InputAdornment
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { image, mobileImage, tabImage, attachmentIcon} from "./assets";
import ContactusController, { Props } from "./ContactusController";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown } from '@mui/icons-material';
import { TextareaAutosize as BaseTextAreaAutosize } from '@mui/base/TextareaAutosize';
import CardMedia from '@mui/material/CardMedia';
// Customizable Area End
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<>
      <NavigationWrapper navigation={this.props.navigation} id={""} >
      <MainDiv style={this.state.authToken ? {paddingLeft:"3%"}: {paddingLeft:"15%"}}>
        <MainLabel>
          <StyledLabel> CONTACT US</StyledLabel> 
        </MainLabel>
        <SubDiv>
        <StyledLabel2>
        We’re here to help! Whether you have questions, need assistance, or want to learn more about our 
        sustainable products, reach out to us through any of the options below. 
        </StyledLabel2>
        </SubDiv>
        <ContentDiv>
        <StyledLabel3>
            How would you like to 
            <span style={{color:"#B7862B"}}>{" "}get in touch</span> with us?
        </StyledLabel3>
        </ContentDiv>
        <DetailContainer>
          <ContentDiv1>
              <ContentDiv2>
                <StyledLabel4>CALL US</StyledLabel4>
                <StyledLabel5>
                Speak to us directly. Click the button below to copy our number to your clipboard or start a call.
                </StyledLabel5>
                <StyledButton><a href="tel:+44 (0) 20 8064 0327" style={{color: "#FAFAF9",textDecoration:"none"}}>Call Us</a></StyledButton>
              </ContentDiv2>
              <ContentDiv2>
                <StyledLabel4>CONTACT FORM</StyledLabel4>
                <StyledLabel5>
                Fill out our contact form and we'll get back to you shortly. Click the button below to start.
                </StyledLabel5>
                <StyledButton onClick={()=>this.openContactPopup()} data-test-id="contactUsBtn">Contact Us</StyledButton>
              </ContentDiv2>
               <ContentDiv2>
                <StyledLabel4>EMAIL US</StyledLabel4>
                <StyledLabel5>
                  Reach out to us via email. Click the button below to open your email client with our address pre-filled.
                </StyledLabel5>
                <StyledButton><a style={{color: "#FAFAF9",textDecoration:"none"}} href="mailto:admin@procurecircular.com">Email</a></StyledButton>
              </ContentDiv2>
              <ContentDiv2>
                <StyledLabel4>LIVE CHAT</StyledLabel4>
                <StyledLabel5>
                Chat with our support team for immediate assistance. Click the button below to start a live chat.
                </StyledLabel5>
                <StyledButton>Start Chat</StyledButton>
              </ContentDiv2>
              <ContentDiv2>
                <StyledLabel4>FAQ</StyledLabel4>
                <StyledLabel5>
                  Have questions? Our FAQ section is here to help!
                </StyledLabel5>
                <StyledButton1>Explore FAQ's</StyledButton1>
              </ContentDiv2>

              <ContentDiv3>
                <StyledLabel4>ADDRESS</StyledLabel4>
                  <Typography style={{marginTop:"20px",color:"#1C1917"}}>Sustainable Ventures, County Hall,</Typography>
                  <Typography style={{color:"#1C1917"}}>Belvedere Road, London. SE1 7PB</Typography>
                  <Typography style={{marginTop:"20px",color:"#1C1917"}}>Worldwide Toll-Free:</Typography>
                  <Typography style={{color:'#407B5F'}}>+44 (0) 20 80 64 0327</Typography>
                  <Typography style={{color:"#1C1917"}}>Mon-Sat 9:00AM to 5:30PM (BST)</Typography>
              </ContentDiv3>
          </ContentDiv1>
          <MapDiv></MapDiv> 
        </DetailContainer>
      </MainDiv>
      </NavigationWrapper>
      <Dialog
        open={this.state.isVisible}
        className="automated-prioritization"
        fullWidth={true}
        transitionDuration={0}
        aria-describedby="scroll-dialog-description"
        data-test-id="dialog"
        aria-labelledby="scroll-dialog-title"
        maxWidth={"md"}
        scroll="body"
      >
        <Box style={{border:"4px solid #C2A657"}}>
          <DialogTitle>
          <Box style={{
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <Typography style={{
            fontSize: "24px",
            fontWeight: 420,
            lineHeight: "32px",
            textAlign: "left",
            color:"#000000"
          }}>Contact form</Typography>
          <IconButton
          aria-label="close"
          data-test-id="closeIcon"
          onClick={()=> this.closeContactPopup()}
          sx={(theme) => ({
            color: "#334155",
          })}
          >
          <CloseIcon />
          </IconButton>
          </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Typography style={{
                fontSize: "18px",
                fontWeight: 450,
                lineHeight: "26px",
                textAlign: "left",
                color:"#162A26",
                textTransform:"uppercase"
              }}>How can we assist you today?</Typography>
              <Typography style={{
                fontSize: "14px",
                fontWeight: 390,
                lineHeight: "22px",
                textAlign: "left",
                color:"#162A26",
                marginTop: "10px"
              }}>Have questions or need help? Fill out the form below with your details and a brief message.</Typography>
              <Typography style={{
                fontSize: "14px",
                fontWeight: 390,
                lineHeight: "22px",
                textAlign: "left",
                color:"#162A26"
              }}>Our team will get back to you shortly.</Typography>
            </Box>
            <Box
              style={{
                padding: "28px 32px 32px 32px",
                gap: "8px",
                border: "1px solid #D6D3D1",
                marginTop: "26px"
              }}
            >
              <form data-test-id="registrationForm" style={{width:"100%"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Name</StyledTextLabel>
                      <StyledTextField
                          fullWidth
                          data-test-id="firstNameInput"
                          placeholder="Write your name"
                          name="name"
                          id="standard-error-helper-text"
                          value={this.state.name}
                          onChange={(event)=>this.handleChange('name' ,event.target.value)}
                          helperText={this.state.nameError}
                          error={!!this.state.nameError}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                              '&:hover fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                              '&.Mui-focused fieldset': {
                                border: '1px solid #292524',
                              },
                            },
                          }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Surname</StyledTextLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="surnameInp"
                        placeholder="Write your surname"
                        name="surname"
                        value={this.state.surname}
                        onChange={(event)=>this.handleChange('surname' ,event.target.value)}
                        helperText={this.state.surnameError}
                        error={!!this.state.surnameError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                            '&:hover fieldset': {
                              border: '1px solid #E2E8F0',
                            },
                            '&.Mui-focused fieldset': {
                              border: '1px solid #292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Email address</StyledTextLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="emailInp"
                        placeholder="name@companyname.com"
                        name="email"
                        value={this.state.email}
                        onChange={(event)=>this.handleChange('email' ,event.target.value)}
                        helperText={this.state.emailError}
                        error={!!this.state.emailError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                            '&:hover fieldset': {
                              border: '1px solid #E2E8F0',
                            },
                            '&.Mui-focused fieldset': {
                              border: '1px solid #292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Phone no.(optional)</StyledTextLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="mobileInp"
                        placeholder="1 000 0000 000"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={(event)=>this.handleChange('phoneNumber' ,event.target.value)}
                        helperText={this.state.phoneError}
                        error={!!this.state.phoneError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                            '&:hover fieldset': {
                              border: '1px solid #E2E8F0',
                            },
                            '&.Mui-focused fieldset': {
                              border: '1px solid #292524',
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                            >
                              <span style={{color: '#292524'}}>+</span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Reason for contact</StyledTextLabel>
                      <CustomSelect
                          fullWidth
                          data-test-id="reasonInp"
                          IconComponent={KeyboardArrowDown}
                          name="country"
                          value={this.state.contactReason}
                          onChange={(event)=>this.handleSelectChange(event)}
                          displayEmpty
                          error={!!this.state.contactReasonError}
                          style={this.state.contactReasonError ==='' ? {
                          border:"1 solid",
                          color:'#292524'
                          }: {color:'#292524'}}
                          sx={{
                            color:"#292524",
                            backgroundColor: "#F8FAFC",
                            width:"100%",
                            '.MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #292524',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: '1px solid #E2E8F0',
                            },
                            '.MuiSvgIcon-root ': {
                              color:"#94A3B8",
                            },
                          }}
                        >
                          <StyledMenuItem value="">Select</StyledMenuItem>
                          <StyledMenuItem key="Buy fabrics" value="Buy fabrics">Buy fabrics</StyledMenuItem>
                          <StyledMenuItem key="Sell fabrics" value="Sell fabrics">Sell fabrics</StyledMenuItem>
                          <StyledMenuItem key="Product related" value="Product related">Product related</StyledMenuItem>
                          <StyledMenuItem key="Order related" value="Order related">Order related</StyledMenuItem>
                          <StyledMenuItem key="Payment related" value="Payment related">Payment related</StyledMenuItem>
                          <StyledMenuItem key="Technical Support" value="Technical Support">Technical Support</StyledMenuItem>
                          <StyledMenuItem key="Other" value="Other">Other</StyledMenuItem>
                        </CustomSelect>
                        <FormHelperText error={!!this.state.contactReasonError}>
                          {this.state.contactReasonError}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledTextLabel>Message</StyledTextLabel>
                       <Textarea
                        data-test-id="commentsInp"
                        name="comments"
                        aria-label="minimum height"
                        minRows={9}
                        placeholder="Write your message"
                        value={this.state.comments}
                        onChange={(event)=>this.handleChange('comments' ,event.target.value)}
                        sx={{
                          width: '100%',
                          maxWidth:"100%",
                          color:"#292524",
                          backgroundColor:"#F8FAFC",
                          border: this.state.commentsError ? '1px solid red' :'1px solid #E2E8F0',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: '1px solid #E2E8F0',
                              },
                            '&:hover': {
                              border: '1px solid #E2E8F0',
                            },
                            '&:focus': {
                              border: '1px solid #292524',
                            },
                            '&:focus-visible': {
                              border: '1px solid #292524',
                            },
                          },
                        }}
                      />
                      <FormHelperText error={!!this.state.commentsError}>
                          {this.state.commentsError}
                        </FormHelperText> 
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{display:"flex"}}>
                        <label htmlFor="file-input" style={{cursor:"pointer"}}>
                         <img src={ attachmentIcon }/>
                        </label>
                        <input id="file-input" data-test-id="fileInp" type="file" style={{display:"none"}} onChange={(e)=>this.handleFiles(e)}/>
                        <Typography style={{
                          color: "#000",
                          marginTop: "6px",
                          marginLeft: "17px",
                        }}>{this.state.files?.name}</Typography>
                      </div>
                    </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContent>
          <DialogActions style={{padding:"24px"}}>
                <CancelButton onClick={()=> this.closeContactPopup()} data-test-id="cancelBtn">Cancel</CancelButton>
                <SendButton onClick={()=> this.handleSendMessage()} data-test-id="sendQuery">Send Message</SendButton>
          </DialogActions>
        </Box>
      </Dialog></>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledLabel = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 24,
  fontWeight: "420",
  textAlign: "left",
  color: "#2D6A4D"
});
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: "#64748B",
    fontSize: "14px",
    height: "36px",
    backgroundColor: "#F8FAFC",
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: "red",
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
    fontFamily: "brandon-grotesque, sans-serif",
    marginLeft: "initial",
    color:'#DC2626',
  },
  '& .MuiFormHelperText-root.Mui-error':{
    color:'#DC2626',
  },
  '& .MuiInputBase-input': {
    height: '2px',
    color: '#292524',
  },
'& .MuiInputBase-input::placeholder': {
  color: '#64748B', 
},
});
const StyledMenuItem = styled(MenuItem)({
  color:"#000000",
  fontSize:"14px", 
  fontWeight:390, 
  lineHeight:"20px",
  height:"25px",
  minHeight:"25px"
});
const StyledTextLabel = styled(Typography)({
  fontSize: "14px",
  fontWeight: 390,
  textAlign: "left",
  lineHeight: "20px",
  color: "#292524",
  letterSpacing: "0.05em",
  marginTop:"-8px"
});
const Textarea = styled(BaseTextAreaAutosize)(
  ({ theme }) => `
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  width:"100%";
  color: "#292524";
  background: "#F8FAFC";
`,
);
const CustomSelect = styled(Select)({
  height: '36px',
  color: "#64748B",
  fontSize: '14px',
  backgroundColor: '#F8FAFC',
  fontFamily: "brandon-grotesque, sans-serif",
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid transparent',
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: "#DC2626",
  },
});
const StyledLabel2 = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: "14px",
  fontWeight: "390",
  textAlign: "left",
  color: "#162A26",
});
const MapDiv = styled(CardMedia)({
  width: "45%",
  height: "auto",
  gap: "54px",
  opacity: "0px",
  backgroundImage:`url(${image})`,
  backgroundSize:"cover", 
  "@media(max-width:600px)": {
    height: "434px",
    width:'90%',
    backgroundImage:`url(${mobileImage})`,
    marginTop:"-6%"
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    marginTop:"-82px",
    height: "413px",
    width:'100%',
    backgroundImage:`url(${tabImage})`,
  }
});
const MainDiv = styled(Box)({
  backgroundColor: "#fff",
  paddingTop:'2%',
  width:"85%",
  "@media(max-width:600px)": {
    paddingLeft:'6% !important',
    width:"96%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    paddingLeft:'6% !important',
    paddingRight:'6% !important',
    width:"88%",
  }
});
const MainLabel = styled(Box)({
  width: "316px",
  height: "34px",
  gap: "0px",
  opacity: "0px",
 "@media(max-width:600px)": {
    left: "5%",
    height: "34px",
    marginTop: "50px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    top: "70px",
    left: "7%",
    height: "34px",
    marginTop: "50px",
  }
});
const DetailContainer = styled(Box)({
  display:"flex",
  width:"100%",
  marginTop: "50px",
  marginBottom: "120px",
 "@media(max-width:600px)": {
    flexDirection: "column-reverse",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    flexDirection: "column-reverse",
  }
});
const SubDiv = styled(Box)({
  width:"50%",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  marginTop:'30px',
  "@media(max-width:600px)": {
    height: "70px",
    width:"95%",
    marginTop:'20px',
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    height: "34px",
    width:"100%",
    marginTop:'20px',
  }
});
const ContentDiv = styled(Box)({
  width: "50%",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  marginTop:'60px',
  "@media(max-width:600px)": {
    height: "auto",
    width:"83%",
    marginTop:'32px',
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    height: "100px",
    width:"83%",
    marginTop:'32px',
  }
});
const StyledLabel3 = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 24,
  fontWeight: "450",
  textAlign: "left",
  color: "#1C1917",
  letterSpacing: 0.03,
  textTransform:'uppercase'
});

const ContentDiv1 = styled(Box)({
  width: "50%",
  display:"flex",
  gap: "54px",
  opacity: "0px",
  flexFlow: "wrap",
  height: "auto",
  "@media(max-width:600px)": {
    width: "90%",
    height: "auto",
    marginLeft:"10%",
    marginTop:"28px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    height: "auto",
    width:"95%",
    marginTop:"28px",
    marginLeft:"5%"
  }
});
const ContentDiv2 = styled(Box)({
  width: "44%",
  height: "176",
  gap: "20px",
  opacity: "0px",
  "@media(max-width:600px)": {
    width: "86%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"44%",
  }
});
const ContentDiv3 = styled(Box)({
  width: "44%",
  height: "185",
  gap: "20px",
  opacity: "0px",
  "@media(max-width:600px)": {
    width: "86%",
    marginTop:"20px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"44%",
  }
});
const StyledLabel4 = styled(Typography)({
  width: "84%",
  height: "32px",
  fontSize: "20px",
  fontWeight: "450",
  textAlign: "left",
  color: "#045544",
  letterSpacing: 0.03,
});
const StyledLabel5 = styled(Typography)({
  width: "84%",
  height: "72px",
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 16,
  fontWeight: "400",
  textAlign: "left",
  color: "#1C1917",
  gap:'20px',
  marginTop: "20px",
});
const StyledButton = styled(Box)({
  width: "140px",
  height: "32px",
  cursor:"pointer",
  padding: "6px 20px 6px 20px",
  gap: "6px",
  fontWeight: "420",
  borderRadius: "36px",
  backgroundColor: "#1C1917",
  fontSize: "14px",
  color: "#FAFAF9",
  marginTop: "20px",
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  textTransform:"uppercase",
  "@media(min-width:500px) and (max-width:610px)": {
    marginTop: "48px",
  },
  "@media(max-width:600px)": {
    marginTop: "42px",
  },
});
const StyledButton1 = styled(Box)({
  width: "140px",
  height: "32px",
  cursor:"pointer",
  padding: "6px 20px 6px 20px",
  gap: "6px",
  borderRadius: "36px",
  backgroundColor: "#1C1917",
  fontSize: "14px",
  fontWeight: "420",
  color: "#FAFAF9",
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  textTransform:"uppercase",
  "@media(min-width:500px) and (max-width:610px)": {
    marginTop: "48px",
  },
  "@media(max-width:600px)": {
    marginTop: "42px",
  },
});
const CancelButton = styled(Box)({
  fontSize: "16px",
  color: "#B7862B",
  fontWeight: 420,
  lineHeight: "24px",
  marginRight:"20px",
  cursor:"pointer"
});
const SendButton = styled(Box)({
  width:"159px",
  height:"24px",
  fontSize: "16px",
  color: "#B7862B",
  fontWeight: 390,
  lineHeight: "24px",
  textTransform:"uppercase",
  padding: "20px",
  border:"1px solid #B7862B",
  borderRadius: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor:"pointer"
});

// Customizable Area End