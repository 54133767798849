import React from "react";

// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { Box, TextField, Button, Grid, Typography, InputAdornment } from '@mui/material';
import { image, headerImage, mobileImage, logoImage, checkImage} from "../../email-account-login/src/assets";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled } from '@mui/material/styles';

const StyledLabel = styled(Typography)({
  fontFamily:"brandon-grotesque, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  textAlign:"left",
  lineHeight:"20px",
  color:"#1E293B",
  letterSpacing:"0.05em",
});
const StyledHeading = styled("div")({
    font:"Garamond Premier Pro",
    fontSize: "56px",
    fontWeight: 400,
    textAlign:"left",
    lineHeight:"50px",
    color:"white",
    marginTop:"60px",
    fontFamily: 'garamond-premier-pro !important',
    letterSpacing:"0.05em",
    "@media(max-width:500px)": {
      lineHeight:"58px",
      marginTop:"52px",
    },
    "@media(min-width:500px) and (max-width:850px)": {
      lineHeight:"60px"
    },
  });
  const StyledContainer = styled("div")({
    width: "96%",
    height:"80vh",
    marginLeft: '2%',
    marginRight: '2%',
    backgroundImage:`url(${image})`,
    outline: '1px solid #CCB26F',
    outlineOffset: '-21px',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundSize: "cover",
    "@media(max-width:500px)": {
      backgroundImage:`url(${mobileImage})`,
      outline: '1px solid #CCB26F',
      outlineOffset: '-21px',
      width: "100%",
      marginLeft: '0',
      marginRight: '0',
      backgroundSize: "cover",
      height:"auto"
    },
    "@media(min-width:500px) and (max-width:940px)":{
      height:"auto",
    }
  });
  const InnerContainer= styled(Box)({
    padding:"10px", 
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "auto", 
    width:"70%",
    "@media(max-width:500px)": {
      width:"90%",
      flexDirection: "column"
    },
    "@media(min-width:500px) and (max-width:850px)": {
      width:"90%",
    }
  })
  const LeftContainer= styled(Box)({
    padding:"33px",
    flexDirection: 'column',
    alignContent: 'center',
    display: 'flex',
    width: "40%",
    marginTop:"30px",
    height: "auto",
    opacity: "0px",
    "@media(max-width:500px)": {
      width:"90%",
      padding:'0px'
    },
    "@media(min-width:500px) and (max-width:850px)": {
      marginTop:"5px",
    }
  })
  const RightContainer= styled(Box)({
    width: '35%',
    height: '423px',
    backgroundColor: 'white',
    display: 'flex',
    opacity: "0px",
    border: '4px solid #BA9553',
    "@media(max-width:500px)": {
      width:"90%",
      flexDirection: "column",
      marginBottom:"20px",
      justifyContent: "center",
      alignItems:"center",
    },
    "@media(min-width:500px) and (max-width:850px)":{
      marginTop:"20px",
      marginBottom:"20px",
      alignItems:"center",
      justifyContent: "center",
    }
  })
  const SignInContainer= styled(Box)({
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:500px)": {
      width:"90%",
      paddingTop:'32px',
    },
  })

  const StyledHeader = styled(Box)({
    marginTop: '30px',
      marginLeft: '50px',
      display:"flex",
      "@media(max-width:500px)": {
        flexDirection: "row-reverse",
        justifyContent: "space-between"
      },
      "@media(min-width:500px) and (max-width:850px)": {
        flexDirection: "row-reverse",
        justifyContent: "space-between"
      }
  });
  const StyledHeading2 = styled("div")({
    fontSize: "16px",
    fontWeight: 420,
    textAlign:"left",
    lineHeight:"22.88px",
    color:"white",
    marginTop:"15px",
    letterSpacing:"0.05em",
    fontFamily: 'brandon-grotesque, sans-serif',
    "@media(max-width:500px)": {
      marginTop:"0px",
      marginBottom:"30px",
    }
  });
  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      color: "#64748B",
      fontSize: "14px",
      height: "36px",
      backgroundColor: "#F8FAFC",
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: "#DC2626",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: '1px solid transparent',
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      marginLeft: "initial",
      color:'#DC2626',
    },
    '& .MuiFormHelperText-root.Mui-error':{
      color:'#DC2626',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#292524",
      },
    },
    '& .MuiInputBase-input': {
      height: '2px',
      color: '#292524',
    },
  '& .MuiInputBase-input::placeholder': {
    color: '#64748B', 
  },
  });
  const LeftBox1= styled(Box)({
    marginTop: '55px',
    "@media(max-width:500px)": {
      marginTop: '55px',
    },
    "@media(min-width:500px) and (max-width:850px)": {
      marginTop: '5px',
    }
  })
  const StyledMessage = styled(Box)({
    backgroundColor: "#F4FFF3",
    padding: "20px",
    fontSize: "16px",
    fontWeight: "390",
    lineHeight: "24px",
    color: "#528A6F",
    marginTop: "10%",
    marginBottom: "5%",
    display:"flex",
  });
const StyledButton = styled(Button)({
  color:"#B7862B", 
  borderRadius:"36px", 
  width:"100%",
  height:"44px",
  marginTop:"20px", 
  fontSize: '16px',
  fontWeight:390,
  border:"1px solid #B7862B",
  cursor:"pointer",
  "&:hover": {
    backgroundColor: "white",
  },
})
// Customizable Area End
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div data-test-id="testDiv">
      <Box>
        <StyledHeader>
          <img src={headerImage}/>
          <img style={{ height:"15px",marginTop:'20px' }} src={logoImage}/>
        </StyledHeader>
      </Box>
      
       <StyledContainer>
        <InnerContainer>
          <LeftContainer>
              <Typography style={{ fontSize: '32px', color: '#F8D18D', fontWeight: '420' }}>CHANGE YOUR PASSWORD</Typography>
              <Typography style={{ fontSize: '12px', color: 'white', fontWeight: '420', textTransform: "uppercase" }}>
                  I don’t have an account, I want to <span style={{ color: '#F8D18D', textTransform: "uppercase",cursor: "pointer" }} onClick={()=>{this.clickSignUp()}} data-test-id="signInBtn">Sign up</span>
              </Typography>
              <StyledHeading>Welcome Back</StyledHeading>
              <LeftBox1>
                  <StyledHeading2>
                      Log in to access your dedicated portal for sustainable procurement. Start or continue your circular journey today!
                  </StyledHeading2>
                  <StyledHeading2>
                  Need assistance? <a style={{ color: '#F8D18D', cursor: "pointer"}} rel="noopener noreferrer" href="https://procurecircular.com/contact" target="_blank" data-test-id="contactUsBtn">Contact us</a> for help with your account.
                  </StyledHeading2>
              </LeftBox1> 
          </LeftContainer>
          <RightContainer>
                <SignInContainer>
                  {
                    this.state.fType === 'forgotPasword' ? 
                    <form onSubmit={this.handleSubmit} noValidate autoComplete="off" style={{width:"85%"}}>
                    <Typography style={{
                        fontFamily: "brandon-grotesque, sans-serif",
                        fontSize: "16px",
                        fontWeight: "420",
                        lineHeight: "22.88px",
                        textAlign: "left",
                        color: "#475569",
                        marginBottom:"26px"
                      }}>Set new password</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <StyledLabel>Password</StyledLabel>
                                <StyledTextField
                                    type={this.state.enablePasswordField ? 'password' : 'text'}
                                    data-test-id="passInp"
                                    placeholder="*******"
                                    name="email"
                                    id="standard-error-helper-text"
                                    value={this.state.password}
                                    onChange={(event)=>this.handleChange('password' ,event.target.value)}
                                    helperText={this.state.passwordError}
                                    error={!!this.state.passwordError}
                                    sx={{
                                      width: '100%',
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                          <InputAdornment data-testid='showPasswordContainer' data-show={this.state.enablePasswordField} position="end">
                                          {this.state.enablePasswordField ? 
                                              <VisibilityOutlinedIcon 
                                              data-test-id="togglePasswordVisibility"
                                              style={{cursor: "pointer",color:"#94A3B8",height:'16px',width:"16px"}}
                                              data-testid="hidePassword" onClick={this.handleClickShowPassword} 
                                              /> :
                                              <VisibilityOffOutlinedIcon 
                                              data-test-id="togglePasswordVisibility"
                                              style={{cursor: "pointer",color:"#94A3B8", height:'16px',width:"16px"}}
                                              data-testid="showPassword" onClick={this.handleClickShowPassword} />
                                          }
                                          </InputAdornment>
                                      ),
                                      }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <StyledLabel>Repeat Password</StyledLabel>
                                <StyledTextField
                                    type={this.state.btnConfirmPasswordShowHide ? 'password' : 'text'}
                                    data-test-id="confirmPassInp"
                                    placeholder="*******"
                                    name="email"
                                    id="standard-error-helper-text"
                                    value={this.state.confirmPassword}
                                    onChange={(event)=>this.handleChange('confirmPassword' ,event.target.value)}
                                    helperText={this.state.confirmPasswordError}
                                    error={!!this.state.confirmPasswordError}
                                    sx={{
                                      width: '100%',
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#F8FAFC',
                                        },
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                          <InputAdornment data-testid='showPasswordContainer' data-show={this.state.btnConfirmPasswordShowHide} position="end">
                                          {this.state.btnConfirmPasswordShowHide ? 
                                              <VisibilityOutlinedIcon 
                                              data-test-id="togglePasswordVisibility"
                                              style={{cursor: "pointer",color:"#94A3B8",height:'16px',width:"16px"}}
                                              data-testid="hidePassword" onClick={this.handleClickShowCPassword} 
                                              /> :
                                              <VisibilityOffOutlinedIcon 
                                              data-test-id="togglePasswordVisibility"
                                              style={{cursor: "pointer",color:"#94A3B8", height:'16px',width:"16px"}}
                                              data-testid="showPassword" onClick={this.handleClickShowCPassword} />
                                          }
                                          </InputAdornment>
                                      ),
                                      }}
                                />
                            </Grid>
                        </Grid>
                        
                        <StyledButton type="submit" data-test-id='registerButton' onClick={(e)=>this.handleSubmit(e)}>CHANGE PASSWORD</StyledButton>
                    </form>
                    : ""
                  }
                  {
                    this.state.fType === 'showSuccess' ?
                    <div style={{width:"85%",marginBottom:"20%"}}>
                    <Typography style={{
                        fontSize: "16px",
                        fontWeight: "420",
                        lineHeight: "22.88px",
                        textAlign: "left",
                        color: "#616161",
                        marginTop: "19%"
                      }}>PASSWORD CHANGED</Typography>
                        <StyledMessage>
                          <div style={{marginRight:"16px",display:"flex", justifyContent:"center",alignItems:"center"}}><img src={checkImage} height="33px" width="33px"/></div>
                          <div>Your Password was successfully changed. Press log in to enter the platform. </div>
                      </StyledMessage>
                     
                      <StyledButton type="submit" data-test-id='loginButton3' onClick={()=>{this.clickReset()}}>LOG IN</StyledButton>
                      </div>: ""
                  }
              </SignInContainer>
          </RightContainer>
        </InnerContainer>
      </StyledContainer> 
      </div>
      );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End