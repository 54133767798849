import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff } from "./assets";
import Settings from "../../../components/src/Settings";
import { styled } from '@mui/system';
import {NavigationSidebarDataFirst, NavigationSidebarDataSecond, NavigationSidebarDataThird} from '../../../components/src/NavigationSidebarData';
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  hdSlogan1 = require('../../../components/src/icons-nav/header-slogan-1.svg');
  hdSlogan2 = require('../../../components/src/icons-nav/header-slogan-2.svg');
  hdSlogan3 = require('../../../components/src/icons-nav/header-slogan-3.svg');
  hdThreedot = require('../../../components/src/icons-nav/header-threedot.svg');
  hdSearch = require('../../../components/src/icons-nav/header-search-icon.svg');
  hdAi = require('../../../components/src/icons-nav/header-ai.svg');
  hdVoiceRc = require('../../../components/src/icons-nav/header-voice-record.svg');

  hdChat = require('../../../components/src/icons-nav/header-chat.svg');
  hdNotify = require('../../../components/src/icons-nav/header-notify.svg');
  hdShoping = require('../../../components/src/icons-nav/header-shoping.svg');

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <div style={{margin: '0', padding: '0'}}>
            <Header>
              <div className="hd-slogan">
                <span><img src={this.hdSlogan1}/></span>
                <span style={{marginLeft: "5.26px"}}><img src={this.hdSlogan2}/></span>
                <span style={{margin: "11.76px 0px 0px 5.26px"}}><img src={this.hdSlogan3}/></span>
              </div>
              <div className="hd-threedot-l">
                <span><img src={this.hdThreedot}/></span>
              </div>
              <div className="hd-search-area">
                <span className="hd-search-icon"><img src={this.hdSearch}/></span>
                <span className="hd-search-input"><input style={{width: "294px",}} value="SEARCH MATERIAL BY NAME, CATEGORY, LOCATION"/></span>
                <span className="hd-ai"><img src={this.hdAi}/></span>
                <span className="hd-voice-rc"><img src={this.hdVoiceRc}/></span>
              </div>
              <div className="hd-threedot-r">
                <span><img src={this.hdThreedot}/></span>
              </div>
              <div className="hd-right">
                <span className="hd-right-icon-chat"><img src={this.hdChat}/></span>
                <span className="hd-right-icon-notify"><img src={this.hdNotify}/></span>
                <span className="hd-right-icon-shoping"><img src={this.hdShoping}/></span>
              </div>
            </Header>
            <MyContainer>
              <div style={navSidebarMenu.sidebar}>
                <SidebarContent>
                    <ul style={navSidebarMenu.sidebarItems}>
                        {
                            NavigationSidebarDataFirst.map((val,key) => {
                                return (
                                    <SidebarContentItem className={val.name} key={key}>
                                        <div className={"icon"+val.name} style={navSidebarMenu.divIcon}><img src={val.icon}/></div>
                                        <div className={"title"+val.name} style={navSidebarMenu.divTitle}>{val.title}</div>
                                    </SidebarContentItem>
                                )
                            })
                        }
                    </ul>
                    <ul style={navSidebarMenu.sidebarItems}>
                        {
                            NavigationSidebarDataSecond.map((val,key) => {
                                return (
                                    <SidebarContentItem  className={val.name} key={key}>
                                        <div style={navSidebarMenu.divIcon}><img src={val.icon}/></div>
                                        <div style={navSidebarMenu.divTitle}>{val.title}</div>
                                    </SidebarContentItem>
                                )
                            })
                        }
                    </ul>
                    <ul style={{...navSidebarMenu.sidebarItems,border:'none'}}>
                        {
                            NavigationSidebarDataThird.map((val,key) => {
                                return (
                                    <SidebarContentItem key={key} className={val.name}>
                                        <div style={navSidebarMenu.divIcon}>
                                          <img src={val.icon}/>
                                        </div>
                                        <div className={val.name+val.link} key={key}
                                          style={navSidebarMenu.divTitle} onClick={() => this.handleOnclickSidebarItem(val.link)}>
                                          {val.title}
                                        </div>
                                    </SidebarContentItem>
                                )
                            })
                        }
                    </ul>
                </SidebarContent>
                <div style={{height:"100%", width: "100%", borderTop: "1px solid #BA9553",alignItems: "center",
                    justifyContent: "center"}}>
                    <div style={{borderRight: "1px solid #BA9553",height: "100%",width: "50%"}}></div>
                </div>
              </div>
              <ContentMain>
                <Settings userInfoData={this.state.userInfoData} onUpdateUserInfo={this.updateUserInfo} />
              </ContentMain>
          </MyContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});

//css for Layout container
const Header = styled('div')(
  {
      width: "1557px",
      height: "97px",
      backgroundColor: "#FFFFFF",
      marginBottom: "19px",
      display: "flex",
      "& .hd-slogan" : {
          height: "16.05px",
          width: "245.71px",
          margin: "55.73px 0px 0px 69px",
          display: "flex",
      },
      "& .hd-threedot-l": {
        height: "28px",
        width: "110px",
        margin: "44px 0px 0px 110.29px",
      },
      "& .hd-threedot-r": {
        height: "28px",
        width: "110px",
        margin: "44px 0px 0px 92.37px",
      },
      "& .hd-search-area": {
        height: "100%",
        width: "500px",
        marginLeft: "79px",
        display: "flex",
      },
      "& .hd-search-icon": {
        height: "32.63px",
        width: "32.63px",
        margin: "34.19px 0px 0px 0px",
      },
      "& .hd-search-input": {
        height: "17px",
        margin: "42px 0px 0px 54px",
        fontSize: "10px",

      },
      "& .hd-ai": {
        height: "32.63px",
        width: "32.63px",
        margin: "34.19px 0px 0px 42px",
      },
      "& .hd-voice-rc": {
        height: "32.63px",
        width: "32.63px",
        margin: "34.19px 0px 0px 0px",
      },
      "& .hd-right": {
        height: "157px",
        width: "37px",
        margin: "36px 0px 0px 48px",
        display: "flex",
      },
      "& .hd-right-icon-chat": {
        margin: "15px 0px 0px 0px",
      },
      "& .hd-right-icon-notify": {
        margin: "15px 0px 0px 40px",
      },
      "& .hd-right-icon-shoping": {
        margin: "0px 0px 0px 43px",
      },
  }
);

const MyContainer = styled('div')(
  {
      display: "flex",
  }
);

const ContentMain = styled('div')(
  {
      flex: '1',
      padding: "45px 0px 0px 20px",
      marginLeft: '300px',
      textAlign: 'justify'
  }
);

// css setting for NavigationSidebar
const navSidebarMenu = {
  body : {
      margin: 0,
      padding: 0,
  },

  sidebar : {
      height: "1311px",
      width: "280px",      
      display: "flex",
      position: "absolute",
  } as React.CSSProperties,

  sidebarItems : {
      height: "auto",
      padding: "0px 0px 16px 0px",
      with: "auto",
      borderBottom:'1px solid #EDF0F2',
  } as React.CSSProperties,

  divIcon : {
      flex: "25%",
      height:"20px",
      display: "grid",
      placeItems: "center"
  },
  divTitle : {
      marginLeft: "4px",
      flex: "75%"
  }

};

const SidebarContent = styled('div')({
  width: "100%",
  height: "599px",
  marginTop: "26px",
  marginLeft: "30px",
  opacity: "0px",
  "& .sb-userinfo": {
    width: "100%",
    height: "146px",
    margin: "93px 0px 0px 0px",
    
  },
  "& .sb-userinfo-m": {
    width: "100%",
    height: "128px",
    margin: "93px 0px 0px 0px",
    border: "1px solid #D9D9D9",
    fontSize: "12px",
  },
  "& .sb-userinfo-m-name": {
    width: "86px",
    height: "17px",
    color: "#162A26",
    margin: "40px 40px 0px 40px",
  },
  "& .sb-userinfo-m-email": {
    height: "14px",
    color: "#162A26",
    margin: "40px 40px 0px 40px",
    
  },
  "& .sb-userinfo-b": {
    width: "100%",
    height: "41px",
    backgroundColor: "#F9FAF9",
  },
  "& .sb-userinfo-b-buyer": {
    color: "#162A26",
    fontSize: "10px",
  },
});
const SidebarContentItem = styled('li')({
  width: "220px",
      height: "24px",
      opacity: "0px",
      padding: "2px 4px 2px 4px",
      listStyleType: "none",
      display: "flex",
      flexDirection: "row",
      fontSize: "12px",
      color: "#29224",
      alignItems: "center",
      justifyContent: "center"
});
// Customizable Area End
