import React from "react";
// Customizable Area Start
import { Box, Container , Typography, Chip, ChipProps} from "@mui/material";
import { styled } from '@mui/system';
import { SearchIcon} from "../src/assets"
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";

interface CustomChipProps extends ChipProps {
  isFirst?: boolean;
}
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
   const {searchItems} = this.state;
    return (
      //Merge Engine DefaultContainer
      <NavigationWrapper id={""} {...this.props}>

         <ContainerDashboardRootMain>
        <TitleContainer>
          <Title>YOUR RECENT SEARCHES</Title>
          <TitleSecond data-test-id="clear-search-id" onClick={this.handleClearSreach}>Clear all searches</TitleSecond>
        </TitleContainer>
         
        <RecentSearchContainer>
 
  <SearchChipsContainer>
  {searchItems.map((item, index) => (
  <SearchChip
    key={index}
    icon={<img src={SearchIcon} alt="Search Icon" style={{ width: '18px', height: '18px',marginLeft:"9px" }} />}
    label={item}
    clickable
    isFirst={index === 0}
    variant="outlined"
  />
))}
  </SearchChipsContainer>
</RecentSearchContainer>

      </ContainerDashboardRootMain>
      </NavigationWrapper>
     
     
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const ContainerDashboardRootMain = styled(Container)({
  flexGrow: 1,
  padding: "24px",

  '&.MuiContainer-root': {
    maxWidth: '100%',
  },

  '@media (max-width: 599px)': {
    '&.MuiContainer-root': {
      maxWidth: '100%',
      padding: "24px"
    },
  },

  '@media (min-width: 1200px)': {
    '&.MuiContainer-root': {
      maxWidth: '1340px',
      padding: '24px',
    },
  },

  '@media (min-width: 600px) and (max-width: 1199px)': {
    '&.MuiContainer-root': {
      maxWidth: '892px',
      padding: '27px',
    },
  },
  '@media screen and (min-width: 1920px) and (min-height: 1200px)': {
    '&.MuiContainer-root': {
      maxWidth: '1600px',
    },
  },

  '@media screen and (min-width: 1920px) and (max-height: 1080px)': {
    '&.MuiContainer-root': {
      maxWidth: '1500px',
    },
  },

  '@media (min-width: 2560px)': {
    '&.MuiContainer-root': {
      maxWidth: '2000px',
    },
  },
});
const TitleContainer = styled('div')({
  display:"flex",
  justifyContent: "space-between",
  alignItems:"center",
  width:"37%",
  '@media screen and (min-width: 320px) and (max-width: 768px)': {
    width:"100% !important"
  },

  '@media screen and (min-width: 800px) and (max-width: 900px)': {
    width:"100% !important"
  },
})

const RecentSearchContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '20px 0px ',
  overflowX: "auto"
});


const Title = styled(Typography)({
  fontWeight: 420,
  fontSize: '24px',
  color: '#2D6A4D',
  textTransform:"uppercase"
});

const TitleSecond = styled(Typography)({
  fontWeight: 390,
  fontSize: '16px',
  color: '#CBAE5B',
  cursor:"pointer",
  "@media (max-width: 1199px)": {  
  display:"none"
  }

})

const SearchChipsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px', 
  marginTop: '10px',
  "@media (max-width: 768px)": {  
    flexWrap:"noWrap",
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    overflowx: "auto"
  }

 
});

const SearchChip = styled(Chip)<CustomChipProps>(({ isFirst }) => ({
  width: isFirst ? "155px" : "220px", 
  height:"36px",
  border: '1px solid #BA902F',
  borderRadius: '25px',
  padding: '5px',
  color: '#2f5133',


  '& .MuiChip-label': {
    display: 'block',  
    overflow: 'hidden', 
    textOverflow: 'ellipsis',   
    whiteSpace: 'nowrap',   
    width: '100%',    
    boxSizing: 'border-box', 
    paddingLeft:"17px",
    color:"#8AAA91",
    fontSize:"14px",
    fontWeight: 400
  },
}));

// Customizable Area End
