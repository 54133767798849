export const logo = require("../assets/logo.png");
export const ThreedotIcon = require('../assets/header-threedot.png');
export const SearchIcon = require('../assets/search-icon.svg');
export const SearchIcon2 = require('../assets/search_icon2.svg');
export const MapIcon = require('../assets/map-icon.svg');
export const CloseIcon = require('../assets/close_icon.svg');
export const BgImage = require('../assets/bg_image.png');
export const BgImageGreen = require('../assets/bg_image_green.png');
export const RoundClose = require('../assets/round_close.svg');
export const SearchIcon3 = require('../assets/search_icon3.svg');
export const SearchIcon4 = require('../assets/search_icon4.svg');
export const ClockIcon = require('../assets/clock_icon.svg');
export const ExpandIcon = require('../assets/expand_icon.svg');
export const CheckboxIcon = require('../assets/checkbox.svg');
export const FilledCheckboxIcon = require('../assets/filled_checkbox.svg');
export const NoSearchImg = require('../assets/no_search_img.png');
