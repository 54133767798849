export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeIcon = require("../assets/closeIcon.svg");
export const IconAddphoto = require("../assets/icon-add-photo.svg");
export const DeleteImg = require("../assets/deleteImg.svg");
export const DownArrow = require("../assets/downArrow.svg");
export const NoUserAdded = require("../assets/no_user_img.png");
export const MoreActionIcon = require("../assets/image_dots.svg");
export const EditIcon = require("../assets/edit_icon.svg");
export const CopyIcon = require("../assets/copy_icon.svg");
export const SuspendIcon = require("../assets/suspend_icon.svg");
export const LeftArrow = require("../assets/LeftArrowImg.svg");
export const RightArrow = require("../assets/RightArrowImg.svg");