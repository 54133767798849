import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { apiCall, handleExpiredToken } from "../../../blocks/utilities/src/CommonFunctions";
import { ComapnyDetailResponse, CompanyDetailAttributes } from "../../../blocks/utilities/src/typeInterface";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";

interface UserInfo {
  name: string;
  initial_address: string;
  address: string;
  country: string;
  city: string;
  phone_number: string;
  zip_code: string;
  country_code: number | string;

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userInfo: UserInfo
  apiMethod: string
  isDisable: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class CompanyDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyDetailsApiCallId: string = "";
  createCompanyDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      userInfo: {
        name: "",
        initial_address: "",
        address: "",
        zip_code: "",
        country: "",
        city: "",
        phone_number: "",
        country_code: ""
      },
      apiMethod: "PUT",
      isDisable: true
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJsonData, this.props);

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getCompanyDetailsApiCallId) {
          this.getCompanyDetailsResponse(responseJsonData);
        }
        if (apiCallId === this.createCompanyDetailCallId) {
          this.createCompanyDetailResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleCountryChange = (setFieldValue: (field: string, value: string | number) => void, event: SelectChangeEvent<unknown>) => {
    const selectedOption = event.target.value as string;

    let country_code = '';

    switch (selectedOption) {
      case 'United Kingdom':
        country_code = '44';
        break;
      case 'Germany':
        country_code = '49';
        break;
      case 'Spain':
        country_code = '34';
        break;
      case 'France':
        country_code = '33';
        break;
      case 'Italy':
        country_code = '39';
        break;
      case 'Portugal':
        country_code = '351';
        break;
      default:
        break;
    }

    setFieldValue('country', selectedOption);
    setFieldValue('country_code', country_code);

  };

  onResetForm = (handleReset: () => void) => {
    handleReset();
  }

  setInitialValues = () => {
    const { userInfo } = this.state;
    return {
      name: userInfo.name,
      initial_address: userInfo.initial_address,
      address: userInfo.address,
      country: userInfo.country,
      city: userInfo.city,
      phone_number: userInfo.phone_number,
      zip_code: userInfo.zip_code,
      country_code: userInfo.country_code,
    };
  }

  async componentDidMount() {
    this.getCompanyDetails();
  }

  
  setCompanyDetailInfo = (attributes: CompanyDetailAttributes) => {
    this.setState({
      userInfo: {
        ...attributes,
        name: attributes.name,
        initial_address: attributes.initial_address,
        address: attributes.address,
        country: attributes.country,
        city: attributes.city,
        phone_number: attributes.phone_number,
        zip_code: attributes.zip_code,
        country_code: attributes.country_code,
      },
    })
  }

  getCompanyDetails = async () => {
    this.getCompanyDetailsApiCallId = await apiCall({ method: configJSON.httpGetMethod, endPoint: configJSON.getCompanyDetailsApiEndpoint, token: true });
  }

  getCompanyDetailsResponse = (responseData: ComapnyDetailResponse) => {
    const { attributes } = responseData.data
    this.setCompanyDetailInfo(attributes)
    this.setState({
      isDisable: !attributes.is_admin
    })
    if (responseData.data.attributes.id) {
      this.setState({
        apiMethod: "PUT"
      })
    }
  }

  createCompanyDetail = async (values: Partial<UserInfo>) => {
    const  data =  {
        attributes: {
          name: values.name,
          address: values.address,
          initial_address: values.initial_address,
          city: values.city,
          country: values.country,
          zip_code: values.zip_code,
          country_code: values.country_code,
          phone_number: values.phone_number
        }
      }

    this.createCompanyDetailCallId = await apiCall({
      method: this.state.apiMethod,
      body: JSON.stringify({data}),
      endPoint: configJSON.updateCompanyDetailsEndpoint,
      token: true,
      contentType:configJSON.searchApiContentType
    });
  }

  createCompanyDetailResponse = (responseData: ComapnyDetailResponse & { errors: { message: string } }) => {
    if (responseData?.data?.attributes) {
      const { attributes } = responseData.data
      this.setCompanyDetailInfo(attributes)
      toast.success(configJSON.formSuccessMsg)
    } else {
      toast.error(responseData.errors.message)
    }
  }
  // Customizable Area End
}
