import React from "react";

// Customizable Area Start
import {
  Button,
  Container,
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import UsersTableController, {
  Props,
  configJSON,
} from "./UsersTableController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import AddUserModal from "./AddUserModal.web";
import { CopyIcon, EditIcon, MoreActionIcon, NoUserAdded, SuspendIcon } from "./assets";
import { roleList } from "../../../blocks/utilities/src/constants";
import Loader from "../../../components/src/Loader.web";
import CustomPagination from "../../../components/src/CustomPagination.web";

// Customizable Area End


export default class UsersTable extends UsersTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  noUserAdded = () => {
    return (
      <Box className="noUserAddedConatiner">
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12} sm={6} className="imgContainer">
            <img className="noUserImg" src={NoUserAdded} alt="No User" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="noUserTextContainer">
              <Typography className='noUserHeader'>{configJSON.no_user_text}</Typography>
              <Typography className='noUserDesc' component={"p"}>
                It looks like there are no users added to your company yet. To start adding users, click on the <span>"Add New"</span> button. This will allow you to invite new members to your platform and get your team up and running.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  actionButton = (userId: number | string) => {
    const { anchorEl, user_id, allowManageUser } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label="more"
          id="action-button"
          data-test-id="action-button"
          aria-controls={open ? 'action-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleClick(event, userId)}
          className={`${userId === user_id && "activeAction"}`}
        >
          <img src={MoreActionIcon} alt="MoreActionIcon" />
        </IconButton>
        <Menu
          id="action-menu"
          data-test-id="action-menu"
          MenuListProps={{
            'aria-labelledby': 'action-button',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          slotProps={{
            paper: {
              style: {
                boxShadow: "none",
                borderRadius: "8px",
                border: "1px solid #E2E8F0",
                width: '141px',
              },
            },
          }}
        >
          {allowManageUser && <StyledActionItems data-test-id="editUser" onClick={this.handleClose}>
            <div className="iconImage"><img src={EditIcon} /></div> <div>Edit</div>
          </StyledActionItems>}
          <StyledActionItems data-test-id="copyEmail" onClick={this.handleClose}>
            <div className="iconImage"><img src={CopyIcon} /></div> <div>Copy email</div>
          </StyledActionItems>
          {allowManageUser && <StyledActionItems data-test-id="suspendUser" onClick={this.handleClose}>
            <div className="iconImage"><img src={SuspendIcon} /></div>
            <div>Suspend</div>
          </StyledActionItems>}
        </Menu>
      </div>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { meta, loading, openModal, usersList, allowManageUser,maxUserCount } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <MainContainer>
          <Box className="innerContainer">
            <Box className="firstContainer">
              <Typography data-test-id='userTxt' className='userTxt'>{`${configJSON.user_text} ${meta.total_records}/${maxUserCount}`}</Typography>
              <Button
                variant="contained"
                data-test-id="addUserButton"
                className="addUserButton"
                onClick={this.addUser}
                disabled={!allowManageUser}
              >
                {configJSON.add_user_btn_text}
              </Button>
            </Box>

            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Phone no.</StyledTableCell>
                    <StyledTableCell >Job title</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersList.map(({ attributes: user }) => (
                    <StyledTableRow key={user.id}>
                      <StyledTableCell className="profileImgCell">
                        <Box className={"userDetailsCell"} sx={{ justifyContent: "center" }}>
                          <Avatar
                            src={user.account_image}
                            alt="avatar"
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box className={"userDetailsCell"}>
                          <div>
                            <Typography className="fullName" component="p" >{`${user.first_name} ${user.last_name}`}</Typography>
                            <Typography className="email" component="p" >{user.email}</Typography>
                          </div>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {`${user.country_code} ${user.phone_number}`}
                      </StyledTableCell>
                      <StyledTableCell>{user.job_title}</StyledTableCell>
                      <StyledTableCell>{roleList.find(role => role.value === user.user_role)?.label}</StyledTableCell>
                      <StyledTableCell>
                        <Box className={`statusCell ${user.status}`}>
                          {user.status}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell className="actionCell">{this.actionButton(String(user.id))}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {
              meta.total_pages > 1 && <CustomPagination
                data-test-id="pagination"
                meta={meta}
                onHandlePageChange={this.handlePageChange}
              />
            }

            {!usersList.length && this.noUserAdded()}
          </Box>
        </MainContainer>
        <AddUserModal
          {...this.props}
          data-test-id="addUserModal"
          openModal={openModal}
          onHandleCloseModal={this.handleCloseModal}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Container)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: colors().white,
  paddingLeft: "0 !important",
  '@media (max-width: 480px)': {
    paddingLeft: "17px !important",
  },
  "& .innerContainer": {
    maxWidth: "1225px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .firstContainer": {
    width: "100%",
    height: "auto",
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap",
    marginBottom: "36px",
    '@media (max-width: 480px)': {
      gap: "20px",
    },
  },
  "& .noUserAddedConatiner": {
    marginTop: "132px",
    width: "100%",
    "& .imgContainer": {
      display: "flex",
      justifyContent: "end",
      '@media (max-width: 600px)': {
        justifyContent: "center",
      },
    },
    "& .noUserImg": {
      height: "100%",
      width: "100%",
      maxHeight: "290px",
      maxWidth: "476px",
      objectFit: "contain",
      aspectRation: "3/2"
    },
    "& .noUserTextContainer": {
      maxWidth: "327px",
    },
    "& .noUserHeader": {
      color: '#1F2024',
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '44px',
      wordBreak: "break-word",
      marginBottom: "40px",
      fontFamily: "garamond-premier-pro !important",
    },
    "& .noUserDesc": {
      color: '#3E4047',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '28px',
      wordBreak: "break-word",
      fontFamily: "garamond-premier-pro !important",
      "& span": {
        color: '#BA902F',
        fontFamily: "garamond-premier-pro !important",
      }
    },
  },
  "& .userTxt": {
    color: '#1F2024',
    fontSize: '18px',
    fontWeight: 450,
    lineHeight: '26px',
    textTransform: "uppercase",
    wordBreak: "break-all",
  },
  "& .addUserButton": {
    width: '117px',
    height: '44px',
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 420,
    color: colors().whiteText,
    backgroundColor: colors().black,
    borderRadius: "36px",
    textTransform: "none",
  },
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    color: '#407B5F',
    fontSize: '14px',
    fontWeight: 390,
    lineHeight: '22px',
    borderColor: "#ECECEC"
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#292524',
    fontSize: '14px',
    fontWeight: 390,
    lineHeight: '22px',
    border: "none",
  },
  "& .userDetailsCell": {
    display: "flex",
    alignItems: "center",
    gap: "29px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      borderRadius: "50%"
    },
    "& .fullName": {
      fontWeight: 450,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#292524',
      textTransform: "capitalize" as const,
      wordBreak: "break-all",
      overflow: "hidden" as const,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "300px",
      minWidth: "167px",
    },
    "& .email": {
      fontSize: '12px',
      fontWeight: 390,
      lineHeight: '24px',
      wordBreak: "break-all",
      color: '#78716C',
      overflow: "hidden" as const,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "300px",
      minWidth: "167px",
    },
  },
  "&.profileImgCell": {
    width: "32px",
  },
  "& .statusCell": {
    borderRadius: "23px",
    fontWeight: 450,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: "capitalize" as const,
    padding: "5.5px 16px 5.5px 16px",
    width: "fit-content",
    "&.active": {
      color: '#528A6F',
      backgroundColor: '#D4F0DA',
    },
    "&.pending": {
      color: '#44403C',
      backgroundColor: '#FCD34D',
    },
    "&.suspended": {
      color: '#94A3B8',
      backgroundColor: '#F1F5F9',
    },
  },
  "&.actionCell": {
    width: "40px",
  },
  "& .activeAction": {
    borderRadius: 5,
    backgroundColor: "#E2E8F0",
  },
  "&.name": {
    minWidth: "225px",
    textAlign: "center",
  },
  "&.phone, &.jobTitle": {
    minWidth: "156px",
  }
});

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
});

const StyledActionItems = styled(MenuItem)({
  cursor: "pointer",
  gap: "8px",
  height: "36px",
  padding: "0",
  paddingLeft: "16",
  listStyleType: "none",
  display: "flex",
  flexDirection: "row",
  fontWight: 390,
  fontSize: "14px",
  lineHeight: "22px",
  color: "#0F172A",
  alignItems: "center",
  justifyContent: "left",

  "&:hover": {
    color: colors().darkGold,
    background: colors().lightGold,
    transition: "all 0.1s ease-in",
    "& img": {
      filter: 'brightness(0) saturate(100%) invert(49%) sepia(68%) saturate(409%) hue-rotate(1deg) brightness(96%) contrast(101%)'
    },
  },

  "& .iconImage": {
    height: "20px",
    display: "grid",
    placeItems: "center",
  },
});

// Customizable Area End
