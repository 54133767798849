import React from "react";

// Customizable Area Start
import { styled } from "@mui/styles";
import { Button,Box } from "@mui/material";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class LandingPageBlock extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div style={styles1.mainContainer}>
        <Box sx={styles1.navbar}>
          <MenuItem data-test-id="home">HOME</MenuItem>
          <MenuItem data-test-id="mktplace">CIRCULAR MARKETPLACE</MenuItem>
          <MenuItem data-test-id="aboutus">ABOUT US</MenuItem>
          <MenuItem data-test-id="works">HOW IT WORKS</MenuItem>
          <MenuItem data-test-id="works">WHITE PAPER</MenuItem>
          <MenuItem data-test-id="works">PRICING</MenuItem>
          <MenuItem data-test-id="works">ESG</MenuItem>
        </Box>
        <div style={styles1.navbar2}>
          <MenuItem data-test-id="works">CONTACT</MenuItem>
        </div>
        <div style={styles1.navbar1}>
          <LoginButton data-test-id="loginBtn" onClick={() => {this.handleLogin()}}>
            LOGIN
          </LoginButton>
          <SignupButton data-test-id="signupBtn" onClick={() => {this.handleSignup()}}>
            SIGN UP
          </SignupButton>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

const MenuItem = styled(Button)({
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Brandon Grotesque",
  fontSize: "16px",
  pointer:"cursor",
  flexGrow:"1",
  flexShrink:"1",
  overflow: "hidden",
  whiteSpace: "nowrap",

  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
  "&.MuiButtonBase-root.MuiButton-root": {
    color: "white",
    // minWidth: 87,
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: "#B7862B",
    backgroundColor: "#162A26"
  },
});
const LoginButton = styled(Button)({
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Brandon Grotesque",
  fontSize: "16px",
  pointer:"cursor",
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
  "&.MuiButtonBase-root.MuiButton-root": {
    color: "white",
    minWidth: 80,
  },
  "&.MuiButtonBase-root.MuiButton-root:hover": {
    color: "#B7862B",
    backgroundColor: "#162A26"
  },
});
const SignupButton = styled(Button)({
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Brandon Grotesque",
  fontSize: "16px",
  pointer:"cursor",
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
  "&.MuiButtonBase-root.MuiButton-root": {
    color: "white",
    minWidth: 80,
    background: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    border: "1px solid black",
    whiteSpace: "nowrap",
    borderRadius:"0px",
  },
});
const styles1 = {
  mainContainer: {
    backgroundColor: "#162A26",
    height: "100vh",
    alignItem: "center",
    justifyContent: "center",
    color: "white",
    fontFamily: "Brandon Grotesque",
    fontSize: "16px",
    display: "flex",
  },
  navbar: {
    height: "42px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    Width: "834px",
    marginTop: "20px",
    flexWrap:'wrap',
    border: "1px solid #D8C487",
    // borderImageSource: "linear-gradient(99.09deg, #D8C487 2.64%, #B7862B 100%)",
    marginRight: "20px",
    marginLeft: "344px",
    padding:"2px",
    gap: "24px",
    opacity: "0px",
    overflow: "hidden",
  },
  navbar2: {
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    // borderImageSource:"linear-gradient(99.99deg, #D8C487 2.64%, #B7862B 100%)",
    width: "122px",
    marginTop: "20px",
    marginRight: "50px",
    padding:"2px",
    gap: "10px",
    opacity: "0px",
    
  },
  navbar1: {
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D8C487",
    width: "182px",
    marginTop: "20px",
    opacity:"0px",
    padding:"2px",
  },
};
