//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CardMedia,
  Card,
  // Customizable Area End
  Grid,

} from "@mui/material";

// Customizable Area Start
import ReactImageMagnify from 'react-image-magnify';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { styled } from '@mui/system';
import ImageMain from '../assets/sewamo.png';
import MinusIcon from '../assets/minusIcon.svg';
import PlusIcon from '../assets/plusIcon.svg';
import ZoomIcon from '../assets/zoomIcon.svg';
import PriceIcon from '../assets/priceIcon.svg';
import UnlockIcon from '../assets/unlockIcon.svg';
import InsFist from '../assets/inspirationSecond.png';
import InsSecond from '../assets/inspirationFirst.png';
import CertificationFirst from '../assets/certificationFirst.svg';
import CertificationSecond from '../assets/certifiSecond.svg';
import CertificationThird from '../assets/certifiThird.svg';
import productImg from '../assets/productImage.svg';
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";

import CommonModalComponent from "../../../components/src/CommonModalComponent";

import { FavoriteBorder as FavoriteBorderIcon } from '@mui/icons-material';
import Loader from "../../../components/src/Loader.web";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  }
);

// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderProductInsipiration = () => {
    const { tokenMain } = this.state
    return (
      <>
        <Wrapper className={tokenMain ? "with-token" : "without-token"}>
          <Typography style={{ fontSize: "24px", lineHeight: "106px", fontWeight: 420, color: "#292524", textTransform: "uppercase", margin: "0" }} align="center" gutterBottom>
            Inspirations
          </Typography>
          <InspirationContainer className={tokenMain ? "token" : "without_token"} container spacing={{ xs: 0, sm: 0 }}>
            <div className="insImgContainer">
              <ImageContainerOne>
                <img
                  src={InsSecond}
                  alt="Inspiration 1"
                />
              </ImageContainerOne>
            </div>
            <InspiratinMidText>
              <TextBox>
                <TextInnerBox>
                  <InspirationComponent>
                    Jacket Revamp
                  </InspirationComponent>
                  <DescriptionComponent>
                    {configJSON.InspirationText}
                  </DescriptionComponent>
                </TextInnerBox>
              </TextBox>
            </InspiratinMidText>
            <div className="insImgContainer">
              <ImageContainerOne>
                <img
                  src={InsFist}
                  alt="Inspiration 2"
                />
              </ImageContainerOne>
            </div>
          </InspirationContainer>
        </Wrapper>
        
        <SimilarProductWrapper ref={this.anchorRef} className={tokenMain ? "with-similar-token" : "without-similar-token"}>
          <SimilarProductComponent align="center" className={tokenMain && "similerTitleWithToken"}>
            Similar products
          </SimilarProductComponent>

          <MainContainer>
            <GridContainerMain>
              {items.map((item: Item, index: number) => (
                <CardMainComponent className={tokenMain ? "with_card_token" : "without_card_token"}>
                  <div style={{ position: 'relative' }} data-test-id="grid-navigate-id" onClick={this.handleProductDescription}>
                    <CardMediaComponent
                      image={item.image}
                    />
                    <IconButtonComponent data-test-id="similar-product-test-id" onClick={(event) => {
                      event.stopPropagation()
                      this.handleSimilarProductLoginSignup()
                    }} 
                    className="fav-icon"
                    >
                      <FavoriteBorderIcon />
                    </IconButtonComponent>
                  </div>
                  <CardContentComponent>
                    <Typography className="title-txt" noWrap>
                      {item.title}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ContainerMain>
                        <ItemNew>
                          <PriceTypography>PRICE</PriceTypography>
                          <Value>
                            £0.45 /m
                          </Value>
                        </ItemNew>
                        <ItemNew>
                          <PriceTypography>REQUESTED</PriceTypography>
                          <Value>
                            150,000 m
                          </Value>
                        </ItemNew>
                      </ContainerMain>
                      <CardContentRowLast>
                        <div style={{ height: "14px", width: "14px", backgroundColor: "#D84539" }} />
                      </CardContentRowLast>
                    </div>
                  </CardContentComponent>
                </CardMainComponent>
              ))}
            </GridContainerMain>
          </MainContainer>
        </SimilarProductWrapper>
      </>
    )
  }



  renderProductDetail = () => {
    const { productDescriptionData } = this.state

    const getBackgroundColor = (isSelected, selectedColorName, defaultColor) => {
      if (isSelected) {
        return selectedColorName === "teal" ? "#FFEED8" : selectedColorName;
      }
      return defaultColor.toLowerCase();
    };

    const DetailRowWeight = ({ children, value }) => {
      return (

        <div style={{
          display: "flex", width: "100%",
          paddingLeft: "15px",
          justifyContent: "space-between",
          margin: "4px 0px",
          minHeight: "27px",
          alignItems : "baseline",
        }}>
          <span style={{ width: "50%", fontWeight: 450, fontSize: "16px", color: "#292524", lineHeight: "22.88px", }}>{children}</span>
          <span style={{ width: "50%", fontWeight: 390, lineHeight: "22.88px", fontSize: "16px", color: "#292524" }}>{value}</span>
        </div>
      );
    };

    const DetailRowRightYarn = ({ label, value }) => (
      <div style={{
        display: "flex", width: "100%",
        paddingLeft: "7px",
        justifyContent: "space-between",
        margin: "4px 0px",
        minHeight: "27px",
        alignItems : "center",
      }}>
        <div style={{ width: "50%", fontWeight: 450, lineHeight: "22.88px", fontSize: "16px", color: "#292524" }}>{label}</div>
        <div style={{ width: "50%", fontWeight: 390, lineHeight: "22.88px", fontSize: "16px", color: "#292524" }}>{value}</div>
      </div>
    );

    const renderCottonRowRight = () => (
      <CottonContainer>
       {DetailRowRightYarn({ label: "Dyed or Non-Dyed", value: productDescriptionData.attributes.dye.value})}
       {DetailRowRightYarn({ label: "Colour", value: 
          <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "17px",
            height: "17px",
            backgroundColor: getBackgroundColor(
              this.state.isSelected,
              this.state.selectedColor.name,
              productDescriptionData.attributes.color.value
            ),
            marginRight: "10px",
          }}
        />
          <span
          style={{
            textTransform: "capitalize",
            fontWeight: 390,
            fontSize: "16px",
          }}
        >
          {this.state.isSelected
            ? this.state.selectedColor.name
            : productDescriptionData.attributes.color.value}
        </span>
       </div>
       })}

  {DetailRowRightYarn({ label: "Wash/Dry clean", value: productDescriptionData.attributes.wash_dry_clean.value})}
  {DetailRowRightYarn({ label: "Tumble dry", value:productDescriptionData.attributes.tumble_dry === true ? "Yes" : "No" })}
  {DetailRowRightYarn({ label: "Iron temperature", value:productDescriptionData.attributes.iron_temperature.value })}
  {DetailRowRightYarn({ label: "Bleaching", value:productDescriptionData.attributes.bleaching === true ? "Yes" : "No" })}
                    <DetailItemLast>
                      {productDescriptionData.attributes.cleaning_instruction.value ?? "Additional care instruction"}
                    </DetailItemLast>
                </CottonContainer>
    )


    const renderYarnRowRight = () => (
             <YarnContainer >    
              {DetailRowRightYarn({ label: "Dyed or Non-Dyed", value: productDescriptionData.attributes.dye.value})}
              {DetailRowRightYarn({ label: "Colour", value: 
                 <DetailRowRightMain>
                    <DetailRowInnerRightMain
           style={{
             backgroundColor: this.state.isSelected
               ? this.state.selectedColor.name === "teal"
                 ? "#FFEED8"
                 : this.state.selectedColor.name
               : productDescriptionData.attributes.color.value.toLowerCase(),
           }}
         />
         <DetailRowInnerRightSpan
         >
           {this.state.isSelected
             ? this.state.selectedColor.name
             : productDescriptionData.attributes.color.value}
         </DetailRowInnerRightSpan>
               </DetailRowRightMain>
              })}
                </YarnContainer>
              )

   const  DetailRow = ({ label, value }) => (
                <div style={{
                  margin: "4px 0px",
                  minHeight: "27px",
                  alignItems : "center",
                  display: "flex", 
                  width: "100%",
                  paddingLeft: "15px",
                  justifyContent: "space-between",
                }}>
                  <div style={{ width: "50%", fontWeight: 450, fontSize: "16px", lineHeight: "22.88px", color: "#292524" }}>{label}</div>
                  <div style={{ width: "50%", fontWeight: 390, fontSize: "16px", color: "#292524", lineHeight: "22.88px" }}>{value}</div>
                </div>
              );

   const renderCottonLeftData = () => (
               <Grid container item xs={12} sm={6} spacing={2}>
                {DetailRow({ label: "Fabric type", value: productDescriptionData.attributes.fabric_type.value})}
                  {CompositionGrid({ productDescriptionData })}
                  {DetailRow({ label: "Fibre pattern", value: productDescriptionData.attributes.fabric_pattern.value})}
                  {DetailRowWeight({
        children: (
          <>Weight (g/cm<sup>2</sup>)</>
        ),
        value: productDescriptionData.attributes.weight
      })}
                  {DetailRow({ label: "Width (cm)", value: productDescriptionData.attributes.width})}
                  {DetailRow({ label: "Stretch", value: productDescriptionData.attributes.stretch.value})}
                  {DetailRow({ label: "Country of origin", value: productDescriptionData.attributes.country_of_origin})}
                </Grid>
   )


   const DetailRowTwo = ({ label, value }) => (
    <div style={{
      display: "flex", width: "100%",
      paddingLeft: "15px",
      justifyContent: "space-between",
      margin: "4px 0px",
      alignItems : "center",
      minHeight: "27px",
    }}>
      <div style={{ width: "50%", fontWeight: 450, fontSize: "16px", lineHeight: "22.88px", color: "#292524" }}>{label}</div>
      <div style={{ width: "50%", fontWeight: 390, fontSize: "16px", lineHeight: "22.88px", color: "#292524" }}>{value}</div>
    </div>
  );

  const CompositionGrid = ({ productDescriptionData }) => (
      <div style={{margin: "4px 0px", width:"100%", marginLeft: "0px", paddingLeft: "14px", paddingTop: "2px", display: "flex" }}>
        <div style={{ width: "50%", display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <DetailItem>Composition</DetailItem>
        </div>
        <div style={{ width: "50%", display: 'flex', flexDirection: 'column' }}>
          {productDescriptionData.attributes.compositions.map((item) => (
            <div key={item.id}>
              <Typography style={{ fontSize: "16px", fontWeight: 390, color: "#292524", lineHeight: "22.88px" }}>
                {item.percent}% {item.value}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <ProductDetailContainer container marginTop="50px">
        <ProductDetailsSectionContainer item>
          <ProductDetailsSection>
            <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px" }} gutterBottom>
              Product Details
            </Typography>
            <DetailInnerContainer container spacing={2} style={{ marginTop: "43px" }}>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                renderCottonLeftData()
              ) : (
                <Grid   style={{ paddingTop: "18px" }} container item xs={12} sm={6} spacing={2}>
                  {DetailRowTwo({ label: "Fibre type", value: productDescriptionData.attributes.fabric_type.value })}
                  {CompositionGrid({ productDescriptionData })}
                  {DetailRowTwo({ label: "Country of origin", value: productDescriptionData.attributes.country_of_origin })}
                  {DetailRowTwo({ label: "Count/Tex", value: productDescriptionData.attributes.tex })}
                </Grid>
              )}
              <div style={{ width: "2px", backgroundColor: "#E7E5E4" }} />  
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                renderCottonRowRight()
              ) : (
                 renderYarnRowRight()
              )}
            </DetailInnerContainer>
          </ProductDetailsSection>
        </ProductDetailsSectionContainer>
        <DescriptionSectionContainer item >
          <DescriptionSection>
            <DescriptionFirstComponent>
              <div>
                <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px", lineHeight: "29px", marginBottom: "16px" }} gutterBottom>
                  Showroom
                </Typography>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "4px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontWeight: "450", fontSize: "14px", lineHeight: "20px", color: "#292524", marginRight: "5px" }}>
                      In Showroom
                    </Typography>
                    <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524", lineHeight: "20px" }}>
                      {productDescriptionData.attributes.sample_in_showroom === true
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", marginRight: "5px",  lineHeight: "20px" }}>
                      Location: <span style={{ fontSize: "14px", fontWeight: 390, color: "#292524",  lineHeight: "20px" }}>
                        {productDescriptionData.attributes.full_location}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "32px",  lineHeight: "2ppx" }}>
                <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px",  lineHeight: "29px", marginBoootm: "12px" }} gutterBottom>
                  Description
                </Typography>
                <DescrptionContentComponent paragraph>
                  {productDescriptionData.attributes.full_description}
                </DescrptionContentComponent>
              </div>

            </DescriptionFirstComponent>
            <LogisticalComponent >
              <Typography style={{ color: "#292524", fontWeight: 450, fontSize: "20px", lineHeight: "29px" }} gutterBottom>
                Logistical Information
              </Typography>
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", lineHeight: "20px",}}>
                    Location:
                  </Typography>
                  <Typography style={{ fontSize: "14px", fontWeight: 390, color: "#292524" , lineHeight: "20px" }}>
                    {productDescriptionData.attributes.full_location}
                  </Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ fontWeight: "450", fontSize: "14px", color: "#292524", lineHeight: "20px",}}>
                    Lead Time: <span style={{ fontSize: "14px", fontWeight: 390, color: "#292524" , lineHeight: "20px" }}>
                      This product should be delivered to you in{' '}
                      <span style={{ color: '#2D6A4D', fontWeight: '420' }}>7 working days</span>.
                    </span>
                  </Typography>
                </div>
              </div>
            </LogisticalComponent>
          </DescriptionSection>
        </DescriptionSectionContainer>
        <CertificationOuter item xs={12}>
          <Typography style={{ fontSize: "20px", fontWeight: 420, color: "#292524", lineHeight: "28.6px" }}>Certifications</Typography>
          <CertificationsSection>
            <div style={{ display: "flex", gap: "8.22px", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <CertificationImage src={productDescriptionData.attributes.first_certificate_image.url} />
              <p style={{ color: "#292524", lineHeight: "20px", margin: "0px", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.first_certificate_image.name}</p>
            </div>

            <div style={{ display: "flex", gap: "8.22px", flexDirection: "column", alignItems: "center" }}>
              <CertificationImage src={productDescriptionData.attributes.second_certificate_image.url} />
              <p style={{ color: "#292524", lineHeight: "20px", margin: "0px", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.second_certificate_image?.name}</p>
            </div>
            <div style={{ display: "flex", gap: "8.22px", flexDirection: "column", alignItems: "center" }}>
              <CertificationImage src={productDescriptionData.attributes.third_certificate_image.url} />
              <p style={{ color: "#292524", lineHeight: "20px", margin: "0px", fontWeight: 420, fontSize: "14px" }}>{productDescriptionData.attributes.third_certificate_image?.name}</p>
            </div>

          </CertificationsSection>
        </CertificationOuter>
      </ProductDetailContainer>
    )
  }

  renderThumbnailPart = () => {
    const { tokenMain, isZoomed, isOutOfStock, productDescriptionData, selectedImage } = this.state;

    const renderProductImage = () => (
      <div
        style={{
          border: '2px solid #E7E5E4',
          padding: '3px',
          marginBottom: '17px',
          position: "relative"
        }}
      >
        {isZoomed ? renderZoomedImage() :
          <ProductImage
            image={selectedImage || productDescriptionData?.attributes.pictures[0]?.url}
            style={{ width: '100%', height: 'auto' }}
          />}
        {isOutOfStock && (
          <Overlay>
            <OutOfStockContainer>
              <Text>Out</Text>
              <Text>Stock</Text>
            </OutOfStockContainer>
          </Overlay>
        )}
        <IconButtonComponentOne
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 2,
            "@media (max-width: 600px)": {
              top: "5px",
              right: "4px",
            },
          }}
          onClick={(event) => {
            event.stopPropagation()
            this.handleProductLoginSignup()
          }}
          data-test-id="login-signup-product-id"
        >
          <FavoriteBorderIcon />
        </IconButtonComponentOne>
        {renderZoomButton()}

      </div>
    );

    const renderZoomedImage = () => (
      <div style={{ width: '100%' }}>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Wristwatch by Ted Baker London',
              isFluidWidth: true,
              src: selectedImage || productDescriptionData?.attributes?.pictures[0]?.url,
            },
            largeImage: {
              src: selectedImage || productDescriptionData?.attributes?.pictures[0]?.url,
              width: 1200,
              height: 1800,
            },
            lensStyle: { backgroundColor: 'none' },
            enlargedImagePosition: 'over',
            enlargedImageContainerStyle: { backgroundColor: 'transparent' },
            isActivatedOnTouch: true,
          }}
        />
      </div>
    );

    const renderZoomButton = () => {
      const ZoomButtonComponent = ZoomButton
      return (
        <ZoomButton className={this.state.productDescriptionData.attributes.pictures.length < 1 && "new_class"} data-test-id="zoom-click-id" onClick={this.handleZoomClick}>
          <img src={ZoomIcon} alt="Zoom Icon" />
        </ZoomButton>
      );
    };

    return (
      <WrraperThumbnail>
        <ThumbnailContainer item>
          <div style={{ width: "100%", height: "100%" }}>
            {renderProductImage()}

            <SmallImagesContainer container>
              {productDescriptionData?.attributes?.pictures?.map((picture) => (
                <SmallImage key={picture.id} src={picture.url}
                  onClick={() => this.handleImageSelect(picture.url)}
                  style={{ border: selectedImage === picture.url ? '3px solid #E7E5E4' : 'none', padding: selectedImage === picture.url ? '3px' : 'none' }}
                  data-test-id="image-select-id"
                />
              ))}
            </SmallImagesContainer>
          </div>
        </ThumbnailContainer>
        {this.renderRightSideContainer()}
      </WrraperThumbnail>
    );
  };

  renderColorPallet = () => {
    const { productDescriptionData, selectedColor, colorGradients } = this.state;
    return (
      <div >
        <Typography style={{ fontSize: "16px", fontWeight: 450, color: "#162A26", lineHeight: "23px", marginBottom: "12px" }}>COLOUR: {this.state.isSelected
          ? selectedColor.name.toUpperCase()
          : productDescriptionData.attributes.color?.value.toUpperCase()}</Typography>
        <Grid style={{ alignItems: "center", width: "291px", justifyContent: "space-between" }} container>
          {colorGradients.map((item) => (
            <ColorButtonMain
              data-test-id="code-select-id"
              key={item.name}
              onClick={() => this.handleColorSelect(item)}
              className={this.state.selectedColor.name === item.name ? 'selected' : ''}
            >
              <ColorButton
                className={this.state.selectedColor.name === item.name ? 'selected' : ''}
                gradient={item.gradient}
              />
            </ColorButtonMain>
          ))}
        </Grid>
      </div>
    )
  }

  renderMaterialLcaDataWithButton = () => {
    const { tokenMain } = this.state;
    return (
      <Grid item>
        <Typography style={{ fontSize: "16px", fontWeight: 450, color: "#162A26", textTransform: "uppercase" }} marginTop={5}>
          Material LCA data
        </Typography>
        <MaterialLcaInnerContainer>
          <StyledContainer>
            <StyledContent>
              <div>
                <div style={{ fontSize: '10.1px' }}>Total CO2 Emissions</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
              <div>
                <div style={{ fontSize: '10.1px' }}>Water saved</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
              <div>
                <div style={{ fontSize: '10.1px' }}>Chemicals & dyes avoided</div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                  <img style={{ width: '20px', height: '20px' }} src={ImageMain} alt="" />
                  <p style={{ paddingLeft: '4px' }}>7676</p>
                </div>
              </div>
            </StyledContent>
            <UnlockOverlay>
              <ButtonComponentMain>
                <img src={UnlockIcon} />
                Coming soon
              </ButtonComponentMain>
            </UnlockOverlay>
          </StyledContainer>
          {!tokenMain
            &&
            <ButtonContainer>
              <Button onClick={this.handleOpenModalOne} data-test-id="order-sample-modal-test-id" className='button_first' variant="text">Order a sample</Button>
              <Button onClick={this.handleOpenModalTwo} data-test-id="add-basket-modal-test-id" variant="text"  className='button_first'>Add to basket</Button>
              <Button onClick={this.handleOpenModalThree} data-test-id="buy-now-modal-test-id"  className='button_last' variant="text">
                Buy Now
              </Button>
            </ButtonContainer>}
          {tokenMain &&
            <ButtonContainerAfterLogin>
              <Button data-test-id="order-sample-test-id" className='button_first_after_login' variant="text">Order a sample</Button>
              <Button variant="text" className='button_first_after_login'>Add to basket</Button>
              <Button data-test-id="buy-now-test-id" className='button_last_after_login' variant="text">
                Buy Now
              </Button>
            </ButtonContainerAfterLogin>
          }
        </MaterialLcaInnerContainer>
      </Grid>
    )

  }

  renderMeterField = () => {
    const { productDescriptionData } = this.state;
    return (
      <>
        {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
          <Grid item style={{ lineHeight: "15px" }}>
            <PriceTag>£{productDescriptionData.attributes?.price}</PriceTag>
            <p style={{ fontWeight: 420, margin: "0px", color: "#44403C" }}>/metre</p>
          </Grid>
        ) : (
          <Grid item style={{ lineHeight: "15px" }}>
            <PriceTag>£{productDescriptionData.attributes?.price}</PriceTag>
            <p style={{ fontWeight: 420, margin: "0px", color: "#44403C" }}>/KG</p>
          </Grid>
        )}
      </>
    )
  }

  renderPriceBlock = () => {
    const { productDescriptionData, tokenMain } = this.state;
    const unit = productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? "metres" : "KG";
    const price = (productDescriptionData.attributes?.discounted_price * this.state.meters).toFixed(2);

    return (
      <PriceComponent className="price_with_token" item>
        <div
          style={{
            ...custom_scrollbar,
            display: "flex",
            alignItems: "center",
            overflowX: "auto",

          }}
        >
          <img
            style={{ width: "13.17px", height: "13.17px", marginRight: "8px", paddingLeft: "9px" }}
            src={PriceIcon}
            alt="Price Icon"
          />
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 420,
              color: "#292524",
              margin: "0",
            }}
          >
            Price Summary: £{price} for {this.state.meters} {unit}
          </Typography>
        </div>
      </PriceComponent>
    )
  }

  renderRightSideContainer = () => {
    const { tokenMain, productDescriptionData } = this.state;
    return (
      <RightSideDataComponent item style={{ marginTop: "0px" }} >
        <Typography style={{ fontSize: "16px", fontWeight: 420, lineHeight: "25px", color: "#292524", margin: 0 }} gutterBottom>
          Circular Marketplace / <span style={{ color: "#292524" }}>{productDescriptionData?.attributes?.material_type?.value}</span>
        </Typography>
        <Typography style={{ fontSize: "24px", fontWeight: 420, color: "#292524", marginTop: "12px", lineHeight: "32.34px" }} gutterBottom>
          {productDescriptionData?.attributes?.display_name}
        </Typography>
        <Typography style={{ color: "#292524", fontSize: "16px", fontWeight: 420, marginTop: "8px", lineHeight: "22.88px", }} >
          PC ID <span style={{ color: "#2D6A4D" }}>#{productDescriptionData?.attributes?.pc_id}</span>
        </Typography>
        <Grid container style={{ margin: "12.5px 0", gap: 8 }} alignItems="center">
          <Grid item>
            <DiscountedPrice > £{(typeof productDescriptionData.attributes.discounted_price === 'number' && !isNaN(productDescriptionData.attributes.discounted_price)
              ? productDescriptionData.attributes.discounted_price.toFixed(0)
              : productDescriptionData.attributes.discounted_price)}</DiscountedPrice>
          </Grid>
          {productDescriptionData.attributes?.discount.length > 0 ? (
            this.renderMeterField()) : ("")}
          <Grid item style={{paddingLeft: "8px"}}>
            {productDescriptionData.attributes?.discount.length > 0 ? (
              <BoxComponent>
                <Typography style={{ color: "#528A6F", fontSize: "16px", fontWeight: 420 }}>

                  {parseFloat(productDescriptionData.attributes?.discount).toFixed(0)}% off
                </Typography>
              </BoxComponent>
            ) : ("")}

          </Grid>
        </Grid>
        {this.renderColorPallet()}
        <Typography style={{ color: "#162A26", fontWeight: 450, fontSize: "16px", textTransform: "uppercase" }} marginTop={2.5}>
          Metres required
        </Typography>
        <AvailableQuantityComponent className={tokenMain ? "with-token-moq" : "without-token-moq"}>
          {productDescriptionData?.attributes?.catalogue_type.toLowerCase() === "cotton" ? (
            <>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={1.5}>
                MOQ: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes.order_minimum_quantity.toLocaleString()} metres</span>
              </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#162A26" }} marginTop={1.5}>
                Available quantity: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes?.available_quantity.toLocaleString()} metres</span>
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={1.5}>
                MOQ: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes.order_minimum_quantity.toLocaleString()} KG</span>
              </Typography>
              <Typography style={{ fontSize: "14px", fontWeight: 450, color: "#292524" }} marginTop={1.5}>
                Available quantity: <span style={{ fontSize: "14px", fontWeight: 390 }}>{productDescriptionData.attributes?.available_quantity.toLocaleString()} KG</span>
              </Typography>
            </>
          )}
        </AvailableQuantityComponent>
        <Grid style={{ marginTop: "6px", gap: "12px" }} container alignItems="center">
          <MeterWrapper container alignItems="center">
            <Box className="firstBox">
              <Grid style={{ paddingLeft: "5px" }} item>
                <StyledIconButtonOne data-test-id="decrease-test-id" onClick={this.handleDecrease}>
                  <img src={MinusIcon} />
                </StyledIconButtonOne>
              </Grid>
              <Grid item>
                <MeterInput>{this.state.meters}</MeterInput>
              </Grid>
              <Grid item style={{ paddingRight: "5px" }}>
                <StyledIconButtonTwo data-test-id="increase-test-id" onClick={this.handleIncrease}>
                  <img src={PlusIcon} />
                </StyledIconButtonTwo>
              </Grid>
            </Box>
            {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
              <MeterComponent item>
                <UnitBox>Metres</UnitBox>
              </MeterComponent>
            ) : (
              <MeterComponent item>
                <UnitBox>KG</UnitBox>
              </MeterComponent>
            )}
          </MeterWrapper>
          {!tokenMain &&
            <>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <TotalWeightComponent>
                  <TotalWeightInnerComponentTypography component="label" >
                    Total weight:
                  </TotalWeightInnerComponentTypography>
                  <Box display="flex" alignItems="center" style={{width: "100%"}}>
                    <WeightBox>
                      <WeightInnerTypography>
                        {
                          !isNaN(productDescriptionData.attributes.weight) &&
                          !isNaN(this.state.meters) &&
                          ((parseFloat(productDescriptionData.attributes.weight) / 10000000) * parseFloat(this.state.meters)).toFixed(5)
                        }
                      </WeightInnerTypography>
                    </WeightBox>
                    <UnitBoxComponent>
                      <Typography className="unit-box" sx={{ fontWeight: 420, fontSize: '14px', color: '#162A26' }}>KG</Typography>
                    </UnitBoxComponent>
                  </Box>
                </TotalWeightComponent>

              ) : ("")}
            </>
          }

          {tokenMain &&
            <>
              {productDescriptionData.attributes.catalogue_type.toLowerCase() === "cotton" ? (
                <TotalWeightComponentAfterLogin>
                  <TotalWeightInnerComponentAfterLogin component="label" >
                    Total weight:
                  </TotalWeightInnerComponentAfterLogin>
                  <Box display="flex" alignItems="center" style={{width: "100%"}}>
                    <WeightBoxAfterLogin>
                      <WeightInnerTypographyAfterLogin>
                        {
                          !isNaN(productDescriptionData.attributes.weight) &&
                          !isNaN(this.state.meters) &&
                          ((parseFloat(productDescriptionData.attributes.weight) / 10000000) * parseFloat(this.state.meters)).toFixed(5)
                        }

                      </WeightInnerTypographyAfterLogin>
                    </WeightBoxAfterLogin>
                    <UnitBoxComponentAfterLogin>
                      <Typography className="unit-box" sx={{ fontWeight: 450, fontSize: '16px', color: '#162A26' }}>KG</Typography>
                    </UnitBoxComponentAfterLogin>
                  </Box>
                </TotalWeightComponentAfterLogin>
              ) : ""}
            </>
          }
        </Grid>
        {this.renderPriceBlock()}
        {this.renderMaterialLcaDataWithButton()}
      </RightSideDataComponent>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationWrapper {...this.props}>
        <ContainerRootMain className={this.state.tokenMain ? "container-product-with-token" : "container-product-without-token"}>
        <Loader loading={this.state.isloading} />
          {this.renderThumbnailPart()}
          {this.renderProductDetail()}
          {this.renderProductInsipiration()}
          <CommonModalComponent
            data-test-id="modalproduct-click-id"
            open={this.state.isOpen}
            handleClose={this.handleProductCloseModal}
            title="Access ProcureCircular"
            content={configJSON.ModalContent}
            primaryButtonLabel="LOG IN"
            secondaryButtonLabel="SIGN UP"
            primaryAction={this.handleProductNavigateLogin}
            secondaryAction={this.handleProductNavigateSignup}
          />
        </ContainerRootMain>
      </NavigationWrapper>



      // Customizable Area End
    );
  }
}

// Customizable Area Start


const GridWrapper = styled('div')({
  marginBottom: "8px",
  paddingLeft: "17px",
  "&.MuiGridItem": {
    marginBottom: "4px"
  }
})

const GridWrapperSecond = styled('div')({
  marginBottom: "8px",
  paddingLeft: "17px",
  "&.MuiGridItem": {
    marginBottom: "4px"
  }
})

const custom_scrollbar = {
  "&::-webkit-scrollbar": {
    width: "3px",
    height: "2px"
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#BA902F",
    borderRadius: "12px",
    minHeight: "2px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#BA902F",
  },

  scrollbarWidth: "8px",
}

const DetailRowRightMain = styled('div')({
display: "flex", alignItems: "center"
})

const DetailRowInnerRightMain = styled('div')({
  width: "17px",
  height: "17px",
  marginRight: "10px",
})

const DetailRowInnerRightSpan = styled('span')({
  textTransform: "capitalize", fontWeight: 390, fontSize: "16px" 
})

const ContainerRootMain = styled(Container)({
  "&.container-product-with-token": {
    flexGrow: 1,
    width: "100%",
    maxWidth: "1373px",
    padding: "70px 52px 46px 46px",
    boxSizing: 'border-box',

    '@media (max-width: 599px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        margin: 0,
        padding: "101px 20px 20px 20px !important",
      },
    },

    '@media (max-width: 1199px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        margin: 0,
        padding: "85px 32px 32px 32px",
      },
    },

    '@media (max-width: 1600px)': {
      '&.MuiContainer-root': {
        width: 'calc(100% - 227px)',
      },
    },

  },
  "&.container-product-without-token": {

    flexGrow: 1,
    width: "100%",
    maxWidth: "1436px",
    padding: "85px 46px 46px",
    boxSizing: 'border-box',

    '@media (max-width: 599px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        margin: 0,
        padding: "85px 20px 20px 20px !important",
      },
    },

    '@media (max-width: 1199px)': {
      '&.MuiContainer-root': {
        maxWidth: '100%',
        margin: 0,
        padding: "85px 32px 32px 32px",
      },
    },
  }


});

const WrraperThumbnail = styled('div')({
  flexWrap: "nowrap",
  display: "flex",
  gap: "67px",
  '@media (max-width: 799px)': {
    flexWrap: "wrap !important",
  },

  '@media (max-width: 1199px)': {
    flexWrap: "wrap !important",
    gap: "40px",
  },

});

const AvailableQuantityComponent = styled('div')({
  "&.with-token-moq": {
    display: "flex", gap: "12px",
    width: "100%",

  }, "&.without-token-moq": {
    display: "flex", gap: "12px",
    width: "100%",

  }

})
const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#D9D9D980',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  width: '93%',
});

const OutOfStockContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '92px',
  width: '295px',
  border: '1px solid #1E293B',
  backgroundColor: '#FFFFFF',
});

const Text = styled('p')({
  margin: 0,
  color: '#000',
});

const ColorButtonMain = styled("div")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "28px",
  height: "28px",
  minWidth: "28px",
  gap: "8px",
  opacity: 1,
  border: "1px solid #D6D3D1",
  boxSizing: "border-box",
  "&:hover": {
    border: "1.14px solid #BA902F",
    opacity: 1,
  },

  "&.selected": {
    border: "1.14px solid #BA902F",
    opacity: 1,
    width: "32px",
    height: "32px",
    minWidth: "32px",
  }
});

const ColorButton = styled(Button)(({ gradient }) => ({
  cursor: "pointer",
  width: "20px",
  height: "20px",
  minWidth: "20px",
  margin: "4px",
  background: gradient,
  borderRadius: "0",

  "&.selected": {
    opacity: 1,
    width: "27.43px",
    height: "27.43px",
    minWidth: "27.43px",
    background: gradient,
  }
}));

const MediumComponent = styled(Typography)({
  fontSize: "16px",
  fontWeight: 390,
  color: "#292524"
})
const DetailItemLast = styled(Typography)({
  fontSize: "16px",
  fontWeight: 450,
  color: "#292524",
  width: "50%",
  paddingLeft: "8px"
})


const MediumComponentColorBox = styled(Typography)(({ selectedColor }) => ({
  border: "1px solid #D6D3D1",
  width: "17px",
  height: "17px"
}));

const ThumbnailContainer = styled('div')({
  width: "50%",
  maxWidth: "579px",
  padding: "0 !important",

  '@media (min-width: 500px) and (max-width: 700px)': {
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: "100%",
  },

  '@media (max-width: 1024px)': {
    flexWrap: 'wrap',
    width: "100%",
    maxWidth: "100%",
  },

})

const RightSideDataComponent = styled('div')({
  marginTop: '10px',
  flex: 1,
  width: "50%",
  '@media (min-width: 768px) and (max-width: 600px)': {
    flexWrap: 'wrap',
  },
  '@media (max-width: 820px)': {
    flexWrap: 'wrap'
  },
});

const ProductImage = styled(CardMedia)({
  paddingTop: '100%',
  backgroundSize: 'contain',
});

const ZoomButton = styled('div')({
  position: "absolute",
  right: "16px",
  bottom: "16px",
  zIndex: 2,
  color: 'white',
  width: '33px',
  height: '33px',
  background: '#FFFFFF',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  "&.new_class": {
    bottom: "33px"
  },
  '@media  (max-width: 600px)': {
    right: "18px !important"
  },

});

const ZoomButtonAfterLogin = styled('div')({
  position: 'absolute',
  top: '80%',
  right: '17px',
  color: 'white',
  width: '33px',
  height: '33px',
  background: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

  '@media (max-width: 470px)': {
    top: '68%',
  },

  '@media (min-width: 471px) and (max-width: 500px)': {
    top: '60%',
  },

  '@media (min-width: 600px) and (max-width: 1199px)': {
    top: '80%',
    right: '19px',
  },

  '@media (min-width: 1025px) and (max-width: 1199px)': {
    top: '84%',
    right: '43px',
    width: '33px',
    height: '33px',
  },

  '@media (min-width: 1200px) and (max-width: 1300px)': {
    top: '45%',
    right: '18px',
  },

  '@media (min-width: 1300px) and (max-width: 1500px)': {
    top: '59%',
    right: '18px',
  },

  '@media screen and (min-width: 1501px) and (max-width: 1699px)': {
    top: '78%',
    right: '18px',
  },

  '@media screen and (min-width: 1700px) and (max-width: 1920px)': {
    top: '81%',
    right: '18px',
  },

  '@media screen and (min-width: 1921px) and (max-width: 2200px)': {
    top: '84%',
    right: '18px',
  }

});


const PriceTag = styled(Typography)({
  textDecoration: 'line-through',
  color: "#292524",
  fontSize: "20px",
  fontWeight: 420

});

const DiscountedPrice = styled(Typography)({
  fontSize: "40px",
  fontWeight: 420,
  color: "#292524"
});

const BoxComponent = styled(Box)({
  padding: "0px",
  borderRadius: "24px",
  opacity: "0px",
  border: "1px solid #528A6F",
  height: "27px",
  background: "#F4FFF3",
  minWidth: "73px",
  boxSizing: "border-box",
  textAlign: "center",
})

const SmallImagesContainer = styled(Grid)({
  marginTop: "10px",
  '@media (max-width: 470px)': {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
});

const PriceComponent = styled(Grid)({
  "&.price_with_token": {
    display: "flex",
    width: "343px",
    height: "51px",
    padding: "0px 6px",
    gap: "10px",
    border: "1px solid #D6D3D1",
    opacity: "0px",
    marginTop: "10px",
    '@media (max-width: 470px)': {
      width: "100%",
      minHeight: "51px",
      height: "auto"
    },
  },
  "&.price-without-token": {
    display: "flex",
    width: "343px",
    height: "51px",
    padding: "0px 6px",
    gap: "10px",
    border: "1px solid #D6D3D1",
    opacity: "0px",
    marginTop: "10px",
    '@media (max-width: 360px)': {
      width: "100% !important"
    },
    '@media (min-width: 1900px) and (max-width: 2200px)': {
      width: "345px",
    },
    '@media (min-width: 2201px) and (max-width: 2700px)': {
      width: "347px",
    }
  }

})

const ProductDetailsSectionContainer = styled(Grid)({
  display: "flex",
  width: "50%",
  padding: "35px 0px",
  '@media (max-width: 1199px)': {
    padding: "0px",
    width: "100%"
  }
})

const DescriptionSectionContainer = styled(Grid)({
  display: "flex",
  width: "50%",
  padding: "35px 60px",
  '@media (max-width: 599px)': {
    marginTop: "67px",

  },
  '@media (max-width: 1199px)': {
    padding: "0px",
    marginTop: "21px",
    width: "100%"
  },
  '@media (max-width: 1024px)': {
    marginTop: "67px",
  }
})

const CertificationOuter = styled(Grid)({
  marginTop: "120px",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  '@media (max-width: 1199px)': {
    gap: "16px",
    marginTop: "32px",
  }
})

const TotalWeightComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  '@media (max-width: 470px)': {
    width: "100%",
  },
  '@media (max-width: 690px)': {
    marginTop: "11px"
  },
  '@media (min-width: 1199px) and (max-width: 1350px)': {
    marginTop: "11px"
  }
})

const TotalWeightComponentAfterLogin = styled(Box)({
  alignItems: "center",
  display: "flex",
  maxWidth: "342px",
  gap: "12px",
  '@media (max-width: 499px)': {
    width: "100%",
  },
  '@media (max-width: 470px)': {
    maxWidth: "100%",
  },
})

const TotalWeightInnerComponentAfterLogin = styled(Typography)({
  fontSize: '16px',
  fontWeight: 450,
  color: '#162A26',
  paddingLeft: "16px",
  margin: "0px ",
  minWidth: "82px",
  '@media (max-width: 470px)': {
    paddingLeft: "0px",
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    paddingLeft: "0px",
  },
  '@media (min-width: 1501px) and (max-width: 1600px)': {
    paddingLeft: "0px",
  }
})

const TotalWeightInnerComponentTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 450,
  color: '#162A26',
  paddingLeft: "0px",
  margin: "0px 12px 0px 0px",
  minWidth: "82px",
  '@media (max-width: 690px)': {
    paddingLeft: "0px",
  },
  '@media (min-width: 1200px) and (max-width: 1400px)': {
    paddingLeft: "0px",
  },
})

const ButtonComponentMain = styled(Button)({
  width: "149px !important",
  height: "32px",
  gap: "8px",
  opacity: "0px",
  boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 30px 0px, rgba(0, 0, 0, 0.0) 0px 2px 2px 0px, rgba(0, 0, 0, 0.1) 0px 2px 5px 0px",
  borderRadius: "8px",
  color: "#B7862B",
  fontSize: "14px",
  fontWeight: 420,
  background: "#FFFFFF",

  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const SmallImage = styled('img')({
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "85.44px",
  width: "85.44px",
  margin: "0px 21px 0px 0px",
  cursor: "pointer",
  '@media (max-width: 470px)': {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
    margin: "0px"
  },

});

const MeterWrapper = styled(Grid)({
  width: '342px',
  display: 'flex',
  alignItems: 'center',
  height: "50px",
  '@media (max-width: 470px)': {
    width: "100%",
  },
  "& .firstBox": {
    boxSizing: "border-box",
    display: "flex", 
    justifyContent: "space-between",
    border: "1px solid #D6D3D1",
    borderRight: "none",
    // width: "254px",
    width: "100%",
    flex: 1,
    height: "48px",
    alignItems: "center",
    '@media (max-width: 359px)': {
      width: "auto",
    },
    '@media (max-width: 470px)': {
      flex: 1,
      maxWidth: "342px",
    },
  }
});


const StyledIconButtonOne = styled(IconButton)({
  '& svg': {
    fontSize: '1.5rem',
    color: '#333',
  },
});
const StyledIconButtonTwo = styled(IconButton)({
  '& svg': {
    fontSize: '1.5rem',
    color: '#333',
  },
});


const MeterInput = styled('div')({
  width: '50px',
  textAlign: 'center',
  fontSize: '21PX',
  fontWeight: '420',
  color: '#162A26',

});



const UnitBox = styled('div')({
  padding: "14px 24px",
  height: '48px',
  boxSizing: 'border-box',
  backgroundColor: '#f0fdf4',
  fontSize: '14px',
  fontWeight: '420',
  color: '#162A26',
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  display: "flex",
  border: "1px solid #E0EEDF"
});

const MeterComponent = styled(Box)({
  width: "auto",
  height: "48px",
  maxWidth: "88px",
})

const UnitBoxComponent = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0FFF0',
  border: "1px solid #E0EEDF",
  borderColor: '#E0EEDF',
  height: '48px',
  boxSizing: "border-box",
  '@media (max-width: 690px)': {
    minWidth: "88px",
  },
  '@media (min-width: 1200px) and (max-width: 1299px)': {
    width: "84px",
  },
});

const UnitBoxComponentAfterLogin = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F0FFF0',
  border: "1px solid #E0EEDF",
  borderColor: '#E0EEDF',
  height: '48px',
  boxSizing: "border-box",
  '@media (max-width: 499px)': {
    width: "88px",
  },
  '@media (min-width: 1199px) and (max-width: 1500px)': {
    width: "82px",
  },
  '@media (min-width: 1501px) and (max-width: 1600px)': {
    width: "82px",
  }
})

const WeightBox = styled(Box)({
  width: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  border: "1px solid #D6D3D1",
  height: '48px',
  boxSizing: "border-box",
  borderRight: "none",
  '@media (max-width: 470px)': {
    width: "100% !important",
    flex: 1
  },
  '@media (max-width: 690px)': {
    width: "150px",
    justifyContent: 'flex-start',
  },
  '@media (min-width: 1200px) and (max-width: 1299px)': {
    width: "159px",
  },

  '@media (min-width: 1300px) and (max-width: 1400px)': {
    width: "174px",
  },
});

const WeightBoxAfterLogin = styled(Box)({
  minWidth: "67px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  border: "1px solid #D6D3D1",
  height: '48px',
  borderRight: "none",
  padding: "0 20px",
  boxSizing: "border-box",
  '@media (max-width: 490px)': {
    width: "100%",
    flex: 1,
    // justifyContent: 'flex-start',
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    // width: "159px",
    // justifyContent: 'flex-start',
  },

  '@media (min-width: 1501px) and (max-width: 1600px)': {
    // width: "159px",
    // justifyContent: 'flex-start',
  }
})

const WeightInnerTypography = styled(Typography)({
  fontWeight: 420, fontSize: '16px', color: '#162A26',
  '@media (max-width: 690px)': {
    paddingLeft: "13px"
  },

  '@media (min-width: 1200px) and (max-width: 1300px)': {
    paddingRight:"83px"
  }
})

// const WeightInnerTypographyAfterLogin = styled(Typography)({
//   fontWeight: 420, fontSize: '16px', color: '#162A26',
//   '@media (max-width: 690px)': {
//     paddingLeft: "13px"
//   },

//   '@media (min-width: 1200px) and (max-width: 1300px)': {
//     paddingLeft: "23px"
//   }
// })

const WeightInnerTypographyAfterLogin = styled(Typography)({
  fontWeight: 450, fontSize: '16px', color: '#162A26',
  '@media (max-width: 470px)': {
    paddingLeft: "13px"
  },

  '@media (min-width: 1199px) and (max-width: 1500px)': {
    paddingLeft: "13px"
  }
})


const QuantityText = styled('div')({
  fontSize: "16px",
  fontWeight: 450,
  marginLeft: "16px",
  '@media (max-width: 420px)': {
    marginLeft: "2px",
    marginTop: "10px"
  },
  '@media (max-width: 360px)': {
    width: "320px !important",
    marginLeft: "2px",
    marginTop: "10px"
  },
});

const ButtonContainerAfterLogin = styled(Box)({
  display: "flex",
  marginTop: "20px",
  gap: "8px",
  flexWrap: "wrap",
  '@media (min-width: 600px) and (max-width: 1024px)': {
    flexWrap: "nowrap",
  },
  '@media (max-width: 768px)': {
    marginTop: "26px",
  },

  " & .button_first_after_login": {
    height: "40px",
    width: "168px",
    minWidth: "168px !important",
    borderRadius: "36px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: 420,
    border: "1.5px solid #B7862B",
    color: "#B7862B",
    '@media (max-width: 599px)': {
      maxWidth: "100% !important",
      width: "100% !important",
    },

  },
  " & .button_last_after_login": {
    maxWidth: "168px",
    minWidth: "168px !important",
    width: "100%",
    fontSize: "14px",
    fontWeight: 420,
    background: "#1C1917",
    color: "#FAFAF9",
    height: "40px",
    borderRadius: "36px",
    opacity: "0px",
    "&:hover": {
      background: "#1C1917",
    },
    '@media (max-width: 1199px)': {
      maxWidth: "328px"
    },
    '@media (max-width: 768px)': {
      maxWidth: "100% !important",
    },

  },

})

const ButtonContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  gap: "8px",
  flexWrap: "wrap",

  '@media (min-width: 600px) and (max-width: 1024px)': {
    flexWrap: "nowrap",
  },
  '@media (max-width: 768px)': {
    marginTop: "26px",
  },

  " & .button_first": {
    width: "168px",
    minWidth: "168px !important",
    height: "40px",
    borderRadius: "36px",
    opacity: "0px",
    border: "1.5px solid #B7862B",
    color: "#B7862B",
    fontSize: "14px",
    fontWeight: 420,

    '@media (max-width: 599px)': {
      maxWidth: "100% !important",
      width: "100% !important",
    },

  },
  " & .button_last": {
    maxWidth: "168px",
    minWidth: "168px !important",
    width: "100%",
    height: "40px",
    borderRadius: "36px",
    opacity: "0px",
    color: "#FAFAF9",
    fontSize: "14px",
    fontWeight: 420,
    background: "#1C1917",
    "&:hover": {
      background: "#1C1917",
    },
    '@media (max-width: 1199px)': {
      maxWidth: "328px"
    },
    '@media (max-width: 768px)': {
      maxWidth: "100% !important",
    },
  },
});

const SimilarProductComponent = styled(Typography)({
  margin: "36px 0 44px 0px",
  fontSize: "24px",
  fontWeight: 420,
  color: "#292524",
  textTransform: "uppercase",
  '@media (max-width: 1024px)': {
    marginTop: "40px !important",
  },
  "&.similerTitleWithToken" : {
    marginTop: "8px",
  }
})

const StyledContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  width: "100%",
  maxWidth: "518px !important",
  flexDirection: 'column',
  overflow: 'hidden',
  background: "#F5F5F480",
  minHeight: "79px",
  '@media (max-width: 470px)': {
    width: "100% !important",
  },
  '@media (min-width: 800px) and (max-width: 820px)': {
    width: "98% !important",
  },

});

const MaterialLcaInnerContainer = styled('div')({
  width: "100%",
  marginTop: "15px",
  '@media (max-width: 470px)': {
    width: "100% !important",
  },
  '@media (min-width: 471px) and (max-width: 599px)': {
    width: "100% !important",
  },
  '@media (min-width: 600px) and (max-width: 699px)': {
    // width: "534px !important",
  },
  '@media (min-width: 700px) and (max-width: 799px)': {
    // width: "83% !important",
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    // width: "700px !important",
  },

  '@media (min-width: 830px) and (max-width: 900px)': {
    // width: "772px !important",
  },
})

const StyledContent = styled('div')({
  maxHeight: '100vh',
  overflowY: 'auto', display: "flex",
  justifyContent: "space-between",
  filter: "blur(4px)",
  transition: "filter 0.3s ease",

  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    borderRadius: '5px',
  },

  '@media (max-width: 768px)': {
    maxHeight: '80vh',
  },
});

const UnlockOverlay = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  '@media (max-width: 360px)': {
    width: "155px !important"
  },
  '@media (max-width: 420px)': {
    width: "155px !important"
  },
})

const ProductDetailContainer = styled(Grid)({
  '@media (min-width: 800px) and (max-width: 820px)': {
    flexDirection: "column !important"
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    flexDirection: "column !important"
  }


})

const DetailInnerContainer = styled(Grid)({
  '@media (max-width: 820px)': {
    flexWrap: "nowrap"
  },
  '@media (max-width: 470px)': {
    flexWrap: "wrap !important"
  },
  '@media (min-width: 471px) and (max-width: 600px)': {
    flexWrap: "wrap !important"
  }
})


const ProductDetailsSection = styled(Box)({
  padding: "0px"
})


const DetailItem = styled(Typography)({
  fontWeight: 450,
  fontSize: "16px",
  color: "#292524"
})


const DescriptionSection = styled(Box)({
  padding: "0px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    display: "flex",
    width: "100%"
  }
});

const CertificationsSection = styled(Box)({
  display: "flex",
  gap: "65px",
  width: "100%",
  boxSizing: "border-box",
  flexWrap: "wrap",
  marginBottom: "27px",
  '@media (max-width: 1024px)': {
    marginBottom: "67px",
  }
})

const CertificationImage = styled('img')({
  height: "50px",
  width: "auto"
})

const TextBox = styled(Box)({
  background: '#F5F5F4',
  height: "100%",
  // '@media (max-width: 470px)': {
  //   padding: "0px 14px"
  // }
});
const TextInnerBox = styled('div')({
  padding: "41px 45px",
  '@media (max-width: 600px)': {
    padding: "41px 24px",
  },
  // '@media (max-width: 470px)': {
  //   padding: "0px 14px"
  // }
})

const DescriptionComponent = styled('p')({
  color: "#292524",
  marginBottom: "0",
  fontSize: "12px",
  fontWeight: 390,
  lineHeight: "16.8px",
  letterSpacing: "0.03em",
  marginTop: "18px",
  '@media (max-width: 470px)': {
    textAlign: "left"
  }


});

const DescriptionFirstComponent = styled('div')({
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "60% !important"
  },

  '@media (max-width: 470px)': {
    width: "100% !important"
  }

})

const LogisticalComponent = styled('div')({
  marginTop: "32px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "49% !important"
  },
})

const DescrptionContentComponent = styled(Typography)({
  whiteSpace: 'pre-line',
  letterSpacing: "unset",
  fontSize: "14px", fontWeight: 390, color: "#292524",  lineHeight: "20px",
  '@media (min-width: 600px) and (max-width: 1199px)': {
    width: "80% !important"
  },
})

const items: Item[] = new Array(5).fill({
  title: 'Sheer Lustre Faille',
  image: productImg,
  price: '£0.45 /kg',
  available: '150,000.00 kg'
});

const CottonContainer = styled('div')({
width: "calc(50% - 34px)",
 lineHeight: "26px", display: "flex", flexDirection: "column", paddingLeft: "32px",
 '@media (max-width: 700px)': {
  width: "100%",
  paddingLeft: "8px"
},
})

const YarnContainer = styled('div')({
width: "calc(50% - 34px)",
 lineHeight: "26px", display: "flex", flexDirection: "column", paddingLeft: "32px",
 '@media (max-width: 700px)': {
  width: "100%",
  paddingLeft: "8px"
},
})


const CardMediaComponent = styled(CardMedia)({
  height: "166.9px",
  width: "223px",
  '@media (max-width: 1199px)': {
    height: "160px",
    width: "160px",
},
});

const IconButtonComponent = styled(IconButton)({
  "&.fav-icon": {
    top: 7, 
    right: 5.57, 
    width: "18px",
    height: "16px",
    position: 'absolute', 
    zIndex: 1, 
    color: 'white'
  },
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "18px",
    height: "16px"
  }
});

const IconButtonComponentOne = styled(IconButton)({
  position: 'absolute',
  top: '4px',
  right: '7px',
  color: 'white',

  '@media (max-width: 768px)': {
    right: "4px !important",
    top: "5px !important"
  },
  '@media (max-width: 1476px)': {
    right: "51px",
    top: "8px"
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    right: "15px !important"
  },
  "& .css-i4bv87-MuiSvgIcon-root": {
    width: "28px",
    height: "25px"
  }
});

const CardContentComponent = styled("div")({
  padding: "12px 16px",
  "& .css-46bh2p-MuiCardContent-root:last-child": {
    paddingBottom: "5px"
  },
  "& .title-txt": {
    fontWeight: "420", 
    fontSize: '16px', 
    lineHeight: '19.2px', 
    marginBottom: "37.8px", 
    color: "#162A26", 
    textTransform: "uppercase",
    '@media (max-width: 1199px)': {
      marginBottom: "19px", 
  }, 
  }
});

const ContainerMain = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});



const ItemNew = styled('div')({
  textAlign: 'center',
});

const Value = styled(Typography)({
  fontSize: '14px',
  color: "#162A26",
  fontFamily: "brandon-grotesque, sans-serif",
  fontWeight: 420,
  textAlign: "left"
});

const PriceTypography = styled(Typography)({
  fontWeight: 400,
  fontFamily: "garamond-premier-pro !important",
  color: "#2D6A4D",
  fontSize: '11px',
  textAlign: "left"
});


const CardContentRowLast = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "4px",
  marginBottom: "6px"
});

const MainContainer = styled('div')({
  overflowX: 'auto',
});


const SimilarProductWrapper = styled('div')({

  "&.without-similar-token": {
    // maxWidth: "1342px",
    // padding: "0px 1px 0px 16px",
    width: "100%",
    padding: "0px",

  },
  "&.with-similar-token": {
    width: "100%",
    padding: "0px",
  }

})

const GridContainerMain = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap' , 
  padding: "0px" , 
  paddingBottom: "16px" , 
  gap: 24,
  overflowX: 'auto' as 'auto',
  whiteSpace: 'nowrap' as 'nowrap',
  width: '100%',
  boxSizing: 'border-box' as 'border-box',
  justifyContent: 'center',
})

const CardMainComponent = styled(Card)({
  boxShadow: "none !important",
  border: "1px solid #D6D3D1", 
  boxSizing: "border-box",
  borderRadius: "0px",
  "&.without_card_token": {
    padding: "5px", cursor: "pointer", 
    width: "235px",
    minWidth: "235px",
    '@media (max-width: 1199px)': {
      width: "180px",
      minWidth: "180px",
      padding: "9px",
      paddingBottom: "0px",
    },
  },
  "&.with_card_token": {
    padding: "5px", 
    cursor: "pointer",
    width: "235.8px",
    minWidth: "235.8px",
    '@media (max-width: 1199px)': {
      width: "180px",
      minWidth: "180px",
      padding: "10px",
      paddingBottom: "0px",
    },
  },

})

const Wrapper = styled('div')({
  "&.without-token": {
    width: "100%",
    background: "#FAFAF9",
    padding: "40px 154px",
    paddingTop: "0px",  
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",

    '@media (max-width: 1100px)': {
      padding: "0px !important",
      maxWidth: "100%",
      paddingBottom: "32px !important"
    },
  },

  "&.with-token": {
    width: "100%",
    background: "#FAFAF9",
    padding: "40px 107px",
    paddingTop: "0px",  
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    '@media (max-width: 1100px)': {
      padding: "0px !important",
      maxWidth: "100%",
      paddingBottom: "32px !important"
    },
  }
});

const InspirationComponent = styled(Typography)({
  textTransform: "uppercase",
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 300,
  fontFamily: "garamond-premier-pro !important",
  color: "#292524",
  margin: "0",
  letterSpacing: "-1px",
});

const InspirationContainer = styled(Grid)({
  "&.without_token": {
    width: "100%",
    gap: "38.5px",
    padding: "0",
    minHeight: "305px",
    height: "100%",

    '@media (max-width: 900px)': {
      minHeight: "413px",
    },
    '@media (max-width: 1100px)': {
      gap: "0px",
    },
    "& .insImgContainer" : {
      maxWidth: "302px",
      height: "auto",
      width: "calc((100% - 458px) / 2)",
      "& img" : {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        '@media (min-width: 1101px)': {
          maxHeight: "305px",
        },
      },
      '@media (max-width: 1100px)': {
        width: "33%"
      },
      '@media (max-width: 768px)': {
        width: "calc((100% - 237px) / 2)",
      },
      '@media (max-width: 600px)': {
        width: "calc((100% - 195px) / 2)",
      },

    },
  },
  "&.token": {
    width: "100%",
    gap: "22px",
    padding: "0",
    height: "100%",
    minHeight: "305px",

    '@media (max-width: 900px)': {
      minHeight: "413px",
    },
    '@media (max-width: 1100px)': {
      gap: "0px",
    },

    "& .insImgContainer" : {
      maxWidth: "302px",
      height: "auto",
      width: "calc((100% - 426px) / 2)",
      '@media (max-width: 1100px)': {
        width: "33%"
      },
      '@media (max-width: 768px)': {
        width: "calc((100% - 237px) / 2)",
      },
      '@media (max-width: 600px)': {
        width: "calc((100% - 195px) / 2)",
      },
      "& img" : {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        '@media (min-width: 1101px)': {
          maxHeight: "305px",
        },
      },
    },
  },


});

const InspiratinMidText = styled("div")({
  // width: "381px",
  maxWidth: "411px",
  minWidth: "381px",
  flex: 1,
  minHeight: "305px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "left",
  backgroundColor: "#f8f8f8",
  '@media (max-width: 1100px)': {
      width: "33%",
      minWidth: "237px",
    },
  '@media (max-width: 768px)': {
    minWidth: "195px",
  },
});

const ImageContainerOne = styled("div")({
  // minHeight: "305px",
  height: "100%",
  '@media (max-width: 470px)': {
    // width: "63.5px",
  },
  '@media (min-width: 471px) and (max-width: 500px)': {
    // width: "71px",
  },
  '@media (min-width: 501px) and (max-width: 599px)': {
    // width: "87px",
  },
  '@media (min-width: 601px) and (max-width: 700px)': {
    // width: "96px",
  },
  '@media (min-width: 701px) and (max-width: 799px)': {
    // width: "143px",
  },
  '@media (min-width: 800px) and (max-width: 1199px)': {
    // width: "221px",
  },
});


// Customizable Area End
