import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, convertFormData, getDiffs, handleApiError, handleExpiredToken, handleUndefinedData, handleClearStorageAndRedirectLogin } from "../../../blocks/utilities/src/CommonFunctions";
import { AccountAttributes, AccountResponse, ErrorResponse } from "../../../blocks/utilities/src/typeInterface";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
import { FormikErrors, FormikHelpers } from "formik";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
  userInfo: AccountAttributes;
  userId: string | number;
  profilePicture: string;
  defaultProfilePicture: string;
  current_password: string,
  new_password: string,
  confirm_new_password: string,
  [key: string]: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class PersonalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPersonalDetailsApi: string = "";
  editUserProfileCallId: string = "";
  updatePasswordCallId: string = "";

  initialPassData = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    this.state = {
      userInfo: {
        activated: true,
        country_code: "",
        email: "",
        first_name: "",
        last_name: "",
        full_phone_number: "",
        phone_number: "",
        account_image: "",
        type: "",
        country: "",
        city: "",
        company_name: "",
        job_title: "",
        created_at: "",
        updated_at: "",
        device_id: "",
        unique_auth_id: ""
      },
      userId: "",
      showCurrentPassword: true,
      showNewPassword: true,
      showConfirmNewPassword: true,
      profilePicture: "",
      defaultProfilePicture: "",
      ...this.initialPassData
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleExpiredToken(responseJsonData, this.props);

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getPersonalDetailsApi) {
          this.getPersonalDetailsResponse(responseJsonData);
        } else if (apiCallId === this.editUserProfileCallId) {
          this.editPersonalDetailsResponse(responseJsonData);
        } else if (apiCallId === this.updatePasswordCallId) {
          this.updatePasswordResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    this.getPersonalDetails();
  }

  getPersonalDetails = async () => {
    this.getPersonalDetailsApi = await apiCall({ method: configJSON.httpGetMethod, endPoint: configJSON.getProfileDetailsEndpoint, token: true });
  }
  setFormikInitialValues = () => {
    const { userInfo } = this.state;
    return {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      job_title: userInfo.job_title,
      company_name: userInfo.company_name,
      country: userInfo.country,
      city: userInfo.city,
      country_code: userInfo.country_code,
      phone_number: userInfo.phone_number,
      account_image: "",
    };
  }

  setPasswordInitialValues = () => {
    const { current_password, new_password, confirm_new_password } = this.state;
    return {
      current_password,
      new_password,
      confirm_new_password,
    };
  }

  handlePasswordChange = (setFieldValue: (field: string, value: string | number) => void, event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    this.setState((prevState) => ({
     ...prevState,
      [name]: value,
    }));
    setFieldValue(name, value);
  };

  handleViewHidePassword = (name: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  }

  shortName = () => {
    const { first_name, last_name } = this.state.userInfo
    const firstInitial = first_name ? first_name.trim()[0] : '';
    const lastInitial = last_name ? last_name.trim()[0] : '';
    return (firstInitial + lastInitial).toUpperCase();
  }


  setUserInfo = (attributes: AccountAttributes) => {
    this.setState({
      userInfo: {
        ...attributes,
        first_name: handleUndefinedData(attributes.first_name, ""),
        last_name: handleUndefinedData(attributes.last_name, ""),
        email: handleUndefinedData(attributes.email, ""),
        job_title: handleUndefinedData(attributes.job_title, ""),
        company_name: handleUndefinedData(attributes.company_name, ""),
        country: handleUndefinedData(attributes.country, ""),
        city: handleUndefinedData(attributes.city, ""),
        country_code: handleUndefinedData(attributes.country_code, ""),
        phone_number: handleUndefinedData(attributes.phone_number, ""),
        account_image: "",
      },
      profilePicture: handleUndefinedData(attributes.account_image, ""),
      defaultProfilePicture: handleUndefinedData(attributes.account_image, "")
    })
  }

  getPersonalDetailsResponse = (responseData: AccountResponse) => {
    if (responseData.data.attributes) {
      const { attributes } = responseData.data
      this.setUserInfo(attributes)
      this.setState({
        userId: responseData.data.id,
      })
    }
  }

  editPersonalDetailsResponse = async (responseData: AccountResponse & ErrorResponse) => {
    if (responseData?.data?.attributes) {
      const { attributes } = responseData.data
      this.setUserInfo(attributes);
      await setStorageData('accountDetails',JSON.stringify(attributes))
      toast.success(configJSON.formSuccessMsg)
    } else {
      handleApiError(responseData.errors);
    }
  }

  handleProfileChange = (eventItem: { target: { files: File[]; }; }, setFieldValue: { (field: string, value: string | string[] | undefined | File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void; }) => {
    const file = eventItem.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/jpg') {
        setFieldValue("account_image", file);
        this.setState({
          profilePicture: URL.createObjectURL(file),
        });
      } else {
        toast.error(configJSON.invalidFileError)
      }
    }
  };

  handleClearProfileImg = (setFieldValue: { (field: string, value: File, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: null): void; }) => {
    this.setState({
      profilePicture: "",
    });
    setFieldValue("account_image", null)
  };

  editProfile = async (values: Partial<AccountAttributes>, { setFieldValue }: FormikHelpers<Partial<AccountAttributes>>) => {

    const { first_name, last_name, email, job_title, company_name, phone_number, country, city, country_code } = this.state.userInfo;

    const orriginalData = {
      first_name,
      last_name,
      email,
      job_title,
      company_name,
      country,
      city,
      country_code,
      phone_number,
      account_image: this.state.defaultProfilePicture
    };

    const attrs = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      job_title: values.job_title,
      company_name: values.company_name,
      country: values.country,
      city: values.city,
      country_code: values.country_code,
      phone_number: values.phone_number,
      ...(values.account_image ? { account_image: values.account_image } : this.state.profilePicture ? {} : { account_image: "" }),
    };

    let payload = getDiffs(orriginalData, attrs);
    this.editProfileApiCall(payload, { setFieldValue })
  }

  editProfileApiCall = async (userData: object,  { setFieldValue }: {setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<Partial<AccountAttributes>>>}) => {
    if (Object.keys(userData).length) {
      this.editUserProfileCallId = await apiCall({
        method: configJSON.httpPutMethod,
        body: convertFormData(userData),
        endPoint: `${configJSON.updateProfileDetailsEndpoint}`,
        token: true,
      });
      setFieldValue("account_image", "")
    }
  }

  updatePassword = async (values: Partial<AccountAttributes>) => {

    const { current_password, new_password, confirm_new_password } = values;

    const data = {
      attributes: {
        current_password,
        new_password,
        confirm_new_password,
      }
    };

    this.updatePasswordCallId = await apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpPutMethod,
      body: JSON.stringify({ data }),
      endPoint: `${configJSON.updatePasswordEndpoint}`,
      token: true,
    });

  }

  handleCountryChange = (setFieldValue: (field: string, value: string | number) => void, event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    const country_code = configJSON.countryList.find((country: { value: string; }) => country.value === selectedOption)?.country_code;
    setFieldValue('country', selectedOption);
    setFieldValue('country_code', country_code);
  };

  updatePasswordResponse = (responseJson: ErrorResponse & { message: string }) => {
    this.setState({ 
      showCurrentPassword: true,
      showNewPassword: true,
      showConfirmNewPassword: true,
    })
    if (responseJson.message) {
      toast.success(responseJson.message);
      this.setState({ 
        ...this.initialPassData
      });
      handleClearStorageAndRedirectLogin(this.props)
    } else {
      handleApiError(responseJson.errors);
    }
  }

  onResetForm = (handleReset: () => void) => {
    this.setState({
      profilePicture: this.state.defaultProfilePicture,
    });
    handleReset();
  }

  checkFormDisabled = (values: Partial<AccountAttributes>) => {
    const { account_image, first_name, last_name, email, job_title, company_name, phone_number, country, city, country_code } = this.state.userInfo;

    const orriginalData = {
      first_name,
      last_name,
      email,
      job_title,
      company_name,
      country,
      city,
      country_code,
      phone_number,
      account_image
    };

    const attrs = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      job_title: values.job_title,
      company_name: values.company_name,
      country: values.country,
      city: values.city,
      country_code: values.country_code,
      phone_number: values.phone_number,
      account_image: values.account_image
    };
    const userData = getDiffs(orriginalData, attrs)
    return !Object.keys(userData).length
  }
  checkPasswordFormDisabled = (values: Partial<AccountAttributes>) => {
    const orriginalData = {
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    };

    const attrs = {
      current_password: values.current_password,
      new_password: values.new_password,
      confirm_new_password: values.confirm_new_password,
    };

    const userData = getDiffs(orriginalData, attrs)
    return !Object.keys(userData).length
  }
  // Customizable Area End
}
