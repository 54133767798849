// Customizable Area Start
import invert from 'invert-color';

/* get colors */
export const colors = (idDark=false) => {
  const lightColors:any = {
    ultraDarkGreen: '#162A26',
    darkGreen: '#2D6A4D',
    lightGreen: '#8AAA91',
    darkGold: "#B7862B",
    lightGold: "#F5F1E2",
    blackText: '#292524',
    whiteText: '#FAFAF9',
    error: '#D32F2F',
    black: '#1C1917',
    white: '#FFFFFF',
  }

  const darkColors:any = {}
  for (const colors in lightColors) {
    darkColors[colors] = invert(lightColors[colors]);
  }
  return idDark ? darkColors : lightColors;
}
// Customizable Area End