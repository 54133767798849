export const bgImage = require('../assets/BGImage.jpeg')
export const arrowImg= require('../assets/image_circle-plus.svg')
export const headerImg= require('../assets/image_ 1.jpeg')
export const mobileImg= require('../assets/bg_image.jpg')
export const tabImg= require('../assets/bg_img_tab.jpg')
export const logoImage = require("../assets/image_logo_new.jpeg");
export const imgPasswordInVisible = require("../assets/image_logo_new.jpeg");
export const imgPasswordVisible = require("../assets/image_logo_new.jpeg");
export const checkbox = require("../assets/checkbox1.jpeg");
export const checkboxErr = require("../assets/checkbox3.jpeg");
export const checkboxFilled = require("../assets/chkbox2.jpeg");