import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/styles";

interface CommonModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  content: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
}

class CommonModalComponent extends React.Component<CommonModalProps> {
  render() {
    const { open, handleClose, title, primaryButtonLabel, secondaryButtonLabel, primaryAction, secondaryAction } = this.props;

    return (
      <Dialog open={open} onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            padding: "36px, 32px, 0px, 32px",
            gap: "44px",
            opacity: "0px",
            width: '620px',
            height: '470px',
            border: '4px solid',
            borderImageSource: 'linear-gradient(118.3deg, #C2A657 1.51%, #E9D292 15.87%, #967C3E 30.23%, #F1DD99 44.6%, #7C6019 58.96%, #E7D8A0 73.32%, #A88E53 87.68%, #EFD99E 102.04%)',
            borderImageSlice: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowX: "hidden !important",
            '@media (min-width: 320px) and (max-width: 500px)': {
              width: '320px',
            }
          },
        }}
        fullWidth
      >
        <DialogTitle sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center',padding: "0" }}>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: "#334155",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            marginTop: "54px",
            '@media screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape)': {
              marginTop: "0px",    
            },
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            overflowY: 'hidden',
            width: {
              xs: '256px',
              sm: '340px',
              md: '340px',
              lg: '340px',
            },
            padding: {
              xs: '0px 21px', // Default padding for extra small screens
              sm: '0px 133px',
              md: '0px 133px',
              lg: '0px 133px',
            },
            '@media (min-width: 360px) and (max-width: 379px)': {
              padding: '0px 21px',
            },
            '@media (min-width: 380px) and (max-width: 399px)': {
              padding: '0px 31px',
            },
            '@media (min-width: 400px) and (max-width: 419px)': {
              padding: '0px 31px',
            },
            '@media (min-width: 420px) and (max-width: 439px)': {
              padding: '0px 31px',
            },
          }}>

          <Typography
            align="left"
            sx={{ fontWeight: 450, fontSize: '24px', color: "#616161", marginTop: 2, lineHeight: "34.32px", textTransform: "uppercase" }}
          >
            {title}
          </Typography>
          <DescriptionComponent sx={{ marginBottom: '10px'}} >
            To enjoy all the features of ProcureCircular, please log in or create an account. Logging in ensures a seamless and personalized shopping experience.
          </DescriptionComponent>
        </DialogContent>


        <DialogActionsComponent>
        <Button
    sx={{
      height: "44px",
      marginTop: "-21px",
      backgroundColor: '#C29E40',
      fontWeight: 390,
      fontSize: "16px",
      color: '#FAFAF9',
      borderRadius: 25,
      '&:hover': {
        backgroundColor: '#C29E40',
      },
      width: {
        xs: '256px',
        sm: '340px',
      },
      '@media (min-width: 360px) and (max-width: 433px)': {
              margin: '-19px 0px 0px',
            },
    }}
    onClick={primaryAction}
  >
    {primaryButtonLabel || 'LOG IN'}
  </Button>
          <Button
            sx={{
              Width: "340px",
              height: "44px",
              backgroundColor: '#1C1917',
              color: '#FAFAF9',
              fontWeight: "420",
              borderRadius: 25,
              '&:hover': {
                backgroundColor: '#1C1917',
              },
              marginLeft: '0 !important',
              width: {
                xs: '256px',
                sm: '340px',
              },

            }}
            onClick={secondaryAction || handleClose}
          >
            {secondaryButtonLabel || 'SIGN UP'}
          </Button>
        </DialogActionsComponent>
      </Dialog>
    );
  }
}

export default CommonModalComponent;

const DescriptionComponent = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#616161",
  fontFamily: "garamond-premier-pro !important",
  textAlign: "left",
  marginTop: "12px",
  lineHeight: "19.2px !important"
})

const DialogActionsComponent = styled(DialogActions)({
justifyContent: 'center',
 flexDirection: 'column',
  gap: 16,
  padding: "0px 0px 80px",
   borderTop: 'none',
   '@media (min-width: 320px) and (max-width: 500px)': {
    padding: "0px 0px 45px",
    gap:"16px"
  },
  '@media screen and (min-width: 400px) and (max-width: 900px) and (orientation: landscape)': {
    padding: "0px 0px 30px",    
  }
})