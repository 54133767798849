import React, { Component } from "react";

import {
  Box,
  Pagination,
  PaginationItem
} from "@mui/material";
import { styled } from '@mui/material/styles';

const LeftArrow = require("../src/icons-nav/LeftArrowImg.svg");
const RightArrow = require("../src/icons-nav/RightArrowImg.svg");

interface Props {
  navigation?: any,
  meta: any,
  onHandlePageChange: any,
}
interface S {
}
class CustomPagination extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onHandlePageChange, meta } = this.props;
    return (
      <ContainerBoxCoponent>
      <StyledPagination
        count={meta.total_pages}
        page={meta.current_page}
        
        onChange={onHandlePageChange}
        renderItem={(item) => (
          <>
            <StyledPaginationItem
              slots={{
                previous: () => <img src={LeftArrow} alt="Previous" />,
                next: () => <img src={RightArrow} alt="Next" />
              }}
              {...item}
            />
          </>

        )}
      />
    </ContainerBoxCoponent>
    );
  }
}

export default (CustomPagination)

const ContainerBoxCoponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0',
  backgroundColor: "#F1F5F9",
  marginTop: "17px"
});

const StyledPagination = styled(Pagination)({
  width: "100%",
  '& .MuiPagination-ul': {
    position: 'relative',
    justifyContent: 'center',
    width: "100%"
  },
  '& .MuiPagination-ul li:first-child': {
    position: 'absolute',
    left: 0,
  },
  '& .MuiPagination-ul li:first-child button::before': {
    content: '"Previous"',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    color: "#045544",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:last-child': {
    position: 'absolute',
    right: 0,
  },
  '& .MuiPagination-ul li:last-child button::before': {
    content: '"Next"',
    position: 'absolute',
    right: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    color: "#045544",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:first-child button:hover, & .MuiPagination-ul li:last-child button:hover': {
    backgroundColor: 'unset',
  },
  '@media (max-width: 420px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
  '@media (max-width: 430px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
  '@media (max-width: 360px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }, '& .css-whh4o0-MuiButtonBase-root-MuiPaginationItem-root': {
      margin: "0px"
    },
    ' & .MuiPagination-ul li:last-child': {
      right: "0",
      position: "absolute",
      left: "91%",
    }
  },
  '@media (max-width: 320px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    }, '& .MuiPagination-ul li:first-child button::before': {
      content: '""',
    }
  },
});

const StyledPaginationItem = styled(PaginationItem)({
  '&.MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#045544',
    color: '#fff',
    width: "100%"
  },
  '&.MuiPaginationItem-root': {
    borderRadius: '4px',
    color: "#045544"

  },
});