import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import {
  createTheme,
} from "@mui/material/styles";
const CheckboxGroup = ({ title, items} : any) => ( 
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{ color: "#44403C" }} />}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "450", color: "#292524" }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <FormGroup>
        {items.map((item: any) => (
          <Box
            key={item.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              lineHeight: "19.6px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#A8A29E",
                    "&.Mui-checked": { color: "#C29E40" },
                  }}
                />
              }
              label={item.value}
            />
            <Typography sx={{ fontSize: "12px", color: "#78716C" }}>
              {item.catalogues_count}
            </Typography>
          </Box>
        ))}
      </FormGroup>
    </AccordionDetails>
  </Accordion>
);

const RangeInput = ({label}: any) => (
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{ color: "#44403C" }} />}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "450", color: "#292524" }}>
        {label}
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ display: "flex", alignItems: "center" }}>
      <TextField id="outlined-basic-from" placeholder="from" variant="outlined" />
      <Box mx={2} component="span">-</Box>
      <TextField id="outlined-basic-to" placeholder="to" variant="outlined" />
    </AccordionDetails>
  </Accordion>
);
//istanbul ignore next
const RadioGroupComponent = ({ title, options}: any) => (
  <Accordion
    sx={{
      backgroundColor: "#F5F5F5",
      border: "none",
      boxShadow: "none",
      "&::before": { display: "none" },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandCircleDownOutlinedIcon sx={{ color: "#44403C" }} />}
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "450", color: "#292524" }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
        {options.map((option: any) => (
          <Box key={option.value} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <FormControlLabel
              value={option.value}
              control={<Radio  disableRipple
                color="default" checkedIcon={<CheckedRadioIcon />}
              icon={<RadioIcon />}/>}
              label={option.value}
            />
            <Typography sx={{ fontSize: "12px", color: "#78716C" }}>{option.catalogues_count}</Typography>
          </Box>
        ))}
      </RadioGroup>
    </AccordionDetails>
  </Accordion>
);
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {filterDataa} = this.state
    //istanbul ignore next
    return (
      < >
  <Box sx={{ flexGrow: 1 }} >
          
                <Box style={webstyle.filterArea} >
                  <Box style={webstyle.filterHead}>
                    <h3 style={webstyle.filterTitle}>Filter by </h3>
                    <p style={webstyle.filterText}>Clear all filters</p>
                  </Box>
                  <Box>
  {filterDataa.map((filter) => {
    switch (filter.type) {
      case "checkbox":
        return (
          <CheckboxGroup
            key={filter.key}
            title={filter.title}
            items={filter.values}
            id={filter.id}
          />
        );
      case "radio":
        return (
          <RadioGroupComponent
            key={filter.key}
            title={filter.title}
            options={filter.values}
            id={filter.id}
          />
        );
      case "range":
        return (
          <RangeInput
            key={filter.key}
            label={filter.title}
            id={filter.id}
          />
        );
      default:
        return null;
    }
  })}
</Box>


                </Box>
             
         
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const RadioIcon = styled('span')({
  borderRadius: '50%',
  padding: '0px !important',
  width: 16,
  height: 16,
  border: "1.2px solid #A8A29E",
  backgroundColor: 'transparent',
   '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  position:'relative'
});
const CheckedRadioIcon = styled(RadioIcon)({
  backgroundColor: '#B7862B',
  borderColor: '#B7862B',
  '&::before': {
    display: 'block',
    width: 6.4,
    position: 'absolute',
    height: 6.4,
    backgroundColor: ' #FFFFFF !important',
top:'4.8',
left:'4.8',
    content: '""',
    borderRadius:'50%'
  },
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const webstyle = {
  filterArea: {
    backgroundColor: "#F5F5F5",
    border: "1px solid #D6D3D1",
    width: "100%",
    
  },
  filterHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
   padding: "0px 10px",
  },
  filterTitle:{
 color: "#1C1917" ,
 fontSize: "24px",
 fontWeight: "600",
 fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  filterText:{
    color: "#C29E40",
    fontWeight: "420",
    fontSize: "12px",
    cursor: "pointer"
  }
  

  
};
// Customizable Area End
