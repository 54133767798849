Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.putMethod = 'PUT'
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.tipsList = [
  {
    title1: 'Tip 1:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ]
  },
  {
    title1: 'Tip 2:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ]
  },
  {
    title1: 'Tip 3:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ] 
  }
]

exports.recentSearchsTitle = "Recent Searches"
exports.clearFilters = "Clear filters"
exports.clearAll = "Clear all"
exports.filterResultsTxt = "FILTER  RESULTS"
exports.recentSearchList = [
  "Material name",
  "Material name",
  "Material name",
  "Material name",
  "Material name",
]

exports.popularSearchsTitle = "popular searches"
exports.popularSearchList = [
  "Popular search",
  "Popular search",
  "Popular search",
  "Popular search",
]

exports.reltedSearchList = [
  {type: "search_result" ,value : "Double Face Wool & Silk Crepe"},
  {type: "search_result" ,value : "Double Face Wool & Silk Crepe"},
  {type: "search_result" ,value : "Double Wool"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
]

exports.priceList = [
  {id: 1 ,value : "Under £5 per metre", count: "77,547"},
  {id: 2 ,value : "Under £10 per metre", count: "55,645"},
  {id: 3 ,value : "Under £20 per metre", count: "24,789"},
  {id: 4 ,value : "Under £25 per metre", count: "77,547"},
  {id: 5 ,value : "Over £25 per metre", count: "77,547"},
]

exports.materialTypeList = [
  {id: 1 ,value : "Deadstock"},
  {id: 2 ,value : "New Material"},
  {id: 3 ,value : "Recycled"},
  {id: 4 ,value : "Other"},
  {id: 5 ,value : "Deadstock"},
  {id: 6 ,value : "New Material"},
  {id: 7 ,value : "Recycled"},
  {id: 9 ,value : "Other"},
  {id: 10 ,value : "Deadstock"},
  {id: 11 ,value : "New Material"},
  {id: 12 ,value : "Recycled"},
  {id: 13 ,value : "Other"},
]

exports.availabilityByMoqList = [
  {id: 1 ,value : "Over 1000m", count: "77,547"},
  {id: 2 ,value : "Over 500m", count: "55,645"},
  {id: 3 ,value : "Over 100m", count: "24,789"},
  {id: 4 ,value : "Under 100m", count: "77,547"},
  {id: 5 ,value : "Under 50m", count: "77,547"},
]

exports.sampleOfferList = [
  {id: 1 ,value : "Yes", count: "77,547"},
  {id: 2 ,value : "No", count: "55,645"},
]

exports.colourList = [
  {id: 1 ,value : "White", count: "77,547"},
  {id: 2 ,value : "Black", count: "55,645"},
  {id: 3 ,value : "Red", count: "24,789"},
  {id: 4 ,value : "Green", count: "77,547"},
  {id: 5 ,value : "Navy", count: "77,547"},
]

exports.fabricTypeList = [
  {id: 1 ,value : "Cotton", count: "77,547"},
  {id: 2 ,value : "Linen", count: "55,645"},
  {id: 3 ,value : "Hemp", count: "24,789"},
  {id: 4 ,value : "Jute", count: "77,547"},
  {id: 5 ,value : "Navy", count: "77,547"},
]

exports.fabricPatternList = [
  {id: 1 ,value : "Check", count: "77,547"},
  {id: 2 ,value : "Dot", count: "55,645"},
  {id: 3 ,value : "Stripe", count: "24,789"},
  {id: 4 ,value : "Floral", count: "77,547"},
  {id: 5 ,value : "Herringbone", count: "77,547"},
]

exports.fabricFormationList = [
  {id: 1 ,value : "Knitted", count: "77,547"},
  {id: 2 ,value : "Braided", count: "55,645"},
  {id: 3 ,value : "Felted", count: "24,789"},
  {id: 4 ,value : "Tufted", count: "77,547"},
  {id: 5 ,value : "Washed", count: "77,547"},
]

exports.stretchList = [
  {id: 1 ,value : "No", count: "77,547"},
  {id: 2 ,value : "Yes", count: "55,645"},
]

exports.dyedList = [
  {id: 1 ,value : "AZO Free Dye", count: "77,547"},
  {id: 2 ,value : "100% Natural Dye", count: "55,645"},
  {id: 3 ,value : "Chemical Dye", count: "24,789"},
  {id: 4 ,value : "Non Dyed", count: "77,547"},
  {id: 5 ,value : "Hand Dyed", count: "77,547"},
]

exports.certificateList = [
  {id: 1 ,value : "GOTS", count: "77,547"},
  {id: 2 ,value : "Fair Trade", count: "55,645"},
  {id: 3 ,value : "Better Cotton Initiative", count: "24,789"},
  {id: 4 ,value : "FSC", count: "77,547"},
  {id: 5 ,value : "Cotton Made in Africa", count: "77,547"},
]

exports.locationList = [
  {id: 1 ,value : "India", count: "77,547"},
  {id: 2 ,value : "China", count: "55,645"},
  {id: 3 ,value : "United Kingdom", count: "24,789"},
  {id: 4 ,value : "United States", count: "77,547"},
  {id: 5 ,value : "Bangladesh", count: "77,547"},
]

exports.useForFabricsList = [
  {id: 1 ,value : "All Interiors", count: "77,547"},
  {id: 2 ,value : "Activewear", count: "55,645"},
  {id: 3 ,value : "Lingerie", count: "24,789"},
  {id: 4 ,value : "Outerwear", count: "77,547"},
  {id: 5 ,value : "Beachwear and Toweling", count: "77,547"},
]

exports.getSuggestionsEndpoint = "bx_block_advanced_search/search/suggestions";
exports.getRecentSearchesEndpoint = "bx_block_search_history/search_historys";
exports.getPopularSearchesEndpoint = "bx_block_search_history/search_historys/popular_searches";
exports.filterListEndpoint = "catalogue/catalogue_attributes";
exports.filterListMethod = "POST"; 
exports.noRecentSearchTxt = "No recent searches"
exports.deleteMethod = "DELETE"
exports.postMethod = "POST"
exports.clearRecentSearchesEndpoint = "bx_block_search_history/search_historys/delete_history";
exports.searchFilterEndpoint = "/bx_block_advanced_search/search/advance_search";
exports.tipsEndpoint = "bx_block_search_history/search_historys/tip_data";
exports.noSearchFoundTxt = "Sorry we don’t currently have any materials that match your searching criteria."
// Customizable Area End
